import { 
  ACCEPT_COOKIES 
} from './types'

const { VERSION } = require('../../const')

const initialState = {
    VERSION,
    cookieConsent: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_COOKIES: {
      return {
        ...state,
        cookieConsent: new Date()
      }
    }
    default:
      return state;
  }
}