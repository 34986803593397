import React from 'react';
import { connect } from "react-redux";
import { AddCircle } from '@material-ui/icons';
import { Fab } from '@material-ui/core';
import { 
    beginEditAd,
    clearEditAd
} from '../redux/account/actions'
import { searchOwnAds } from '../redux/search/actions';
import { 
    LOGGED_IN,
    EDIT_AD_SUCCESS,
    OWN_ADS_PATH,
    PROFILE_TYPE_REFEREE
} from '../const'

class NewAdButton extends React.Component {

    getSelectedTeam = () => {
        return this.props.account.teams.find(
            team => team.id === this.props.account.selectedTeam
        ) || {}
    }
    
    componentWillReceiveProps(nextProps) {
		if ( this.props.account.editAdState !== nextProps.account.editAdState 
			&& nextProps.account.editAdState === EDIT_AD_SUCCESS ) {
			  if ( this.props.account.editAdId === null ) {
				// Waits for the new data to be written to the database.
				// Strictly a temporary solution.
				setTimeout(() => {
						this.props.searchOwnAds(
							nextProps.sortOrder,
							nextProps.account.selectedTeam,
							nextProps.login.token
						)
					}, 1000)
				}
                this.props.clearEditAd()
                this.redirectIfNotThere(OWN_ADS_PATH)
		}
    }

    redirectIfNotThere(path) {
		if ( this.props.history.location.pathname !== path ) {
			this.props.history.push(path)
		}
	}

    render() {
        const padding = 10
        if ( this.getSelectedTeam().profileType === PROFILE_TYPE_REFEREE ) {
            return null
        }
        return <Fab
            id="NewAdButton"
            style={{
                position: 'fixed',
                right: this.props.padding.paddingRight,
                bottom: padding + ( this.props.desktop ? 0 : 57 )
            }}
            variant="extended"
            size="large"
            color="primary"
            onClick={e => {
                if ( this.props.login.state !== LOGGED_IN ) {
                    this.props.promptLogin("Vain sisäänkirjautuneet käyttäjät voivat jättää ilmoituksia.")
                } else {
                    this.props.beginEditAd()
                }
            }}>
            <AddCircle style={{ marginRight: "5px" }}/>Jätä ilmoitus
        </Fab>
    }
}

const mapStateToProps = state => ({
    login: state.login,
    account: state.account,
    sortOrder: state.search.sortOrder
})
const mapDispatchToProps = dispatch => ({
    beginEditAd: adId => {
		dispatch(beginEditAd(adId))
    },
    searchOwnAds: (sortOrder, teamContext, token) => {
		dispatch(searchOwnAds(sortOrder, teamContext, token))
    },
    clearEditAd: () => {
		dispatch(clearEditAd())
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(NewAdButton)