import React from 'react';
import { connect } from "react-redux";

import {
    withMobileDialog,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField
} from '@material-ui/core';

import {
	beginTransferCredits,
	transferCredits,
	clearTransferCredits,
} from '../redux/account/actions'

import {
    TRANSFER_CREDITS_INIT,
    TRANSFER_CREDITS_SUCCESS,
	TRANSFER_CREDITS_FAILURE_400,
	TRANSFER_CREDITS_FAILURE_500
} from '../const'

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class TransferCreditsDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            source: -1,
            destination: -1,
            amount: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.account.transferCreditsState !== nextProps.account.transferCreditsState 
            && nextProps.account.transferCreditsState === TRANSFER_CREDITS_SUCCESS ) {
              this.props.clearTransferCredits()
          }      
        if ( this.props.account.transfrCreditsState === null 
            && nextProps.account.transferCreditsState === TRANSFER_CREDITS_INIT ) {
                this.setState({
                    source: -1,
                    destination: -1,
                    amount: 0
                })
            }
    }

    render() {
        return (
        <MobileDialog
            open={this.props.account.transferCreditsState !== null}
            onClose={e => {this.props.clearTransferCredits()}}
        >
        <DialogTitle>Siirrä krediittejä</DialogTitle>
        <DialogContent>
            <DialogContentText>Valitse lähdetili, kohdetili ja krediittien määrä.</DialogContentText>
            <FormControl style={{ marginBottom: 16 }}>
                <InputLabel shrink>
                    Siirretään tililtä
                </InputLabel>
                <Select
                    value={this.state.source}
                    onChange={e => {
                        this.setState({source: e.target.value})
                    }}
                    displayEmpty
                >
                    <MenuItem key={-1} value={-1}>Käyttäjätili: {this.props.login.user.email} ({this.props.login.user.credits})</MenuItem>
                    {
                        this.props.account.teams.map(team => 
                            <MenuItem key={team.id} value={team.id}>{team.name} ({team.credits})</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
            <FormControl style={{ marginBottom: 16 }}>
                <InputLabel shrink>
                    Siirretään tililtä
                </InputLabel>
                <Select
                    value={this.state.destination}
                    onChange={e => {
                        this.setState({destination: e.target.value})
                    }}
                    displayEmpty
                >
                    <MenuItem key={-1} value={-1}>Käyttäjätili: {this.props.login.user.email} ({this.props.login.user.credits})</MenuItem>
                    {
                        this.props.account.teams.map(team => 
                            <MenuItem key={team.id} value={team.id}>{team.name} ({team.credits})</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel shrink>
                    Määrä
                </InputLabel>
                <TextField 
                    margin="dense"
                    fullWidth
                    type="number"
                    style={{ 
                        marginTop: 17, 
                        marginBottom: 20,
                        background: 'transparent' }}
                    onChange={ e => {
                        this.setState({amount: parseInt(e.target.value)})
                    }}
                    value={ this.state.amount || '' }
                />
            </FormControl>
            { this.props.account.transferCreditsState === TRANSFER_CREDITS_FAILURE_400 &&
                <DialogContentText style={{ 
                    marginBottom: 0, 
                    color: 'red', 
                    fontSize: '.8em' 
                }}>
                    Lähdetilillä ei ole riittävästi krediittejä.
                </DialogContentText>
            }
            { this.props.account.transferCreditsState === TRANSFER_CREDITS_FAILURE_500 &&
                <DialogContentText style={{ 
                    marginBottom: 0, 
                    color: 'red', 
                    fontSize: '.8em' 
                }}>
                    Palvelinvirhe. Yritä myöhemmin uudestaan.
                </DialogContentText>
            }
        </DialogContent>
        <DialogActions>
            <Button 
            disabled={ false }
            onClick={e => {
                this.props.transferCredits(
                    this.props.login.token,
                    this.state.source,
                    this.state.destination,
                    this.state.amount
                )
            }} color="primary" variant="contained">
                Siirrä
            </Button>
            <Button onClick={e => {this.props.clearTransferCredits()}} color="primary">
                Peruuta
            </Button>
        </DialogActions>
        </MobileDialog>)
    }

}

const mapStateToProps = state => {
    return { 
        login: state.login,
        account: state.account
    }
  };
  
const mapDispatchToProps = dispatch => ({
	beginTransferCredits: () => {
		dispatch(beginTransferCredits())
	},
	transferCredits: (token, source, destination, amount) => {
		dispatch(transferCredits(token, source, destination, amount))
	},
	clearTransferCredits: () => {
		dispatch(clearTransferCredits())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(TransferCreditsDialog)
