import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardMedia,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Grid,
  Fab,
  Typography,
  Tooltip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
  Link,
  Menu,
  MenuItem
} from '@material-ui/core';

import {
  DateRange,
  Payment,
  Place,
  Edit,
  Lens,
  ArrowRight,
  CheckCircle,
  Timelapse,
  HourglassEmpty,
  Check,
  Cancel,
  Delete,
  Launch,
  ExpandMore,
  Person
} from '@material-ui/icons';

import LinkIcon from '@material-ui/icons/Link';

import {
  beginEditAd,
  deleteAd,
  beginConfirmResponse,
  getResponses,
  confirmMatch
} from '../redux/account/actions'

import { 
  toggleResponse,
  toggleRefereeRequest
} from '../redux/search/actions'

import { 
  LOGGED_IN,
  PROFILE_TYPE_NORMAL,
  PROFILE_TYPE_REFEREE
} from '../const'

const styles = theme => ({
  avatar: {
    width: 26,
    height: 26,
    alignSelf: 'flex-start',
    top: 24,
    display: 'none'
  },
  avatarDblTop: {
    top: 41
  },
  avatarIcon: {
    width: '.8em',
    height: '.8em',
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0
  },
  media: {
    height: 40,
    opacity: .65
  },
  card: {
   maxWidth: 360,
   width: 360,
   minWidth: 300,
   alignSelf: 'flex-start'
  },
  cardContent: {
    paddingTop: 0
  },
  actions: {
    display: 'flex',
  },
  confirmed: {
    backgroundColor: '#0c0'
  },
  responses: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.background.paper
   },
  },
  noResponses: {
    backgroundColor: theme.palette.background.paper,
    color: '#aaaaaa',
    '&:hover': {
      background: theme.palette.background.paper
   }
  }
});

class MatchCard extends React.Component {
  state = {
    selected: false,
    openSportMismatchAlert: false,
    openTeamNotSelectedAlert: false,
    snackOpen: false,
    snackTimeout: null,
    opponentDivisionsListAnchor: null
  };

  redirectIfNotThere(path) {
    if ( this.props.history.location.pathname !== path ) {
      this.props.history.push(path)
    }
  }

  getSelectedTeam = () => {
    return this.props.account.teams.find(
      team => team.id === this.props.account.selectedTeam
    ) || {}
  }

  handleSelectClick = () => {
    if ( this.props.account.selectedTeam == null ) {
      this.setState({openTeamNotSelected: true})
    } else {
      const team = this.getSelectedTeam()
      if (this.props.card.sportId === team.sportId) {
        this.props.toggleResponse(
          this.props.card.id, 
          this.props.account.selectedTeam,
          this.props.login.token
        )
      } else this.setState({openSportMismatchAlert: true})
    }
  };

  handleToggleRefereeRequest = () => {
    this.props.toggleRefereeRequest(
      this.props.card.id, 
      this.props.account.selectedTeam,
      this.props.login.token
    )
  }

  handleConfirmMatchClick = () => {
    this.props.confirmMatch(
      this.props.login.token,
      this.props.card.id
    )
  };

  handleOpponentDivisionsListClose = () => {
    this.setState({opponentDivisionsListAnchor: null})
  }

  render() {
    const confirmed = (!this.props.card.isOwn && this.props.login.state === LOGGED_IN && this.props.card.responseStatus === 1)
    const { classes } = this.props;
    const gridStyle = {marginLeft:"10px"}
    const url = `/ad/${ this.props.card.id }`
    const cardMediaOverlayStyle = {
      display: 'flex',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,.2)',
      fontFamily: 'sans-serif',
      borderRadius: 3,
      color: '#fff',
      letterSpacing: .3,
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    };
    return (
      <Card className={classes.card} style={{margin: this.props.margin}}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          key={`top,right`}
          open={this.state.snackOpen}
          message="Linkki kopioitiin leikepöydälle."
        />
      <CardMedia
          className={classes.media}
          image={this.props.card.image}
        >
          <div style={ cardMediaOverlayStyle }>
            {this.props.card.sport}
          </div>
        </CardMedia>
        <CardContent className={classes.cardContent} style={{ paddingBottom: 16 }}>

        <Typography style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', textAlign: 'center', margin: '10px 10px 5px 10px', padding: '6px 12px', border: '1px solid #ddd', borderRadius: 10, boxShadow: '2px 2px 2px #eee'}}>
          { this.props.card.teamName }
        </Typography>

        <Grid style={{ display: "flex", justifyContent: "space-between", paddingTop: 4 }}>
          <Grid item xs={6} style={{ display: "inline-flex" }}>
            <List style={{ marginRight: 12, paddingBottom: 0 }} className={classes.root}>
            <ListItem className={classes.listItem}>
               <Avatar className={classes.avatar}>
                 <Place className={classes.avatarIcon}/>
               </Avatar>
               <Grid style={gridStyle}>
                 <Grid item>
                   <ListItemText secondary="Sijainti" />
                 </Grid>
                 <Grid item>
                 <ListItemText primary={<span><span>{ this.props.card.location }</span><br /><span style={{ visibility: 'hidden' }}>-</span></span>}/>
                 </Grid>
               </Grid>
             </ListItem>
              <ListItem className={classes.listItem}>
                <Avatar className={classes.avatar}>
                  <ArrowRight className={classes.avatarIcon}/>
                </Avatar>
        				<Grid style={gridStyle}>
        					<Grid item>
        						<ListItemText secondary="Ilmoittajajoukkue" />
        					</Grid>
        					<Grid item>
                		<ListItemText primary={<span><span>{this.props.card.type0},</span><br /><span>{this.props.card.series0}</span></span>}/>
        					</Grid>
        				</Grid>
              </ListItem>
              <ListItem className={classes.listItem}>
                 <Avatar className={classes.avatar}>
                   <Timelapse className={classes.avatarIcon}/>
                 </Avatar>
                 <Grid style={gridStyle}>
                   <Grid item>
                     <ListItemText secondary="Vuoro" />
                   </Grid>
                   <Grid item>
                     <ListItemText primary={ this.props.card.turn } />
                   </Grid>
                 </Grid>
               </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} style={{ display: "inline-flex" }}>
            <List style={{ paddingBottom: 0 }} className={classes.root}>
              <ListItem className={classes.listItem}>
                <Avatar className={classes.avatar}>
                  <DateRange className={classes.avatarIcon}/>
                </Avatar>
                <Grid style={gridStyle}>
                  <Grid item>
                    <ListItemText secondary="Ajankohta" />
                  </Grid>
                  <Grid item>
                    <ListItemText primary={<span><span>{ this.props.card.date }</span><br /><span>{this.props.card.time}</span></span>}/>
                  </Grid>
                </Grid>
              </ListItem>
             <ListItem className={classes.listItem}>
                <Avatar className={classes.avatar}>
                  <ArrowRight className={classes.avatarIcon}/>
                </Avatar>
                <Grid style={gridStyle}>
                  <Grid item>
                    <ListItemText secondary="Haettu joukkue" />
                  </Grid>
                  <Grid item>
                    <ListItemText primary={
                      <span>
                        <span>{this.props.card.type1},</span><br />
                        {
                          this.props.card.opponentDivisions.length > 1 &&
                          <span>
                            <Link 
                              style={{ fontSize: '0.9285714285714286rem', display: 'flex' }}
                              component="button" 
                              variant="body2" 
                              onClick={e => {
                                this.setState({opponentDivisionsListAnchor: e.currentTarget})
                              }}>
                                {this.props.card.opponentDivisions[this.props.card.opponentDivisions.length - 1]} <ExpandMore />
                            </Link>
                            <Menu
                              anchorEl={this.state.opponentDivisionsListAnchor}
                              open={Boolean(this.state.opponentDivisionsListAnchor)}
                              onClose={this.handleOpponentDivisionsListClose}
                            >
                              {this.props.card.opponentDivisions.map((division, i) =>
                                <MenuItem key={i} onClick={this.handleOpponentDivisionsListClose}>{ division }</MenuItem>
                              ).reverse()}
                            </Menu>
                          </span>
                        }
                        {
                          this.props.card.opponentDivisions.length <= 1 &&
                          <span>{this.props.card.opponentDivisions[0]}</span>
                        }
                      </span>
                    }/>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </Grid>

        </Grid>
        <Grid style={{ paddingBottom: 4 }}>
        <ListItem className={classes.listItem}>
                <Avatar className={classes.avatar}>
                  <Payment className={classes.avatarIcon}/>
                </Avatar>
                <Grid style={gridStyle}>
                  <Grid item>
                    <ListItemText secondary="Kustannukset" />
                  </Grid>
                  <Grid item>
                    <ListItemText primary={ this.props.card.cost } />
                  </Grid>
                </Grid>
              </ListItem>
              </Grid>

        <Grid style={{ paddingBottom: 4 }}>
          <ListItem className={classes.listItem}>
                <Grid style={gridStyle}>
                  <Grid item>
                    <ListItemText secondary="Kenttä" />
                  </Grid>
                  <Grid item>
                    <ListItemText primary={ this.props.card.environment } />
                  </Grid>
                </Grid>
          </ListItem>
        </Grid>

      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          { ((!(this.props.login.state === LOGGED_IN) || !this.props.card.isOwn) && (!(this.props.login.state === LOGGED_IN) || this.props.card.responseStatus < 0)) &&
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={ e => {
                  if ( this.props.login.state !== LOGGED_IN ) {
                    this.props.promptLogin("Vain sisäänkirjautuneet käyttäjät voivat vastata ilmoituksiin.")
                  } else {
                    this.handleSelectClick()
                  }
                }}
                className={[classes.button, classes.responses].join(' ')}
              >
                <CheckCircle style={{ marginRight: "5px" }}/>Vastaa ilmoitukseen
            </Fab>
          }
          { this.props.card.responseStatus === 2 && this.props.card.refereeRequested === null &&
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                style={{ marginBottom: 12 }}
                onClick={ () => {
                  this.handleToggleRefereeRequest()
                } }
                className={[classes.button, classes.responses].join(' ')}
              >
                <Person style={{ marginRight: "5px" }}/>Kutsu otteluun tuomari
            </Fab>
          }
          { this.props.card.responseStatus === 2 && this.props.card.refereeRequested !== null && this.props.card.refereeResponseStatus < 0 &&
            <Fab
                variant="extended"
                size="medium"
                color="secondary"
                style={{ marginBottom: 12 }}
                onClick={ () => {
                  this.handleToggleRefereeRequest()
                } }
                className={classes.button}
              >
                <Cancel style={{ marginRight: "5px" }}/>Peruuta tuomarikutsu
            </Fab>
          }
          { this.props.card.responseStatus === 2 && this.props.card.refereeRequested !== null && this.props.card.refereeResponseStatus === 1 &&
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={() => {}}
                style={{ color: "#aaaaaa", backgroundColor: "#fff", marginBottom: 12 }}
                className={classes.button}
              >
                <HourglassEmpty style={{ marginRight: "5px" }}/>Odotetaan tuomaria....
            </Fab>
          }
          { 
            this.props.card.responseStatus === 2 && 
            this.props.card.refereeRequested !== null && 
            this.props.card.refereeResponseStatus === 2 &&
            this.getSelectedTeam().profileType === PROFILE_TYPE_NORMAL  &&
              <Fab
                  variant="extended"
                  size="medium"
                  style={{ color: "#000", backgroundColor: "#fff", marginBottom: 12  }}
                  onClick={ () => {
                    this.redirectIfNotThere(`/team/${ this.props.card.refereeId }`)
                  } }
                  className={classes.button}
                >
                  <Launch style={{ marginRight: "5px" }}/>Näytä tuomarisivu
              </Fab>
          }
          { 
            this.props.card.responseStatus === 2 && 
            this.props.card.refereeRequested !== null && 
            this.props.card.refereeResponseStatus === 2 &&
            this.getSelectedTeam().profileType === PROFILE_TYPE_REFEREE &&
              <Fab
                  variant="extended"
                  size="medium"
                  style={{ color: "#000", backgroundColor: "#fff", marginBottom: 12  }}
                  onClick={ () => {
                    this.redirectIfNotThere(`/team/${ this.props.card.teamId }`)
                  } }
                  className={classes.button}
                >
                  <Launch style={{ marginRight: "5px" }}/>Näytä ilmoittajan joukkuesivu
              </Fab>
          }
          { this.props.card.responseStatus === 2 && this.props.card.refereeRequested !== null && this.props.card.refereeResponseStatus === 0 &&
            <React.Fragment>
              <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  style={{ marginBottom: 12 }}
                  onClick={() => {
                    if ( this.props.card.refereeResponses ) {
                      this.props.beginConfirmResponse(this.props.card.id)
                      this.props.getResponses(
                        this.props.login.token,
                        this.props.card.id
                      )
                    }
                  }}
                  className={[classes.button, (this.props.card.refereeResponses ? classes.responses : classes.noResponses)].join(' ')}
                >
                  {!this.props.card.refereeResponses
                    ? <Lens style={{ marginRight: "5px" }}/>
                    : <CheckCircle style={{ marginRight: "5px" }}/>
                  }
                  {!this.props.card.refereeResponses 
                    ? 'Ei tuomarivastauksia' 
                    : (this.props.card.refereeResponses + ' tuomarivastaus' + (this.props.card.refereeResponses > 1 ? 'ta' : ''))
                  }
              </Fab>
            </React.Fragment>
          }
          { this.props.card.responseStatus === 2 &&
            <Fab
                variant="extended"
                size="medium"
                style={{ color: "#000", backgroundColor: "#fff" }}
                onClick={ () => {
                  this.redirectIfNotThere(`/team/${ this.props.card.opponentId }`)
                } }
                className={classes.button}
              >
                <Launch style={{ marginRight: "5px" }}/>Näytä vastustajan joukkuesivu
            </Fab>
          }
          { (!this.props.card.isOwn && this.props.login.state === LOGGED_IN && this.props.card.responseStatus === 0) &&
            <Fab
                variant="extended"
                size="medium"
                color="secondary"
                onClick={ this.handleSelectClick }
                className={classes.button}
              >
                <Cancel style={{ marginRight: "5px" }}/>Peruuta vastaus
            </Fab>
          }
          { confirmed &&
            <Fab
                color="primary"
                variant="extended"
                size="medium"
                onClick={ this.handleConfirmMatchClick }
                style={{ color: "#fff", backgroundColor: "#0c0" }}
                className={classes.button}
              >
                <Check style={{ marginRight: "5px" }}/>Vahvista ottelu
            </Fab>
          }
          { (this.props.card.isOwn && this.props.card.responseStatus < 1 && this.props.login.state === LOGGED_IN ) &&
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={() => {
                  if ( this.props.card.responses ) {
                    this.props.beginConfirmResponse(this.props.card.id)
                    this.props.getResponses(
                      this.props.login.token,
                      this.props.card.id
                    )
                  }
                }}
                className={[classes.button, (this.props.card.responses ? classes.responses : classes.noResponses)].join(' ')}
              >
                {!this.props.card.responses
                  ? <Lens style={{ marginRight: "5px" }}/>
                  : <CheckCircle style={{ marginRight: "5px" }}/>
                }
                {!this.props.card.responses 
                  ? 'Ei vastauksia' 
                  : (this.props.card.responses + ' vastaus' + (this.props.card.responses > 1 ? 'ta' : ''))
                }
            </Fab>
          }
          { (this.props.card.isOwn && this.props.card.responseStatus === 1 && this.props.login.state === LOGGED_IN ) &&
            <Fab
                variant="extended"
                size="medium"
                color="primary"
                onClick={() => {}}
                style={{ color: "#aaaaaa", backgroundColor: "#fff" }}
                className={classes.button}
              >
                <HourglassEmpty style={{ marginRight: "5px" }}/>
                <span style={{ fontSize: '.9em', textAlign: 'left',  lineHeight: '15px' }}>Odotetaan<br />vastustajaa....</span>
            </Fab>
          }
          </div>
          
          { this.props.card.responseStatus < 2 &&
            <React.Fragment>
              { (!this.props.card.responses && this.props.card.isOwn && this.props.login.state === LOGGED_IN) && 
                <Tooltip title="Muokkaa ilmoitusta" aria-label="Muokkaa ilmoitusta">
                  <IconButton onClick={e => {
                    this.props.beginEditAd(this.props.card.id)
                  }}>
                    <Edit />
                  </IconButton> 
                </Tooltip>
              }
              
              { (!!this.props.card.responses && this.props.card.isOwn && this.props.login.state === LOGGED_IN) && 
                <Tooltip title="Poista ilmoitus" aria-label="Poista ilmoitus">
                    <IconButton onClick={e => {
                      if ( window.confirm('Oletko varma, että haluat poistaa ilmoituksen? Kaikki ilmoitukseen liittyvät tiedot hävitetään.') ) {
                        this.props.deleteAd(
                          this.props.login.token,
                          this.props.card.id
                        )
                      }
                    }}>
                    <Delete />
                  </IconButton>             
                </Tooltip>
              }
              <Tooltip title="Kopioi linkki leikepöydälle" aria-label="Kopioi linkki leikepöydälle">
                <CopyToClipboard text={ process.env.REACT_APP_FRONTEND_URL + url }>
                  <IconButton onClick={e => {
  //                  this.redirectIfNotThere(url)
                    if ( this.state.snackTimeout != null ) {
                      clearTimeout(this.state.snackTimeout)
                    }
                    this.setState({
                      snackOpen: true,
                      snackTimeout: setTimeout(() => {
                        this.setState({
                          snackOpen: false
                        })
                      }, 2000)
                    })
                    e.preventDefault()
                  }}>
                    <LinkIcon />
                  </IconButton>
                </CopyToClipboard> 
              </Tooltip>
            </React.Fragment>
         }

          </div>
          <Typography style={{ display: 'none', textAlign: 'left', left: 20, top: 4, position: 'relative', height: 0, fontSize: '.6rem', color: '#888'}}>
            Ilmoitus jätetty { moment(this.props.card.createdAt).format('DD.MM.YYYY HH:mm')}
          </Typography>
        </CardContent>
        <Dialog
          open={this.state.openSportMismatchAlert}
          onClose={e => {this.setState({openSportMismatchAlert: false})}}
        >
          <DialogTitle>Virhe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ilmoituksen ja valitun profiilin lajit eivät täsmää. Valitse toinen profiili.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={e => {this.setState({openSportMismatchAlert: false})}} color="primary" autoFocus>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(this.state.openTeamNotSelected)}
          onClose={e => {this.setState({openTeamNotSelected: false})}}
        >
          <DialogTitle>Virhe</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Joukkuetta ei ole valittu.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={e => {this.setState({openTeamNotSelected: false})}} color="primary" autoFocus>
              Sulje
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    );
  }
}

MatchCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ 
  login: state.login,
  account: state.account
});

const mapDispatchToProps = dispatch => ({
  toggleResponse: (adId, teamContext, token) => {
    dispatch(toggleResponse(adId, teamContext, token))
  },
  toggleRefereeRequest: (adId, teamContext, token) => {
    dispatch(toggleRefereeRequest(adId, teamContext, token))
  },
  beginEditAd: adId => {
		dispatch(beginEditAd(adId))
	},
  deleteAd: (token, adId) => {
		dispatch(deleteAd(token, adId))
  },
  beginConfirmResponse: adId => {
    dispatch(beginConfirmResponse(adId))
  },
  getResponses: (token, adId) => {
    dispatch(getResponses(token, adId))
  },
  confirmMatch: (token, adId) => {
		dispatch(confirmMatch(token, adId))
	}
})

export default 
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MatchCard)
);