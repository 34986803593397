import React, { Component } from 'react';
import { connect } from "react-redux";
import TopBar from '../components/TopBar'
import NewAdButton from '../components/NewAdButton'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios';
import ChipInput from 'material-ui-chip-input';
import moment from 'moment';

import {
    Card,
    CardContent,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
    CardMedia,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Tooltip,
    IconButton,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';

import {
    Edit
} from '@material-ui/icons';

import { 
	getTeam
} from '../redux/view/actions';

import { 
    beginEditTeam
  } from '../redux/account/actions'  

import {
	teamTypeToString,
	divisionToString
} from '../utils';

import {
    EDIT_TEAM_SUCCESS,
    BACKEND_URL,
    LOGGED_IN,
    PROFILE_TYPE_NORMAL,
    PROFILE_TYPE_REFEREE
} from '../const'

const styles = theme => ({
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
      }
})

class TeamView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openAlert: false,
            alertContent: null,
            tab: 0,
            history: []
        }
    }

    componentWillMount(){
        this.refresh(this.props)
    }

    refresh(props) {
        this.props.getTeam(
            props.teamId,
            props.login.token
        )
        if ( this.state.tab === 1 ) {
            this.getHistory(props)
        }
    }

    getHistory(props) {
        const headers = props.login.token 
            ? {Authorization: props.login.token} 
            : null                                            
        axios.get(
            props.team.users
                ? `${BACKEND_URL}/team/${props.teamId}/history`
                : `${BACKEND_URL}/team/${props.account.selectedTeam}/history/${props.teamId}`,
            {headers}
        ).then(res => {
            this.setState({history: res.data})
        }).catch(err => {
            this.setState({history: []})
            console.error(err)
        })
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.login.state !== nextProps.login.state ) {
            this.refresh(nextProps)
        }
        if ( nextProps.account.editTeamId === nextProps.teamId &&
            this.props.account.editTeamState !== nextProps.account.editTeamState &&
            nextProps.account.editTeamState === EDIT_TEAM_SUCCESS ) {
                this.refresh(nextProps)
        }
        if ( this.props.teamId !== nextProps.teamId ) {
            this.refresh(nextProps)
        }
    }

    render(){
        const { classes } = this.props;
        const gridStyle = {marginLeft: 0, paddingLeft: 0}
        const team = this.props.team
        const sport = team 
            ? (this.props.sports.find(sport => team.sportId === sport.id) || {name: null})
            : null
        return (
            <div className="BrowseView TeamView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: this.props.desktop ? 67 : 63,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom
                }}>
                <TopBar history={this.props.history}
                    title={ team.profileType === PROFILE_TYPE_NORMAL 
                        ? "Joukkueen tiedot" 
                        : "Tuomarin tiedot"
                    }
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />

                <Dialog
                    open={this.state.openAlert}
                    onClose={e => {this.setState({openAlert: false})}}
                >
                    <DialogTitle>Virhe</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            { this.state.alertContent }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => {this.setState({openAlert: false})}} color="primary" autoFocus>
                            Sulje
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    !team && <Typography style={{ margin: 10 }}>Profiilia ei löytynyt.</Typography>
                }
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {
                        team &&
                        <Card style={{ width: 640, maxWidth: 640, marginTop: 10, marginBottom: 10 }}>
                            <CardMedia
                                component="img"
                                height="80"
                                style={{ opacity: 0.65 }}
                                image={`/images/${sport.image}`}
                            />
                            {
                                team.profileType === PROFILE_TYPE_NORMAL &&
                                <Tabs
                                    value={this.state.tab}
                                    onChange={(event, newValue) => {
                                        this.setState({tab: newValue})
                                        if ( newValue === 1 ) {
                                            this.getHistory(this.props)
                                        }
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    aria-label="full width tabs example">
                                    <Tab label="Tiedot" />
                                    <Tab disabled={ this.props.login.state !== LOGGED_IN } label="Otteluhistoria" />                        
                                </Tabs>
                            }
                            { this.state.tab === 0 &&
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <div style={{ display: 'flex', flexFlow: 'row' }}>
                                        <div style={{ flex: 1 }}>
                                            <Typography gutterBottom variant="h5" component="h2" style={{ marginBottom: '0.15em' }}>
                                                { team.name }
                                            </Typography>
                                            <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                <Typography gutterBottom component="h2" style={{ marginBottom: 0, marginRight: 16, fontWeight: 'bold'}}>
                                                    { sport.name }
                                                </Typography>
                                                { team.profileType === PROFILE_TYPE_NORMAL &&
                                                    <Typography gutterBottom component="h3" style={{ display: 'flex', alignItems: 'center', fontSize: '0.8em', color: '#888' }}>
                                                        { teamTypeToString(team.type) }, { divisionToString(team.division) }
                                                    </Typography>
                                                }
                                                { team.profileType === PROFILE_TYPE_REFEREE &&
                                                    <Typography gutterBottom component="h3" style={{ display: 'flex', alignItems: 'center', fontSize: '0.8em', color: '#888' }}>
                                                        Tuomari
                                                    </Typography>
                                                }
                                            </div>
                                        </div>
                                        { 
                                            this.props.team.users &&
                                            <div>
                                                <Tooltip title="Muokkaa profiilia" aria-label="Muokkaa profiilia">
                                                    <IconButton onClick={e => {
                                                        this.props.beginEditTeam(team.id)
                                                        e.stopPropagation()
                                                    }}>
                                                    <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                    </div>
                                    <Grid item xs={12} style={{ display: "flex" }}>
                                        <List style={{ paddingTop: 0, width: '100%' }} className={classes.root}>
                                            {
                                                team.generalInfo && team.generalInfo.length &&
                                                <ListItem style={{ paddingLeft: 0 }}>
                                                    <Grid style={{...gridStyle, width: '100%'}}>
                                                        <Grid item>
                                                            <ListItemText secondary="Kuvaus" />
                                                        </Grid>
                                                        <Grid item>
                                                            <ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={ team.generalInfo } />
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            { 
                                                team.contactInfo && team.contactInfo.length &&
                                                <ListItem style={{ paddingLeft: 0 }}>
                                                    <Grid style={gridStyle}>
                                                        <Grid item>
                                                            <ListItemText secondary="Yhteystiedot" />
                                                        </Grid>
                                                        <Grid item>
                                                            <ListItemText style={{ whiteSpace: 'pre-wrap' }} primary={ team.contactInfo } />
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                            {
                                                team.profileType === PROFILE_TYPE_NORMAL && this.props.team.users &&
                                                <ListItem style={{ paddingLeft: 0 }}>
                                                    <Grid style={gridStyle}>
                                                        <Grid item>
                                                            <ListItemText secondary="Jäsenet" />
                                                        </Grid>
                                                        <Grid item>
                                                        <ChipInput
                                                            style={{ boxSizing: 'border-box' }}
                                                            value={this.props.team.users.map(user => user.email)}
                                                            placeholder="Lisää käyttäjä..."
                                                            alwaysShowPlaceholder={true}
                                                            onAdd={addChip => {
                                                                const headers = this.props.login.token 
                                                                    ? {Authorization: this.props.login.token} 
                                                                    : null                                            
                                                                axios.post(
                                                                    `${BACKEND_URL}/team/${this.props.teamId}/member`, 
                                                                    {email: addChip},
                                                                    {headers}
                                                                ).then(res => {
                                                                    this.refresh(this.props)
                                                                }).catch(err => {
                                                                    if (err.response.status === 404 ) {
                                                                        this.setState({
                                                                            openAlert: true,
                                                                            alertContent: 'Sähköpostiosoitteella ei löytynyt käyttäjää.'
                                                                        })
                                                                    }
                                                                    console.error(err)
                                                                })
                                                            }}
                                                            onDelete={deleteChip => {
                                                                if ( window.confirm("Haluatko varmasti poistaa jäsenen joukkueesta?") ) {
                                                                    const headers = this.props.login.token 
                                                                        ? {Authorization: this.props.login.token} 
                                                                        : null                                            
                                                                    axios.delete(
                                                                        `${BACKEND_URL}/team/${this.props.teamId}/member`, 
                                                                        {headers, data: {email: deleteChip}}
                                                                    ).then(res => {
                                                                        this.refresh(this.props)
                                                                    }).catch(err => {
                                                                        if (err.response.status === 404 ) {
                                                                            this.setState({
                                                                                openAlert: true,
                                                                                alertContent: 'Sähköpostiosoitteella ei löytynyt käyttäjää.'
                                                                            })
                                                                        } else if ( err.response.status === 409 ) {
                                                                            this.setState({
                                                                                openAlert: true,
                                                                                alertContent: 'Käyttäjää ei voi poistaa, sillä hänet on merkitty joukkueen omistajaksi.'
                                                                            })
                                                                        }
                                                                        console.error(err)
                                                                    })
                                                                }
                                                            }}
                                                        />
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            }
                                        </List>
                                    </Grid>
                                </CardContent>
                            }
                            { this.state.tab === 1 && !!this.state.history.length &&
                                <CardContent style={{ paddingBottom: 0 }}>
                                    <Table aria-label="a table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{fontWeight: 'bold'}}>Ajankohta</TableCell>
                                                <TableCell style={{fontWeight: 'bold'}}>Joukkueet</TableCell>
                                                <TableCell style={{fontWeight: 'bold'}}>Sijainti</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.history.map(match => {
                                                const date = moment(match.date)
                                                return (
                                                    <TableRow key={match.id}>
                                                        <TableCell>
                                                            {date.format('DD.MM.YYYY')} 
                                                            <br />{date.format('HH:mm')} - {moment(date).add(match.duration, 'minutes').format('HH:mm')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {match.team.name}
                                                            <br />{match.opponent.name}
                                                        </TableCell>
                                                        <TableCell>{match.location.name}</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>                            
                            </CardContent>
                            }
                        </Card>
                    }
                </div>
                <NewAdButton promptLogin={this.props.promptLogin} 
                	padding={this.props.padding}
					desktop={this.props.desktop} 
					history={this.props.history} 
					margins={this.props.margins} />
            </div>
        )
    }

}

TeamView.propTypes = {
    teamId: PropTypes.number.isRequired,
};  

const mapStateToProps = state => ({
    login: state.login,
    team: state.view.data,
    viewType: state.view.type,
    viewStatus: state.view.status,
    sports: state.static.sports,
    account: state.account
})
const mapDispatchToProps = dispatch => ({
    getTeam: (id, token) => {
        dispatch(getTeam(id, token))
    },
    beginEditTeam: teamId => {
        dispatch(beginEditTeam(teamId))
    },    
})

export default 
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeamView)
);