import axios from 'axios'
import moment from 'moment'

import { 
    BACKEND_URL,
    SEARCH_RESULT,
    SEARCH_OWN_RESPONSES_RESULT,
    SEARCH_OWN_ADS_RESULT,
    SEARCH_OWN_MATCHES_RESULT,
    SEARCH_AD_BY_ID_RESULT,
    NOTIFICATION_AD,
    NOTIFICATION_RESPONSE,
    NOTIFICATION_MATCH,
    AD
} from '../../const'

import { 
    TOGGLE_SPORT,
    SELECT_SPORTS,
    SELECT_OUTER_REGION,
    SELECT_INNER_REGION,
    SEARCH,
    SEARCH_TO_PATCH,
    TOGGLE_RESPONSE,
    TOGGLE_REFEREE_REQUEST,
    REMOVE_FROM_RESULTS,
    SET_START_DATE,
    SET_END_DATE,
    SET_START_TIME,
    SET_END_TIME,
    SET_TIME_RANGE,
    SELECT_TURN,
    SELECT_EXPENSE_MODEL,
    SELECT_TYPE,
    SELECT_DIVISION,
    SELECT_MY_TYPE,
    SELECT_MY_DIVISION,
    TOGGLE_CATEGORY_EXPANDED,
    SET_LOCATIONS,
    SET_SORT_ORDER,
    SET_FILTER,
    SELECT_DETAIL_0,
    SELECT_DETAIL_1,
    SELECT_ENVIRONMENT
} from "./types";

import { 
    getNotifications 
} from "../account/actions";

export const selectSports = sports => ({
    type: SELECT_SPORTS,
    payload: {sports}
})

export const toggleSport = id => ({
    type: TOGGLE_SPORT,
    payload: {id}
})

export const selectOuterRegion = id => ({
    type: SELECT_OUTER_REGION,
    payload: {id}
})

export const selectInnerRegion = id => ({
    type: SELECT_INNER_REGION,
    payload: {id}
})

export const removeFromResults = ids => ({
    type: REMOVE_FROM_RESULTS,
    payload: ids
})

export const setStartDate = date => ({
    type: SET_START_DATE,
    payload: date
})

export const setEndDate = date => ({
    type: SET_END_DATE,
    payload: date
})

export const setStartTime = time => ({
    type: SET_START_TIME,
    payload: time
})

export const setEndTime = time => ({
    type: SET_END_TIME,
    payload: time
})

export const setTimeRange = range => ({
    type: SET_TIME_RANGE,
    payload: range
})

export const selectTurn = turn => ({
    type: SELECT_TURN,
    payload: {turn}
})

export const selectExpenseModel = expenseModel => ({
    type: SELECT_EXPENSE_MODEL,
    payload: {expenseModel}
})

export const selectType = type => ({
    type: SELECT_TYPE,
    payload: {type}
})

export const selectDivision = division => ({
    type: SELECT_DIVISION,
    payload: {division}
})

export const selectMyType = myType => ({
    type: SELECT_MY_TYPE,
    payload: {myType}
})

export const selectDetail0 = detail0 => ({
    type: SELECT_DETAIL_0,
    payload: {detail0}
})

export const selectDetail1 = detail1 => ({
    type: SELECT_DETAIL_1,
    payload: {detail1}
})

export const selectEnvironment = environment => ({
    type: SELECT_ENVIRONMENT,
    payload: {environment}
})

export const selectMyDivision = myDivision => ({
    type: SELECT_MY_DIVISION,
    payload: {myDivision}
})

export const toggleCategoryExpanded = i => ({
    type: TOGGLE_CATEGORY_EXPANDED,
    payload: {i}
})

export const setLocations = locations => ({
    type: SET_LOCATIONS,
    payload: {locations}
})

export const setSortOrder = sortOrder => ({
    type: SET_SORT_ORDER,
    payload: {sortOrder}
})

export const setFilter = filter => ({
    type: SET_FILTER,
    payload: {filter}
})

export const clearNotifications = (type, teamId, token) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.patch(`${BACKEND_URL}/team/${teamId}/notifications/${type}`, null, {headers})
            .then(() => {
                dispatch(getNotifications(token, teamId))
            }).catch(err => {
                console.error(err)
            })
    }    
}

export const searchOwnResponses = (sortOrder, teamContext, token) => {
    let params = {
        sortOrder,
        teamContext,
        ownResponses: true
    }

    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}`, {params, headers})
            .then(res => {
                dispatch({
                    type: SEARCH,
                    payload: {
                        resultType: SEARCH_OWN_RESPONSES_RESULT,
                        results: res.data
                    }
                })
                dispatch(clearNotifications(
                    NOTIFICATION_RESPONSE, teamContext, token
                ))
            }).catch(err => {
                console.error(err)
            })
    }
}

export const searchOwnAds = (sortOrder, teamContext, token) => {
    let params = {
        sortOrder,
        teamContext,
        ownAds: true
    }

    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}`, {params, headers})
            .then(res => {
                dispatch({
                    type: SEARCH,
                    payload: {
                        resultType: SEARCH_OWN_ADS_RESULT,
                        results: res.data
                    }
                })
                dispatch(clearNotifications(
                    NOTIFICATION_AD, teamContext, token
                ))
            }).catch(err => {
                console.error(err)
            })
    }
}

export const searchOwnMatches = (searchParams, teamContext, token) => {
    let params = {
        filter: searchParams.filter,
        sortOrder: searchParams.sortOrder,
        teamContext,
        ownMatches: true
    }

    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}`, {params, headers})
            .then(res => {
                dispatch({
                    type: SEARCH,
                    payload: {
                        resultType: SEARCH_OWN_MATCHES_RESULT,
                        results: res.data
                    }
                })
                dispatch(clearNotifications(
                    NOTIFICATION_MATCH, teamContext, token
                ))
            }).catch(err => {
                console.error(err)
            })
    }
}

export const searchToPatch = (teamContext, type, ids, token, _clearNotifications=null) => {
    let params = {
        teamContext,
        ...type,
        ids: ids.join(',')
    }

    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}`, {params, headers})
            .then(res => {
                dispatch({
                    type: SEARCH_TO_PATCH,
                    payload: res.data
                })
                if ( _clearNotifications !== null ) {
                    dispatch(clearNotifications(
                        _clearNotifications, teamContext, token
                    ))            
                }
            }).catch(err => {
                console.error(err)
            })
    }
}

export const search = (
    sports, 
    outerRegion, 
    innerRegion, 
    startDate, 
    endDate, 
    startTime, 
    endTime, 
    type,
    division,
    myType,
    myDivision,
    turn, 
    expenseModel,
    locations,
    detail0,
    detail1,
    environment,
    sortOrder, 
    teamContext, 
    token
) => {

    let params = {
        sortOrder,
        teamContext
    }
    if ( sports.length ) params.sports = sports.join(',')
    if ( innerRegion && innerRegion >= 0 ) params.innerRegion = innerRegion
    else if ( outerRegion && outerRegion >= 0 ) params.outerRegion = outerRegion
    if ( locations && locations.length ) params.locations = locations.join(',')
    if ( detail0 && detail0 >= 0 ) params.detail0 = detail0
    if ( detail1 && detail1 >= 0 ) params.detail1 = detail1
    if ( environment && environment >= 0 ) params.environment = environment

    params.startDate = moment(startDate).format('YYYY-MM-DD')
    params.endDate = moment(endDate).format('YYYY-MM-DD')

    if ( startTime !== null ) {
        params.startTime = moment(startTime).utc().format('HH:mm:00')
        params.endTime = moment(endTime).utc().format('HH:mm:00')
    }

    if ( type >= 0 ) params.type = type
    if ( division >= 0 ) params.division = division
    if ( myType >= 0 ) params.myType = myType
    if ( myDivision >= 0 ) params.myDivision = myDivision
    if ( turn >= 0 ) params.turn = turn
    if ( expenseModel >= 0 ) params.expenseModel = expenseModel

    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}`, {params, headers})
            .then(res => {
                dispatch({
                    type: SEARCH,
                    payload: {
                        resultType: SEARCH_RESULT,
                        results: res.data
                    }
                })
            }).catch(err => {
                console.error(err)
            })
    }
}

export const searchAdById = (adId, teamContext, token) => {    
    let params = {ids: adId}
    if ( teamContext ) params.teamContext = teamContext

    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}`, {params, headers})
            .then(res => {
                dispatch({
                    type: SEARCH,
                    payload: {
                        resultType: SEARCH_AD_BY_ID_RESULT,
                        results: res.data
                    }
                })
            }).catch(err => {
                console.error(err)
            })
    }
}

export const toggleResponse = (adId, teamContext, token) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    const payload = {teamContext}

    return dispatch => {
        axios.patch(`${BACKEND_URL}/${AD}/${adId}`, payload, {headers})
            .then(res => {
                dispatch({
                    type: TOGGLE_RESPONSE,
                    payload: res.data
                })
            }).catch(err => {
                console.error(err)
            })
    }    
}

export const toggleRefereeRequest = (adId, teamContext, token) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    const payload = {teamContext}

    return dispatch => {
        axios.patch(`${BACKEND_URL}/${AD}/${adId}/referee_request`, payload, {headers})
            .then(res => {
                dispatch({
                    type: TOGGLE_REFEREE_REQUEST,
                    payload: res.data
                })
            }).catch(err => {
                console.error(err)
            })
    }    
}

export const redoSearch = (searchParams, teamContext, token) => {
    return dispatch => {
        if ( searchParams.resultType === SEARCH_AD_BY_ID_RESULT ) {
        } else if ( searchParams.resultType === SEARCH_OWN_RESPONSES_RESULT ) {
            dispatch(searchOwnResponses(searchParams.sortOrder, teamContext, token))
        } else if ( searchParams.resultType === SEARCH_OWN_ADS_RESULT ) {
            dispatch(searchOwnAds(searchParams.sortOrder, teamContext, token))
        } else if ( searchParams.resultType === SEARCH_OWN_MATCHES_RESULT ) {
            dispatch(searchOwnMatches(searchParams, teamContext, token))
        } else if ( searchParams.resultType === SEARCH_RESULT ) {
            dispatch(search(
                searchParams.selectedSports, 
                searchParams.selectedOuterRegion, 
                searchParams.selectedInnerRegion, 
                searchParams.selectedStartDate, 
                searchParams.selectedEndDate, 
                searchParams.selectedStartTime, 
                searchParams.selectedEndTime, 
                searchParams.selectedType, 
                searchParams.selectedDivision, 
                searchParams.selectedMyType, 
                searchParams.selectedMyDivision, 
                searchParams.selectedTurn, 
                searchParams.selectedExpenseModel, 
                searchParams.selectedLocations, 
                searchParams.selectedDetail0, 
                searchParams.selectedDetail1, 
                searchParams.selectedEnvironment, 
                searchParams.sortOrder, 
                teamContext, 
                token
            ))
        }
    }
}