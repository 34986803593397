import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from 'axios'
import TopBar from '../components/TopBar'
import {
    BACKEND_URL,
    REPORT_PATH,
    USER_TYPE_USER
} from '../const'
import {
    profileTypeToString
} from '../utils'
import moment from 'moment'

import {
    withWidth,
    Paper,
    Tab,
    Tabs,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Link
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import * as qs from 'query-string';

class ReportView extends Component {

    constructor(props) {
        super(props)
        this.state = {data: []}
    }

    clearData() {
        this.setState({data: []})
    }

    componentWillMount() {
        this.fetch(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.match !== nextProps.match ) {
            this.fetch(nextProps)
        }
    }

    isOnUserTab = props =>
        props.match.params.entity === 'user'

    fetch(props) {
        const path = `${BACKEND_URL}/report/${this.isOnUserTab(props) ? 'user' : 'team'}` 
        const params = {search: this.getSearchQuery(props), ...this.getSort(props)}
        const headers = props.login.token 
            ? {Authorization: props.login.token} 
            : null
        this.clearData()
        axios.get(path, {params, headers})
            .then(res => {
                this.setState({data: res.data})
            }).catch(err => {
                console.error(err)
            })
    }

    transferCredits(props, entity, id) {
        const amount = prompt('Lisää krediittejä')
        if ( amount !== null ) {
            const headers = props.login.token 
                ? {Authorization: props.login.token} 
                : null                                        
            const path = `${BACKEND_URL}/transfer/${entity}/${id}`
            axios.post(path, {amount: parseInt(amount)}, {headers})
                .then(res => {
                    this.fetch(props)
                }).catch(err => {
                    alert('Virhe!')
                })
        }
    }

    redirectIfNotThere(path) {
        if ( this.props.history.location.pathname !== path ) {
          this.props.history.push(path)
        }
      }    

    booleanToString = boolean => boolean ? 'Kyllä' : 'Ei'

    userTypeToString = type => 
        type === USER_TYPE_USER ? 'Käyttäjä' : 'Ylläpitäjä'

    getSearchQuery(props) {
        const params = qs.parse(props.location.search).search
        return params ? (Array.isArray(params) ? params : [params]) : []
    }

    getPath = (entity=null, search=null, sort=null) => {
        if ( !entity ) entity = this.isOnUserTab(this.props) ? 'user' : 'team'
        if ( !search ) search = this.getSearchQuery(this.props)
        if ( !sort ) sort = this.getSort(this.props)
        return `${REPORT_PATH.replace(':entity', entity)}?${qs.stringify({search, ...sort})}` 
    }

    getSort(props) {
        const params = qs.parse(props.location.search)
        return {
            sortBy: params.sortBy || 'id',
            sortOrder: params.sortOrder || 'asc'
        }
    }

    setSort(field, props) {
        const sort = this.getSort(props)
        if ( sort.sortBy === field ) {
            sort.sortOrder = sort.sortOrder === 'desc' ? 'asc' : 'desc'
            const path = this.getPath(null, null, sort)
            this.redirectIfNotThere(path)
        } else {
            sort.sortBy = field
            const path = this.getPath(null, null, sort)
            this.redirectIfNotThere(path)
        }
    }

    userHeader = [
        {
            field: 'id',
            label: 'ID'
        },
        {
            field: 'type',
            label: 'Tyyppi'
        },
        {
            field: 'email',
            label: 'Sähköpostiosoite'
        },
        {
            field: 'confirmed',
            label: 'Vahvistettu'
        },
        {
            field: 'createdAt',
            label: 'Tili luotu'
        },
        {
            field: 'lastActive',
            label: 'Viim. aktiivinen'
        },
        {
            field: 'credits',
            label: 'Krediitit'
        },
        {
            field: 'facebookId',
            label: 'Facebook'
        },
        {
            field: 'googleId',
            label: 'Google'
        },
        {
            label: 'Joukkueet'
        }
    ]

    teamHeader = [
        {
            field: 'id',
            label: 'ID'
        },
        {
            field: 'name',
            label: 'Nimi'
        },
        {
            field: 'profileType',
            label: 'Profiili'
        },
        {
            field: 'generalInfo',
            label: 'Kuvaus'
        },
        {
            field: 'contactInfo',
            label: 'Yhteystiedot'
        },
        {
            field: 'createdAt',
            label: 'Luotu'
        },
        {
            field: '_sport.name',
            label: 'Laji'
        },
        {
            field: 'credits',
            label: 'Krediitit'
        },
        {
            field: '_adCount',
            label: 'Ilmoitukset'
        },
        {
            field: '_responseCount',
            label: 'Vastaukset'
        },
        {
            field: '_matchCount',
            label: 'Ottelut'
        },
        {
            label: 'Käyttäjät'
        }
    ]

    render(){
        const timeFormat = 'DD.MM.YYYY HH:mm'
        const chips = this.getSearchQuery(this.props)
        return (
            <div className="BrowseView ReportView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: 0,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom
                }}>
                <TopBar history={this.props.history}
                    title={"Käyttäjätiedot"}
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />
                <Paper style={{ 
                    marginTop: 10, 
                    padding: 10, 
                    marginBottom: 10, 
                    height: this.props.desktop 
                        ? 'calc(100% - 80px)' 
                        : 'calc(100% - 20px)' }}
                    >
                    <Tabs
                        value={this.props.match.params.entity === 'user' ? 0 : 1}
                        onChange={(event, newValue) => {
                            this.redirectIfNotThere(REPORT_PATH.replace(':entity', newValue === 0 ? 'user' : 'team'))
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        >
                        <Tab label="Käyttäjät" />
                        <Tab label="Joukkueet" />                        
                    </Tabs>
                    <ChipInput
                        style={{ marginTop: 5, padding: 5, paddingLeft: 8, paddingRight: 8, boxSizing: 'border-box' }}
                        value={chips}
                        placeholder={ 
                            this.isOnUserTab(this.props) 
                            ? "Etsi ID:n tai sähköpostiosoitteen perusteella..."
                            : "Etsi ID:n tai joukkueen nimen perusteella..."
                        }
                        alwaysShowPlaceholder={false}
                        onAdd={addChip => {
                            const entity = this.isOnUserTab(this.props) ? 'user' : 'team'
                            const path = this.getPath(entity, [...chips, addChip])
                            this.redirectIfNotThere(path)
                        }}
                        onDelete={(deleteChip, deleteIndex) => {
                            chips.splice(deleteIndex, 1)
                            const entity = this.isOnUserTab(this.props) ? 'user' : 'team'
                            const path = this.getPath(entity, chips)
                            this.redirectIfNotThere(path)
                        }}
                    />
                    <div className="table-wrapper">
                    {
                        this.isOnUserTab(this.props) &&
                            <Table aria-label="a table">
                            <TableHead>
                                <TableRow>
                                    {
                                        this.userHeader.map((header, i) => 
                                            <TableCell key={i}>
                                                {
                                                    header.field &&
                                                    <TableSortLabel 
                                                        active={this.getSort(this.props).sortBy === header.field} 
                                                        direction={this.getSort(this.props).sortOrder}
                                                        onClick={() => this.setSort(header.field, this.props)}
                                                    >
                                                        {header.label}
                                                    </TableSortLabel>
                                                }
                                                {
                                                    !header.field && header.label
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{this.userTypeToString(row.type)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{row.email}</TableCell>
                                    <TableCell>{this.booleanToString(row.confirmed)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(row.createdAt).format(timeFormat)}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(row.lastActive).format(timeFormat)}</TableCell>
                                    <TableCell>
                                        <Link component="button" 
                                            style={{ textAlign: 'left' }}
                                            variant="body2" 
                                            onClick={e => {
                                                this.transferCredits(this.props, 'user', row.id)
                                            }}>
                                                {row.credits}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{this.booleanToString(row.facebookId != null)}</TableCell>
                                    <TableCell>{this.booleanToString(row.googleId != null)}</TableCell>
                                    <TableCell>{
                                        row.teams.map((team, i) => 
                                            <span key={i} style={{ display: 'block', whiteSpace: 'nowrap' }}>
                                                <Link component="button" 
                                                    style={{ textAlign: 'left' }}
                                                    variant="body2" 
                                                    onClick={e => {
                                                        this.redirectIfNotThere(this.getPath('team', [team.id]))
                                                    }}>
                                                        {team.name} ({team.id})
                                                </Link>
                                                { i < row.teams.length - 1 && <span>, </span> }
                                            </span>
                                        )                                
                                    }</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    {
                        !this.isOnUserTab(this.props) &&
                            <Table aria-label="a table">
                            <TableHead>
                                <TableRow>
                                {
                                        this.teamHeader.map((header, i) => 
                                            <TableCell key={i}>
                                                {
                                                    header.field &&
                                                    <TableSortLabel 
                                                        active={this.getSort(this.props).sortBy === header.field} 
                                                        direction={this.getSort(this.props).sortOrder}
                                                        onClick={() => this.setSort(header.field, this.props)}
                                                    >
                                                        {header.label}
                                                    </TableSortLabel>
                                                }
                                                {
                                                    !header.field && header.label
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{profileTypeToString(row.profileType)}</TableCell>
                                    <TableCell>{row.generalInfo}</TableCell>
                                    <TableCell>{row.contactInfo}</TableCell>
                                    <TableCell style={{ whiteSpace: 'nowrap' }}>{moment(row.createdAt).format(timeFormat)}</TableCell>
                                    <TableCell>{row.sportName}</TableCell>
                                    <TableCell>
                                        <Link component="button" 
                                            style={{ textAlign: 'left' }}
                                            variant="body2" 
                                            onClick={e => {
                                                this.transferCredits(this.props, 'team', row.id)
                                            }}>
                                                {row.credits}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.adCount}</TableCell>
                                    <TableCell>{row.responseCount}</TableCell>
                                    <TableCell>{row.matchCount}</TableCell>
                                    <TableCell>{
                                        row.users.map((user, i) => 
                                            <span key={i} style={{ display: 'block', whiteSpace: 'nowrap' }}>
                                                <Link component="button" 
                                                    style={{ textAlign: 'left' }}
                                                    variant="body2" 
                                                    onClick={e => {
                                                        this.redirectIfNotThere(this.getPath('user', [user.id]))
                                                    }}>
                                                        {user.email} ({user.id})
                                                </Link>
                                                { i < [row.user].length - 1 && <span>, </span> }
                                            </span>
                                        )                                
                                    }</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    }
                    </div>
                </Paper>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login
})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(ReportView)
)