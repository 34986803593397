import React from 'react';
import { connect } from "react-redux";

import {
  Label,
  Delete
} from '@material-ui/icons';

import {
  withMobileDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton
} from '@material-ui/core';

import { 
    clearEditTeam,
    beginEditTeam,
    editTeam,
    deleteTeam,
} from '../redux/account/actions'
  
import {
    EDIT_TEAM_INIT,
    EDIT_TEAM_FAILURE_409,
    EDIT_TEAM_FAILURE_500,
    EDIT_TEAM_SUCCESS,
    PROFILE_TYPE_NORMAL
} from '../const'  

import {
  divisionToString,
  teamTypeToString,
  profileTypeToString,
  getNumberOfDivisions
} from '../utils';

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class EditTeamDialog extends React.Component {

  constructor(props) {
        super(props)
        this.state = {
            editTeamName: "",
            editTeamProfileType: 0,
            editTeamType: 0,
            editTeamSport: 1,
            editTeamDivision: 999,
            editTeamGeneralInfo: "",
            editTeamContactInfo: "",
            editTeamOwned: false
          }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.account.editTeamState !== nextProps.account.editTeamState 
            && nextProps.account.editTeamState === EDIT_TEAM_SUCCESS ) {
              this.props.clearEditTeam()
          }      
        if ( this.props.account.editTeamState === null 
            && nextProps.account.editTeamState === EDIT_TEAM_INIT ) {
              if ( nextProps.account.editTeamId !== null ) {
                const team = nextProps.account.teams.find(
                  team => team.id === nextProps.account.editTeamId
                )
                console.log(team)
                console.log(nextProps.login)
                this.setState({
                  editTeamName: team.name,
                  editTeamProfileType: team.profileType,
                  editTeamSport: team.sportId,
                  editTeamDivision: team.division,
                  editTeamType: team.type,
                  editTeamGeneralInfo: team.generalInfo,
                  editTeamContactInfo: team.contactInfo,
                  editTeamOwned: team.ownerId === nextProps.login.user.id
                })
              } else {
                this.setState({
                  editTeamName: "",
                  editTeamProfileType: 0,
                  editTeamSport: 1,
                  editTeamType: 0,
                  editTeamDivision: 999,
                  editTeamGeneralInfo: "",
                  editTeamContactInfo: "",
                  editTeamOwned: false
                })
              }
          }      
    }

    redirectIfNotThere(path) {
      if ( this.props.history.location.pathname !== path ) {
        this.props.history.push(path)
      }
    }  

    render() {
    
        const classes = {}
        const numberOfDivisions = getNumberOfDivisions(
          this.props.sports, 
          this.state.editTeamSport, 
          this.state.editTeamType
        )
        const checkTeamDivisions = () => {
          if ( getNumberOfDivisions(
            this.props.sports, 
            this.state.editTeamSport, 
            this.state.editTeamType
          ) <= this.state.editTeamDivision ) {
            this.setState({editTeamDivision: 999})
          }  
        }

        return (
            <MobileDialog
              open={this.props.account.editTeamState !== null}
              onClose={e => {this.props.clearEditTeam()}}
            >
              <DialogTitle>{
                this.props.account.editTeamId === null 
                  ? 'Lisää profiili'
                  : 'Muokkaa profiilia'
              }</DialogTitle>
              <DialogContent>
                {
                  this.props.account.editTeamId === null &&
                    <DialogContentText>
                      Syötä perustiedot.
                    </DialogContentText>
                }
                <TextField
                  type="text"
                  label="Nimi"
                  autoFocus
                  value={ this.state.editTeamName }
                  onKeyUp={e => {
                    if ( e.keyCode === 13 ) {
                        this.props.editTeam(
                            this.props.login.token,
                            this.state.editTeamName,
                            this.state.editTeamSport,
                            this.state.editTeamDivision,
                            this.state.editTeamType,
                            this.state.editTeamGeneralInfo,
                            this.state.editTeamContactInfo,
                            this.props.account.editTeamId
                          )      
                    }
                  }}
                  onChange={e => {
                    if ( this.props.account.editTeamState === EDIT_TEAM_FAILURE_409 ||
                      this.props.account.editTeamState === EDIT_TEAM_FAILURE_500 ) {
                        this.props.beginEditTeam(this.props.account.editTeamId)
                      }
                    this.setState({editTeamName: e.target.value})
                  }}
                  InputProps={{
                    style: this.props.account.editTeamState === EDIT_TEAM_FAILURE_409 ||
                      this.props.account.editTeamState === EDIT_TEAM_FAILURE_500
                        ? { color: this.FAILURE_COLOR } 
                        : {},  
                    startAdornment: (
                      <InputAdornment position="start">
                        <Label />
                      </InputAdornment>
                    ),
                  }}
                />
                <DialogContentText style={{ color: 'red', fontSize: '.8em' }}>
                  { this.props.account.editTeamState === EDIT_TEAM_FAILURE_409 &&
                    'Samanniminen profiili on jo olemassa.' }
                </DialogContentText>
                <FormControl style={{ marginBottom: 16 }} className={classes.formControl}>
                  <InputLabel>
                    Profiili
                  </InputLabel>
                  <Select
                    disabled={this.props.account.editTeamId != null}
                    value={this.state.editTeamProfileType}
                    onChange={e => {
                      this.setState({
                        editTeamProfileType: e.target.value
                      })
                    }}
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    {[...Array(2).keys()].map(profileType => (
                      <MenuItem key={profileType} value={profileType}>{ profileTypeToString(profileType) }</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ marginBottom: 16 }} className={classes.formControl}>
                  <InputLabel>
                    Laji
                  </InputLabel>
                  <Select
                    disabled={this.props.account.editTeamId != null}
                    value={this.state.editTeamSport}
                    onChange={e => {
                      this.setState({
                        editTeamSport: e.target.value
                      }, checkTeamDivisions)
                    }}
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    { this.props.sports.map(sport => (
                      <MenuItem key={sport.id} value={sport.id}>{sport.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {
                  this.state.editTeamProfileType === PROFILE_TYPE_NORMAL &&
                  <React.Fragment>
                    <FormControl style={{ marginBottom: 16 }} className={classes.formControl}>
                      <InputLabel shrink>
                        Tyyppi
                      </InputLabel>
                      <Select
                        value={this.state.editTeamType}
                        onChange={e => {
                          this.setState({
                            editTeamType: e.target.value
                          }, checkTeamDivisions)
                        }}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        {[...Array(3).keys()].map(type => (
                          <MenuItem key={type} value={type}>{ teamTypeToString(type) }</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl style={{ marginBottom: 16 }} className={classes.formControl}>
                      <InputLabel shrink>
                        Sarjataso
                      </InputLabel>
                      <Select
                        value={this.state.editTeamDivision}
                        onChange={e => {this.setState({editTeamDivision: e.target.value})}}
                        displayEmpty
                        className={classes.selectEmpty}
                      >
                        { [...Array(numberOfDivisions).keys(), 999].reverse().map(division => (
                          <MenuItem key={division} value={division}>{ divisionToString(division) }</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </React.Fragment>
                }
                <FormControl style={{ marginBottom: 16 }} className={classes.formControl}>
                  <InputLabel shrink>
                    Kuvaus
                  </InputLabel>
                  <TextField 
                    margin="dense"
                    fullWidth
                    variant="filled"
                    hiddenLabel
                    multiline 
                    style={{ background: 'transparent' }}
                    onChange={ e => {
                      this.setState({editTeamGeneralInfo: e.target.value})
                    }}
                    value={ this.state.editTeamGeneralInfo }
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink>
                    Yhteystiedot
                  </InputLabel>
                  <TextField 
                    margin="dense"
                    fullWidth
                    variant="filled"
                    hiddenLabel
                    multiline 
                    style={{ background: 'transparent' }}
                    onChange={ e => {
                      this.setState({editTeamContactInfo: e.target.value})
                    }}
                    value={ this.state.editTeamContactInfo }
                  />
                </FormControl>
                { this.props.account.editTeamState === EDIT_TEAM_FAILURE_500 &&
                  <DialogContentText style={{ 
                      marginBottom: 0, 
                      marginTop: 16, 
                      color: 'red', 
                      fontSize: '.8em' 
                  }}>
                    Palvelinvirhe. Yritä myöhemmin uudestaan.
                  </DialogContentText>
                }
              </DialogContent>
              <DialogActions>
                  <div style={{ flex: 1, paddingLeft: 14, height: 48 }}>
                  { this.props.account.editTeamId !== null && this.state.editTeamOwned &&
                    <React.Fragment>
                      <Tooltip title="Poista profiili" aria-label="Poista profiili">
                        <IconButton onClick={() => {
                          if ( window.confirm('Oletko varma, että haluat poistaa profiilin? Kaikki profiiliin liittyvät tiedot hävitetään.') ) {
                            this.props.deleteTeam(
                              this.props.login.token,
                              this.props.account.editTeamId
                            )
                          }
                        }}>
                          <Delete />
                        </IconButton> 
                      </Tooltip>
                    </React.Fragment>
                }
                </div>
                <Button 
                  disabled={ !this.state.editTeamName.length }
                  onClick={e => {
                    this.props.editTeam(
                      this.props.login.token,
                      this.state.editTeamName,
                      this.state.editTeamProfileType,
                      this.state.editTeamSport,
                      this.state.editTeamDivision,
                      this.state.editTeamType,
                      this.state.editTeamGeneralInfo,
                      this.state.editTeamContactInfo,
                      this.props.account.editTeamId
                    )
                  }} color="primary" variant="contained">
                    Tallenna
                </Button>
                <Button onClick={e => {this.props.clearEditTeam()}} color="primary">
                  Peruuta
                </Button>
              </DialogActions>
            </MobileDialog>
        )
    }

}

const mapStateToProps = state => {
    return { 
      login: state.login,
      account: state.account,
      divisions: state.static.divisions,
      sports: state.static.sports  
    };
  };
  
const mapDispatchToProps = dispatch => ({
    beginEditTeam: teamId => {
        dispatch(beginEditTeam(teamId))
      },
      clearEditTeam: () => {
        dispatch(clearEditTeam())
      },
      editTeam: (token, name, profileType, sportId, division, type, generalInfo, contactInfo, teamId=null) => {
        dispatch(editTeam(token, name, profileType, sportId, division, type, generalInfo, contactInfo, teamId))
      },
      deleteTeam: (token, teamId) => {
        dispatch(deleteTeam(token, teamId))
      }    
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTeamDialog)