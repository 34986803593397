import axios from 'axios';
import { BACKEND_URL, AD } from '../../const'
import { 
    GET_TEAMS, 
    EDIT_TEAM,
    GET_TEAM_ATTRIBUTE,
    SELECT_TEAM, 
    BEGIN_EDIT_TEAM,
    CLEAR_EDIT_TEAM,
    BEGIN_EDIT_AD,
    EDIT_AD,
    CLEAR_EDIT_AD,
    GET_RESPONSES,
    BEGIN_CONFIRM_RESPONSE,
    CONFIRM_RESPONSE,
    CLEAR_CONFIRM_RESPONSE,
    GET_NOTIFICATIONS,
    CONFIRM_MATCH,
    BEGIN_TRANSFER_CREDITS,
    TRANSFER_CREDITS,
    CLEAR_TRANSFER_CREDITS
} from "./types";

export const getTeamAttribute = (token, teamId, attribute) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        axios.get(`${BACKEND_URL}/team/${teamId}/${attribute}`, {headers})
            .then(res => {
                dispatch({
                    type: GET_TEAM_ATTRIBUTE,
                    payload: {
                        teamId,
                        attribute,
                        data: res.data
                    }
                })
            }).catch(err => {
                console.error(err)
            })
    }    
}

export const getTeams = (token, select=null) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        axios.get(`${BACKEND_URL}/team`, {headers})
            .then(res => {
                const teams = res.data 
                dispatch({
                    type: GET_TEAMS,
                    payload: teams
                })
                if ( select === null && teams.length ) {
                    dispatch(selectTeam(teams[0].id, token))
                } else if ( select !== false ) {
                    dispatch(selectTeam(select, token))
                }
            }).catch(err => {
                console.error(err)
            })
    }
}

export const editTeam = (token, name, profileType, sportId, division, type, generalInfo, contactInfo, teamId=null) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    const payload = {
        name, profileType, sportId, division, type, generalInfo, contactInfo
    }
    if ( teamId !== null ) {
        return dispatch => {
            axios.put(`${BACKEND_URL}/team/${teamId}`, payload, {headers})
                .then(res => {
                    dispatch({
                        type: EDIT_TEAM,
                        payload: { status: 200 }
                    })
                    dispatch(getTeams(token, false))
                }).catch(err => {
                    dispatch({
                        type: EDIT_TEAM,
                        payload: {status: 
                            err.response
                                ? err.response.status
                                : 500 
                        }
                    })
                })
        }
    } else {
        return dispatch => {
            axios.post(`${BACKEND_URL}/team`, payload, {headers})
                .then(res => {
                    const team = res.data
                    dispatch({
                        type: EDIT_TEAM,
                        payload: { status: 200 }
                    })
                    dispatch(getTeams(token, team.id))
                }).catch(err => {
                    dispatch({
                        type: EDIT_TEAM,
                        payload: {status: 
                            err.response
                                ? err.response.status
                                : 500 
                        }
                    })
                })
        }
    }
}

export const deleteTeam = (token, teamId) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        axios.delete(`${BACKEND_URL}/team/${teamId}`, {headers})
            .then(() => {
                dispatch({
                    type: EDIT_TEAM,
                    payload: { status: 200 }
                })
                dispatch(getTeams(token))
            }).catch(err => {
                console.error(err)
            })
    }
}

export const selectTeam = (id, token) => {
    return dispatch => {
        dispatch({
            type: SELECT_TEAM,
            payload: id
        })
        if ( token ) {
            dispatch(getNotifications(token, id))
        }
    }
}

export const beginEditTeam = teamId => ({
    type: BEGIN_EDIT_TEAM,
    payload: teamId
})

export const clearEditTeam = () => ({
    type: CLEAR_EDIT_TEAM
})

export const beginEditAd = (adId=null) => ({
    type: BEGIN_EDIT_AD,
    payload: adId
})

export const clearEditAd = () => ({
    type: CLEAR_EDIT_AD
})

export const beginTransferCredits = () => ({
    type: BEGIN_TRANSFER_CREDITS
})

export const clearTransferCredits = () => ({
    type: CLEAR_TRANSFER_CREDITS
})

function post(path, params, method='post') {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
  
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
  
        form.appendChild(hiddenField);
      }
    }
  
    document.body.appendChild(form);
    form.submit();
}

export const editAd = (token, teamId, division, divisions, outerRegionId, innerRegionId, dateTime, duration, turn, expenseModel, type, locationId, detail0Id, detail1Id, environmentId, expires, adId=null) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    const payload = {
        token, 
        teamId, 
        division, 
        divisions,
        outerRegionId, 
        innerRegionId, 
        dateTime, 
        duration, 
        turn, 
        expenseModel, 
        type, 
        locationId, 
        detail0Id: detail0Id >= 0 ? detail0Id : null, 
        detail1Id: detail1Id >= 0 ? detail1Id : null,
        environmentId: environmentId >= 0 ? environmentId : null, 
        expires
    }
    if ( adId !== null ) {
        return dispatch => {
            axios.put(`${BACKEND_URL}/${AD}/${adId}`, payload, {headers})
                .then(() => {
                    dispatch({
                        type: EDIT_AD,
                        payload: { status: 200 }
                    })
                }).catch(err => {
                    dispatch({
                        type: EDIT_AD,
                        payload: {status: 
                            err.response
                                ? err.response.status
                                : 500 
                        }
                    })
                })
        }
    } else {
        return dispatch => {
            axios.post(`${BACKEND_URL}/${AD}`, payload, {headers})
                .then(res => {
                    dispatch({
                        type: EDIT_AD,
                        payload: { status: 200 }
                    })
                    if ( res.status === 200 ) {
                        post("https://payment.paytrail.com/e2", res.data)
                    }
                }).catch(err => {
                    dispatch({
                        type: EDIT_AD,
                        payload: {status: 
                            err.response
                                ? err.response.status
                                : 500 
                        }
                    })
                })
        }
    }
}

export const transferCredits = (token, source, destination, amount) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    const payload = {source, destination, amount}
    return dispatch => {
        axios.post(`${BACKEND_URL}/transfer`, payload, {headers})
            .then(res => {
                dispatch({
                    type: TRANSFER_CREDITS,
                    payload: { status: 200 }
                })
            }).catch(err => {
                dispatch({
                    type: TRANSFER_CREDITS,
                    payload: {status: 
                        err.response
                            ? err.response.status
                            : 500 
                    }
                })
            })
    }
}

export const deleteAd = (token, adId) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        axios.delete(`${BACKEND_URL}/${AD}/${adId}`, {headers})
            .then(res => {
//                const ad = res.data
                dispatch({
                    type: EDIT_AD,
                    payload: { status: 200 }
                })
            }).catch(err => {
                console.error(err)
            })
    }
}

export const getResponses = (token, adId) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        axios.get(`${BACKEND_URL}/${AD}/${adId}/response`, {headers})
            .then(res => {
                const responses = res.data 
                dispatch({
                    type: GET_RESPONSES,
                    payload: responses
                })
            }).catch(err => {
                console.error(err)
            })
    }
}

export const beginConfirmResponse = adId => ({
    type: BEGIN_CONFIRM_RESPONSE,
    payload: adId
})

export const clearConfirmResponse = () => ({
    type: CLEAR_CONFIRM_RESPONSE
})

export const confirmMatch = (token, adId) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.post(`${BACKEND_URL}/${AD}/${adId}/confirm`, null, {headers})
            .then(res => {
                dispatch({
                    type: CONFIRM_MATCH,
                    payload: { status: 200 }
                })
                if ( res.status === 200 ) {
                    post("https://payment.paytrail.com/e2", res.data)
                }
            }).catch(err => {
                dispatch({
                    type: CONFIRM_MATCH,
                    payload: {status: 
                        err.response
                            ? err.response.status
                            : 500 
                    }
                })
            })
    }

}

export const confirmResponse = (token, adId, responseId, teamContext) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    const payload = {teamContext}

    return dispatch => {
        if ( responseId != null ) {
            axios.patch(`${BACKEND_URL}/response/${responseId}`, payload, {headers})
                .then(res => {
                    dispatch({
                        type: CONFIRM_RESPONSE,
                        payload: { status: 200 }
                    })
                }).catch(err => {
                    console.error(err)
                })
        } else {
            axios.delete(`${BACKEND_URL}/${AD}/${adId}/response`, {headers})
                .then(res => {
                    dispatch({
                        type: CONFIRM_RESPONSE,
                        payload: { status: 200 }
                    })
                }).catch(err => {
                    console.error(err)
                })
        }
    }    
}

export const getNotifications = (token, teamId) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/team/${teamId}/notifications`, {headers})
            .then(res => {
                dispatch({
                    type: GET_NOTIFICATIONS,
                    payload: res.data
                })
            }).catch(err => {
                console.error(err)
            })
    }
}
