import React from 'react';
import { connect } from "react-redux";

import {
    Typography
} from '@material-ui/core';

class GuideFlow extends React.Component {

    render() {

        const { 
            imgDiameter, 
            imgBorder,
            edgeHeight,
            edgeBorder,
            steps,
            wide
        } = this.props

        const imgStyle = {
            width: imgDiameter,
            height: imgDiameter,
            border: imgBorder,
            borderRadius: '50%',
            backgroundColor: '#efefef',
            backgroundSize: 'cover',
            boxShadow: '3px 3px 8px 3px rgba(0,0,0,.3)'
        }

        const edgeStyle = {
            height: edgeHeight,
            borderRight: edgeBorder,
            width: '50%',
            alignSelf: 'flex-start'
        }

        const textAreaStyle = {
            display: 'flex',
            alignItems: 'center',
            height: imgDiameter,
            marginLeft: 15,
            maxWidth: 360
        }

        return (
            <div style={{ display: 'flex', flexFlow: 'row' }}>
                <div style= {{
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {steps.map((step, i) => {
                        const _imgStyle = {...imgStyle, backgroundImage: `url(${step.image})`}
                        return <React.Fragment>
                            { !this.props.wide && !!i && <div style={{...edgeStyle, height: edgeHeight * 2}}></div>}
                            <div style={_imgStyle}>
                                <div style={{
                                        width: 20,
                                        height: 20,
                                        color: '#fff',
                                        backgroundColor: 'black',
                                        display: 'flex',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        justifyContent: 'center',
                                        borderRadius: '50%'
                                }}>
                                    <Typography style={{fontSize: '0.8em'}}>{i+1}</Typography>
                                </div>
                            </div>
                            {( !this.props.wide || i < steps.length - 1 ) && <div style={edgeStyle}></div>}
                            { !this.props.wide &&
                                <div style={{
                                    margin: '0 20px',
                                    textAlign: 'center'
                                }}>
                                    <Typography style={{fontSize: '0.8em'}}>{ step.text }</Typography>
                                </div>
                            }
                        </React.Fragment>
                    })}
                </div>
                <div>
                    {wide && steps.map((step, i) => {
                        return <React.Fragment>
                            <div style={ textAreaStyle }>
                                <Typography style={{fontSize: '0.8em'}}>{ step.text }</Typography>
                            </div>
                            { i < steps.length - 1 && <div style={{ height: edgeHeight }}></div>}
                        </React.Fragment>
                    })}
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GuideFlow)