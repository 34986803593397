export const divisionToString = n => {
    switch ( n ) {
        case 0:
            return "Pääsarja"
        case 999:
            return "Harraste"
        default:
            return `${n}. Div.`
    }
}

export const expenseModelToString = n => {
    switch ( n ) {
        case 0:
            return "Ilmoittaja maksaa vuoron, haastaja muut kulut"
        case 25:
            return "Ilmoittaja maksaa kaikki kulut"
        case 50:
            return "Kaikki kulut 50/50"
        case 75:
            return "Haastaja maksaa kaikki kulut"
        case 100:
            return "Haastaja maksaa vuoron, ilmoittaja muut kulut"
        default:
            return `Muu`
    }

    /*
    switch ( n ) {
        case 0:
            return "Vastustaja maksaa"
        case 100:
            return "Ilmoittaja maksaa"
        default:
            return `${n} / ${100 - n}`
    }
    */
}

export const durationToString = n => n < 60 
    ? `${n} minuutti${n === 1 ? '' : 'a'}` 
    : `${n/60} tunti${(n/60) === 1 ? '' : 'a'}`

export const turnToString = n => [
        "Ilmoittajan vuoro",
        "Jaettu vuoro",
        "Vastustajan vuoro"
    ][n]

export const teamTypeToString = n => [
        "Sekajoukkue",
        "Miehet",
        "Naiset"
    ][n]

export const profileTypeToString = n => [
        "Joukkue- tai yksilölaji",
        "Tuomari"
    ][n]

export const getNumberOfDivisions = (sports, sportId, type) => {
    const sport = sports.find(sport => sport.id === sportId)
    if ( !sport ) return 0
    if ( type < 0 ) {
        return Math.max(
            sport['divisionsMixed'], 
            sport['divisionsMen'], 
            sport['divisionsWomen']
        )
    } else {
        return sport[
            type === 0 
                ? 'divisionsMixed'
                : ( type === 1 
                    ? 'divisionsMen'
                    : 'divisionsWomen'
                )
        ]
    }
}

export const getEnvironmentsForSports = (sports, environments) => 
    sports.length 
        ? environments.filter(
            environment => environment.sports.find(
                sport => sports.includes(sport.id)
            )
        ) 
        : environments