import React, { Component } from 'react';
import { connect } from "react-redux";
import TopBar from '../components/TopBar'
//import { BACKEND_URL } from '../const'
//import axios from 'axios'
import NewAdButton from '../components/NewAdButton'

import {
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    withWidth,
    Typography
} from '@material-ui/core';

import {
    CheckBox
} from '@material-ui/icons';

class PricingView extends Component {

    componentWillMount() {
  //      this.fetch()
    }
/*
    fetch() {
        axios.get(`${BACKEND_URL}/current_pricing`)
            .then(res => {
                console.log(res.data)
            }).catch(err => {
                console.error(err)
            })
    }
*/
    render(){

        const minTicks = 6

        const offers = [{
            "title": "Joukkuelajit",
            "price": '5',
            "item": 'Ilmoitus tai haaste',
            "color": 'black',
            "image": 'images/joukkue.jpg',
            "textColor": "white",
            "tick": this.props.sports.filter(sport => !sport.individualSport)
                .map(sport => sport.name)
        },{
            "title": "Yksilölajit",
            "price": '2',
            "item": 'Ilmoitus tai haaste',
            "color": 'black',
            "image": 'images/yksilo.jpg',
            "textColor": "white",
            "tick": this.props.sports.filter(sport => sport.individualSport)
                .map(sport => sport.name)
        },{
            "title": "Tuomarit",
            "price": '3,5',
            "item": 'Vastaus tuomarihakuun',
            "color": 'black',
            "image": 'images/tuomari.jpg',
            "textColor": "white",
            "tick": []
        }]
    
        return (
            <div className="BrowseView PricingView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: this.props.desktop ? 67 : 63,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom,
                    alignItems: 'center'
                }}>
                <TopBar
                    history={this.props.history}
                    title={"Hinnat"}
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />

                <div className="offer-wrapper">{
                    offers.map(offer =>
                        <Card style={{ height: '100%', width: 240, maxWidth: '100%', marginTop: 20, marginLeft: 10, marginRight: 10 }}>
                            <CardActionArea onClick={() => {}}>
                                <CardMedia 
                                    image={offer.image}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: offer.color, height: 80}}>
                                    <Typography className="text-outline" style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,.2)', fontSize: 34, color: offer.textColor }}>{ offer.title }</Typography>
                                </CardMedia>
                                <CardContent>
                                    <div style={{ textAlign: 'center', marginTop: 5, marginBottom: offer.tick.length ? 20 : 10 }}>
                                        <div style={{ fontSize: 30 }}>{ offer.price }€</div>
                                        <div style={{ color: '#888' }}>{ offer.item}</div>
                                    </div>
                                    { !!offer.tick.length &&
                                        <div style={{ marginTop: 10 }}>
                                        { offer.tick.map(tick =>
                                            <div style={{display:'flex', marginBottom: 6, alignItems: 'center'}}>
                                                <CheckBox style={{ color: 'rgb(90, 158, 50)' }}/>
                                                <span style={{ marginRight: 5 }}></span>
                                                { tick }
                                            </div>
                                        )}
                                        { minTicks > offer.tick.length &&
                                            Array(minTicks- offer.tick.length).fill(0).map(() => 
                                                <div style={{display:'flex', marginBottom: 6, alignItems: 'center', visibility: 'hidden'}}>
                                                    <CheckBox style={{ color: 'rgb(90, 158, 50)' }}/>
                                                    <span style={{ marginRight: 5 }}></span>-
                                                </div>
                                            )
                                        }
                                    </div>
                                    }
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )
                }</div>

                <NewAdButton promptLogin={this.props.promptLogin} 
                    desktop={this.props.desktop} 
                    padding={this.props.padding}
					history={this.props.history} 
					margins={this.props.margins} />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    sports: state.static.sports
})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(PricingView)
)