import React, { Component } from 'react';
import { connect } from "react-redux";
import TopBar from '../components/TopBar'
import NewAdButton from '../components/NewAdButton'
import GuideFlow from '../components/GuideFlow'

import {
    withWidth,
    Tabs,
    Tab,
    Paper,
    Typography
} from '@material-ui/core';

class GuideView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
            wide: true
        }
        this.ref = React.createRef()
    }

    componentWillMount() {
        setInterval(() => {
            if ( this.ref.current ) {
                const width = this.ref.current.getBoundingClientRect().width
                const threshold = 460
                if ( !this.state.wide && width >= threshold ) {
                    this.setState({wide: true})
                } else if ( this.state.wide && width < threshold ) {
                    this.setState({wide: false})
                }
            }
        }, 1000)
    }

    render(){
        const steps = [[
            {
                image: '/images/a0.png',
                text: `Aloita ilmoituksen jättäminen painamalla "Jätä ilmoitus"-painiketta ja täytä pyydetyt tiedot. Paina lopuksi "Tallenna". Mikäli tilillä on krediittejä, ilmoitus maksetaan automaattisesti niillä. Muussa tapauksessa sinut ohjataan Paytrail-maksupalveluun suorittamaan maksu ilmoituksen jättämisestä.`,
            },
            {
                image: '/images/a1.png',
                text: 'Ilmoituksen onnistuneen jättämisen jälkeen sinut ohjataan "Omat ilmoitukset"-sivulle, jossa näet kaikki ilmoituksesi. Ilmoituksen alaosassa on painike, josta voit tarkistaa saamasi vastaukset.'
            },
            {
                image: '/images/a2.png',
                text: `Avautuvassa listassa on tietoja ilmoitukseesi vastanneista joukkueista. Valitse listasta joukkue vastustajaksesi.`,
            },
            {
                image: '/images/a3.png',
                text: 'Valintasi jälkeen vastustajalta odotetetaan vielä vahvistusta. Kun vastustaja on vahvistanut ottelun, ilmoitus siirretään "Omat ottelut"-sivulle.'
            },
            {
                image: '/images/a4.png',
                text: 'Löydät vastustajasi tarkemmat tiedot sekä yhteystiedot ilmoituksen "Näytä vastustajan tiedot"-painikkeen alta.'
            }            
        ], [
            {
                image: '/images/b0.png',
                text: `Käytä hakutoimintoa kiinnostavien ilmoitusten etsimiseen.`,
            },
            {
                image: '/images/b1.png',
                text: 'Sopivan ilmoituksen löydettyäsi, vastaa ilmoitukseen painamalla ilmoituksen alaosassa olevaa "Vastaa ilmoitukseen"-painiketta. Ilmoituksen jättänyttä tahoa tiedotetaan vastauksestasi ja ilmoittaja valitsee vastustajan kaikkien vastanneiden joukosta. Ilmoitukset, joihin olet vastannut, ovat löydettävissä "Omat vastaukset"-sivulta.'
            },
            {
                image: '/images/b2.png',
                text: `Jos ilmoituksen jättäjä valitsi vastustajakseen sinut, saat siitä ilmoituksen ja näet ilmoituksen alaosassa "Vahvista ottelu"-painikkeen. Sen painaminen on viimeinen vaihe ottelun sopimisessa. Vahvistuksen yhteydessä sinut ohjataan Paytrail-maksupalveluun, tai jos joukkueen tilillä on krediittejä, niin ottelun sopiminen maksetaan niillä.`,
            },
            {
                image: '/images/b3.png',
                text: 'Ottelun onnistuneen sopimisen jälkeen ilmoitus on nähtävissä "Omat ottelut"-sivulla. Löydät vastustajasi tarkemmat tiedot sekä yhteystiedot ilmoituksen "Näytä vastustajan tiedot"-painikkeen alta.'
            }
        ]]

        const footer = [
            'Saat jokaisesta yllä olevasta välivaiheesta myös ilmoituksen sähköpostiisi. Kun olet jättänyt ilmoituksen voit siis ottaa rennosti, ja odottaa ilmoituksia.',
            'Saat jokaisesta yllä olevasta välivaiheesta myös ilmoituksen sähköpostiisi. Kun olet vastannut ilmoitukseen voit siis ottaa rennosti, ja odottaa ilmoituksia.'
        ]

        return (
            <div className="BrowseView GuideView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: this.props.desktop ? 67 : 63,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom,
                    alignItems: 'center'
                }}>
                <TopBar
                    history={this.props.history}
                    title={"Ohjeet"}
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />
                <Paper style={{ width: '100%' }}>
                    <Tabs
                        value={this.state.tab}
                        onChange={(event, newValue) => {
                            this.setState({tab: newValue})
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                        >
                        <Tab label="Ilmoituksen jättäminen" />
                        <Tab label="Ilmoitukseen vastaaminen" />                        
                        {/*<Tab label="UKK" />*/}
                    </Tabs>
                </Paper>

                <div className="guide-wrapper" ref={this.ref} style={{ 
                    margin: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    flexFlow: 'row',
                    width: '100%'
                }}>
                    {
                        this.state.tab <= 1 &&
                        <GuideFlow steps={steps[this.state.tab]}
                            wide={this.state.wide}
                            imgDiameter={200}
                            imgBorder={'1px solid #444'}
                            edgeHeight={20}
                            edgeBorder={'2px solid #444'}        
                        />
                    }
                </div>
                {
                    this.state.tab <= 1 &&
                    <Typography style={{ maxWidth: 580, textAlign: 'center', margin: 40, marginTop: 0 }}>
                        { footer[this.state.tab] }
                    </Typography>
                }
                <NewAdButton promptLogin={this.props.promptLogin} 
                    desktop={this.props.desktop} 
                    padding={this.props.padding}
					history={this.props.history} 
					margins={this.props.margins} />
            </div>
        )
    }

}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(GuideView)
)