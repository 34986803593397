import React from 'react';
import { connect } from "react-redux";

import {
  withMobileDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  InputAdornment
} from '@material-ui/core';

import {
  AlternateEmail,
  Lock
} from '@material-ui/icons';

import {
    REGISTER_SUCCESS,
    REGISTER_FAILURE_409,
    REGISTER_FAILURE_500,
} from '../const'

import {
    beginRegister,
    register,
    clearRegister
} from '../redux/login/actions'

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class RegisterDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            registerEmail: '',
            registerPassword: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.login.registerState !== nextProps.login.registerState 
          && nextProps.login.registerState === REGISTER_SUCCESS ) {
            this.props.clearRegister()
        }
    }

    render() {
        return (
            <MobileDialog
            open={this.props.login.registerState !== null}
            onClose={e => {this.props.clearRegister()}}
          >
            <DialogTitle>Rekisteröidy</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Syötä sähköpostiosoitteesi ja valitse salasana. Lähetämme sähköpostiisi vahvistuslinkin. jonka avulla voit kirjautua ensimmäisen kerran.
              </DialogContentText>
              <TextField
                autoFocus
                type="text"
                label="Sähköpostiosoite"
                value={ this.state.registerEmail }
                onKeyUp={e => {
                  if ( e.keyCode === 13 ) {
                    this.props.register(this.state.registerEmail, this.state.registerPassword)
                  }
                }}
                onChange={e => {
                  if ( this.props.login.registerState === REGISTER_FAILURE_409 ||
                    this.props.login.registerState === REGISTER_FAILURE_500 ) {
                      this.props.beginRegister()
                    }
                  this.setState({registerEmail: e.target.value})
                }}
                InputProps={{
                  style: this.props.login.registerState === REGISTER_FAILURE_409 ||
                    this.props.login.registerState === REGISTER_FAILURE_500
                      ? { color: this.FAILURE_COLOR } 
                      : {},  
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmail />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type="password"
                label="Salasana"
                style={{ marginTop: 10 }}
                value={ this.state.registerPassword }
                onKeyUp={e => {
                  if ( e.keyCode === 13 ) {
                    this.props.register(this.state.registerEmail, this.state.registerPassword)
                  }
                }}
                onChange={e => {
                  if ( this.props.login.registerState === REGISTER_FAILURE_409 ||
                    this.props.login.registerdState === REGISTER_FAILURE_500 ) {
                      this.props.beginRegister()
                    }
                  this.setState({registerPassword: e.target.value})
                }}
                InputProps={{
                  style: this.props.login.registerState === REGISTER_FAILURE_409 ||
                    this.props.login.registerState === REGISTER_FAILURE_500
                      ? { color: this.FAILURE_COLOR } 
                      : {},  
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
              />
              <DialogContentText style={{ color: 'red', fontSize: '.8em' }} id="alert-dialog-description">
                { this.props.login.registerState === REGISTER_FAILURE_409 
                  ? 'Sähköpostiosoitteella on jo rekisteröity käyttäjätili.'
                  : (
                    this.props.login.registerState === REGISTER_FAILURE_500
                    ? 'Palvelinvirhe. Yritä myöhemmin uudestaan.'
                    : ''
                  )
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button 
                disabled={ !this.state.registerEmail.length || !this.state.registerPassword.length }
                onClick={e => {this.props.register(this.state.registerEmail, this.state.registerPassword)}} color="primary" variant="contained">
                Rekisteröidy
              </Button>
              <Button onClick={e => {this.props.clearRegister()}} color="primary">
                Peruuta
              </Button>
            </DialogActions>
          </MobileDialog>
        )
    }

}

const mapStateToProps = state => {
    return { 
      login: state.login
    };
  };
  
const mapDispatchToProps = dispatch => ({
    beginRegister: () => {
        dispatch(beginRegister())
      },
      register: (email, password) => {
        dispatch(register(email, password))
      },
      clearRegister: () => {
        dispatch(clearRegister())
      },
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterDialog)