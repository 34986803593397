import React, { Component } from 'react';
import { connect } from "react-redux";
import TopBar from '../components/TopBar'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { 
    FRONTEND_URL,
    BACKEND_URL,
    ARTICLES_PATH
} from '../const'
import axios from 'axios';

import {
    withWidth,
    Typography,
    Paper,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton,
    FormControl,
    InputLabel,
    TextField,
    Snackbar
} from '@material-ui/core';

import {
    Save
} from '@material-ui/icons';

class ArticleEditorView extends Component {

    constructor(props){
        super(props)
        this.state = {
            editorState: EditorState.createEmpty(),
            draft: true,
            title: '',
            description: '',
            source: null,
            snackOpen: false,
            snackTImeout: null
        }
    }

    defaultState = {
        editorState: EditorState.createEmpty(),
        draft: true,
        title: '',
        description: '',
        source: null
    }

    originalMetadata = null

    onEditorStateChange = editorState => {
        this.setState({editorState})
    }

    componentWillMount() {
        this.fetch(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.source !== nextProps.source ) {
            this.fetch(nextProps)
        }
    }

    redirectIfNotThere(path) {
        if ( this.props.history.location.pathname !== path ) {
          this.props.history.push(path)
        }
    }    

    fetch(props) {
        if ( props.source ) {
            const headers = props.login.token 
                ? {Authorization: props.login.token} 
                : null
            axios.get(`${BACKEND_URL}/article/${props.source}/_metadata`, {headers})
                .then(res => {
                    const metadata = res.data
                    return axios.get(`${BACKEND_URL}/article/${props.source}/_json`, {headers})
                        .then(res => {
                            const contentState = res.data
                            window.scrollTo(0, 0)
                            this.originalMetadata = metadata
                            this.setState({
                                title: metadata.title,
                                description: metadata.description,
                                draft: metadata.draft,
                                source: metadata.source,
                                image: metadata.image
                            })
                            const editorState = EditorState.createWithContent(convertFromRaw(contentState))
                            this.setState({editorState})
                        })
                }).catch(err => {
                    console.error(err)
                })
        } else {
            this.setState({
                ...this.defaultState
            })
        }
    }

    render(){
        const { editorState } = this.state
        const url = FRONTEND_URL + ARTICLES_PATH + '/' + this.state.source
        return (
            <div className="BrowseView ArticleEditorView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: 0,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom
                }}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    key={`top,right`}
                    open={this.state.snackOpen}
                    message="Artikkeli tallennettiin."
                />
                <TopBar tools={
                        <Tooltip title="Tallenna" aria-label="Tallenna">
                            <IconButton onClick={e => {
                                const payload = {
                                    title: this.state.title,
                                    description: this.state.description,
                                    image: this.state.image,
                                    draft: this.state.draft,
                                    html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                                    json: JSON.stringify(convertToRaw(editorState.getCurrentContent()), null, 4)
                                }                                
                                const path = `${BACKEND_URL}/article${this.state.source ? `/${this.state.source}` : ''}` 
                                const headers = this.props.login.token 
                                    ? {Authorization: this.props.login.token} 
                                    : null
                                axios.post(path, payload, {headers})
                                    .then(res => {
                                        this.setState({
                                            source: res.data.id
                                        })
                                        if ( this.state.snackTimeout != null ) {
                                            clearTimeout(this.state.snackTimeout)
                                        }
                                        this.setState({
                                            snackOpen: true,
                                            snackTimeout: setTimeout(() => {
                                                this.setState({snackOpen: false})
                                            }, 2000)
                                        })                      
                                    }).catch(err => {
                                        console.error(err)
                                    })                        
                            }}>
                                <Save />
                            </IconButton> 
                        </Tooltip>
                    }
                    history={this.props.history}
                    title="Artikkelieditori"
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />
                <Paper style={{ marginTop: 10, padding: 10, marginBottom: 10, maxWidth: 520 }}>
                    <Typography style={{ marginBottom: 16, fontSize: '0.8em'}}>
                        Osoite: <b>{
                            this.state.source 
                                ? <a href={url} onClick={e => {
                                    this.redirectIfNotThere(ARTICLES_PATH + '/' + this.state.source)
                                    e.preventDefault()
                                }}>{url}</a> 
                                : '-'
                        }</b>
                    </Typography>
                    <FormControl>
                        <InputLabel shrink>
                            Otsikko
                        </InputLabel>
                        <TextField 
                            margin="dense"
                            fullWidth
                            style={{ 
                                marginTop: 17, 
                                marginBottom: 20,
                                background: 'transparent' }}
                            onChange={ e => {
                                this.setState({title: e.target.value})
                            }}
                            value={ this.state.title }
                        />
                    </FormControl>
                    <FormControl style={{ marginBottom: 16 }}>
                        <InputLabel shrink>
                            Kuvaus
                        </InputLabel>
                        <TextField 
                            margin="dense"
                            fullWidth
                            variant="filled"
                            hiddenLabel
                            multiline 
                            style={{ background: 'transparent' }}
                            onChange={ e => {
                                this.setState({description: e.target.value})
                            }}
                            value={ this.state.description }
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel shrink>
                            Kuva
                        </InputLabel>
                        <TextField 
                            margin="dense"
                            fullWidth
                            type="file"
                            style={{ 
                                marginTop: 17, 
                                background: 'transparent' 
                            }}
                            onChange={ e => {
                                if ( this.state.source === null ) {
                                    alert('Tallenna artikkeli ennen kuvan lisäämistä.')
                                    return
                                }
                                const formData = new FormData()
                                formData.append('image', e.target.files[0])
                                axios({
                                    method: 'post',
                                    url: `${BACKEND_URL}/article/${this.state.source}/_image`,
                                    data: formData,
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                        'Authorization': this.props.login.token
                                    }
                                }).then(res => {
                                    console.log(res.data)
                                    this.setState({image: res.data.filename})
                                }).catch(err => {
                                    console.error(err)
                                })
                            }}
                        />
                    </FormControl>
                    {
                        this.state.source && this.state.image &&
                        <div style={{
                            width: '100%',
                            maxWidth: 480,
                            height: 80,
                            marginBottom: 8,
                            backgroundColor: '#e8e8e8',
                            backgroundImage: `url(${BACKEND_URL}/article/${this.state.source}/${this.state.image})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}>

                        </div>
                    }
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={this.state.draft}
                            onChange={e => {
                                this.setState({draft: !this.state.draft})
                            }}
                            color="primary"
                        />
                        }
                        label="Luonnos"
                    />
                </Paper>
                <Paper style={{ padding: 10, marginBottom: 10 }}>
                    <Typography>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onEditorStateChange}
                            toolbar={{
                                image: { uploadCallback: file => {
                                    return new Promise(
                                        (resolve, reject) => {
                                            if ( this.state.source === null ) {
                                                alert('Tallenna artikkeli ennen kuvan lisäämistä.')
                                                reject()
                                            } else {
                                                const formData = new FormData()
                                                formData.append('image', file)
                                                axios({
                                                    method: 'post',
                                                    url: `${BACKEND_URL}/article/${this.state.source}/_asset`,
                                                    data: formData,
                                                    headers: {
                                                        'Content-Type': 'multipart/form-data',
                                                        'Authorization': this.props.login.token
                                                    }
                                                }).then(res => {
                                                    console.log(res.data)
                                                    resolve({data:{link:res.data.url}})
                                                }).catch(reject)
                                            }
                                        }
                                      );                              
                                }, alt: { present: true, mandatory: false } }
                            }}
                        />
                        </Typography>
                </Paper>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login
})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(ArticleEditorView)
)