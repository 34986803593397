export const VERSION = 0.20
export const UNDEFINED_VERSION = 0

export const NOT_LOGGED_IN = 0
export const LOGGED_IN = 1
export const NOT_LOGGED_IN_FAILURE = 2

export const RESET_PASSWORD_INIT = 0
export const RESET_PASSWORD_SUCCESS = 1
export const RESET_PASSWORD_FAILURE_404 = 2
export const RESET_PASSWORD_FAILURE_500 = 3

export const REGISTER_INIT = 0
export const REGISTER_SUCCESS = 1
export const REGISTER_FAILURE_409 = 2
export const REGISTER_FAILURE_500 = 3

export const DO_REGISTER_SUCCESS = 0
export const DO_REGISTER_FAILURE = 1

export const EDIT_TEAM_INIT = 0
export const EDIT_TEAM_SUCCESS = 1
export const EDIT_TEAM_FAILURE_409 = 2
export const EDIT_TEAM_FAILURE_500 = 3

export const EDIT_AD_INIT = 0
export const EDIT_AD_SUCCESS = 1
export const EDIT_AD_FAILURE = 2

export const TRANSFER_CREDITS_INIT = 0
export const TRANSFER_CREDITS_SUCCESS = 1
export const TRANSFER_CREDITS_FAILURE_400 = 2
export const TRANSFER_CREDITS_FAILURE_500 = 3

export const CONFIRM_RESPONSE_INIT = 0
export const CONFIRM_RESPONSE_SUCCESS = 1
export const CONFIRM_RESPONSE_FAILURE = 2

export const SEARCH_RESULT = 0
export const SEARCH_OWN_RESPONSES_RESULT = 1
export const SEARCH_OWN_ADS_RESULT = 2
export const SEARCH_AD_BY_ID_RESULT = 3
export const SEARCH_OWN_MATCHES_RESULT = 4

export const NOTIFICATION_AD = 0
export const NOTIFICATION_RESPONSE = 1
export const NOTIFICATION_MATCH = 2

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
export const FACEBOOK_PAGE_ID = process.env.REACT_APP_FACEBOOK_PAGE_ID
export const FACEBOOK_CHAT_GREETING = process.env.REACT_APP_FACEBOOK_CHAT_GREETING

export const AD = 'alt_ad'

export const SORT_ORDER_ASCENDING = 0
export const SORT_ORDER_DESCENDING = 1

export const OWN_MATCHES_FILTER_ALL = 0
export const OWN_MATCHES_FILTER_PAST = 1
export const OWN_MATCHES_FILTER_UPCOMING = 2

export const UNDEFINED_VIEW = -1
export const TEAM_VIEW = 0

export const OWN_ADS_PATH = '/ads'
export const OWN_RESPONSES_PATH = '/responses'
export const OWN_MATCHES_PATH = '/matches'
export const REPORT_PATH = '/report/:entity'
export const PRIVACY_POLICY_PATH = '/privacy'
export const COOKIE_CONSENT_PATH = '/cookies'
export const TEAM_PATH = '/team/:teamId'
export const LOGIN_TOKEN_PATH = '/login/:token'
export const COLOR_CTRL_PATH = '/color/:btnBg/:btnCt'
export const RESET_PASSWORD_TOKEN_PATH = '/reset-password/:token'
export const AD_PATH = '/ad/:adId'
export const ARTICLES_PATH = '/articles'
export const ARTICLES_MD_PATH = '/articles/:md'
export const ARTICLES_NEW_PATH = '/articles/new'
export const ARTICLES_EDIT_PATH = '/articles/edit/:id'
export const PRICING_PATH = '/pricing'
export const GUIDE_PATH = '/guide'

export const USER_TYPE_USER = 0
export const USER_TYPE_ADMIN = 1

export const PROFILE_TYPE_NORMAL = 0
export const PROFILE_TYPE_REFEREE = 1