import React from 'react';
import { connect } from "react-redux";

import {
    withMobileDialog,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    InputAdornment,
    Button
} from '@material-ui/core';

import { Lock } from '@material-ui/icons';

import {
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE_500
} from '../const'  

import { 
    doResetPassword,
} from '../redux/login/actions'  

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class DoResetPasswordDialog extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            openResetPasswordDialog: props.match.path === '/reset-password/:token' 
                && props.match.params.token !== undefined,
            resetPassword: ""  
        }
    }

    componentWillReceiveProps(nextProps) {
        if ( this.state.openResetPasswordDialog 
          && this.props.login.doResetPasswordState !== nextProps.login.doResetPasswordState ) {
            if ( nextProps.login.doResetPasswordState === RESET_PASSWORD_SUCCESS ) {
              this.setState({openResetPasswordDialog: false})
              this.props.history.push('/')
            }
          }
      }    

      doResetPassword() {
        this.props.doResetPassword(
          this.state.resetPassword, 
          this.props.match.params.token
        )
      }
    
    render() {
        return (
            <MobileDialog
                open={this.state.openResetPasswordDialog}
                onClose={e => {this.setState({openResetPasswordDialog: false})}}
            >
            <DialogTitle id="alert-dialog-title">Vaihda salasana</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    id="input-password"
                    type="password"
                    label="Uusi salasana"
                    onKeyUp={e => {
                    if ( e.keyCode === 13 ) {
                        this.doResetPassword()
                    }
                    }}
                    onChange={e => {
                        this.setState({resetPassword: e.target.value})
                    }}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <Lock />
                        </InputAdornment>
                    ),
                    }}
                />
                <DialogContentText style={{ color: 'red', fontSize: '.8em' }}>
                    { this.props.login.doResetPasswordState === RESET_PASSWORD_FAILURE_500
                        ? 'Palvelinvirhe. Yritä myöhemmin uudestaan.'
                        : ''
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button 
                    disabled={ !this.state.resetPassword.length }
                    onClick={e => {this.doResetPassword()}} variant="contained" color="primary">
                    Vaihda
                </Button>
                <Button onClick={e => {
                    this.props.history.push('/')                  
                    this.setState({openResetPasswordDialog: false})
                    }} color="primary">
                    Peruuta
                </Button>
            </DialogActions>
        </MobileDialog>
    )
}
}

const mapStateToProps = state => {
    return { 
      login: state.login
    };
  };
  
const mapDispatchToProps = dispatch => ({
    doResetPassword: (password, token) => {
        dispatch(doResetPassword(password, token))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(DoResetPasswordDialog)