import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';

import Ad from '../components/Ad'
import TopBar from '../components/TopBar'
import ConfirmResponseDialog from '../components/ConfirmResponseDialog';
import NewAdButton from '../components/NewAdButton'

import {
	withWidth,
	Typography,
	Grid,
} from '@material-ui/core';

import {
	teamTypeToString,
	divisionToString,
	expenseModelToString,
	turnToString
} from '../utils';

import { 
	search, 
	redoSearch, 
	searchOwnAds,
	searchOwnResponses,
	searchOwnMatches,
	searchToPatch,
	searchAdById,
	removeFromResults
} from '../redux/search/actions';

import {
	clearEditAd,
	beginConfirmResponse,
	clearConfirmResponse,
	beginEditTeam
} from '../redux/account/actions'

import {
	EDIT_AD_SUCCESS,
	CONFIRM_RESPONSE_SUCCESS,
	NOTIFICATION_AD,
    NOTIFICATION_RESPONSE,
    NOTIFICATION_MATCH,
	LOGGED_IN,
	SEARCH_RESULT,
	SEARCH_OWN_RESPONSES_RESULT,
	SEARCH_OWN_ADS_RESULT,
	SEARCH_OWN_MATCHES_RESULT,
	SEARCH_AD_BY_ID_RESULT,
	OWN_MATCHES_FILTER_ALL,
	OWN_MATCHES_FILTER_PAST,
	OWN_MATCHES_FILTER_UPCOMING,
	OWN_ADS_PATH,
	OWN_MATCHES_PATH,
	OWN_RESPONSES_PATH
} from '../const'

class BrowseView extends Component {

	componentWillMount() {
		if ( !this.props.gotStatic ) return
		this.search(this.props, true)
	}

	componentWillReceiveProps(nextProps) {
		if ( !nextProps.gotStatic ) return
		if ( this.props.match.path !== nextProps.match.path ) {
			if ( nextProps.match.path === OWN_ADS_PATH ) {
				this.props.searchOwnAds(
					nextProps.sortOrder,
					nextProps.account.selectedTeam,
					nextProps.login.token
				)
			} else if ( nextProps.match.path === OWN_RESPONSES_PATH ) {
				this.props.searchOwnResponses(
					nextProps.sortOrder,
					nextProps.account.selectedTeam,
					nextProps.login.token
				)
			} else if ( nextProps.match.path === OWN_MATCHES_PATH ) {
				this.props.searchOwnMatches(
					nextProps.sortOrder,
					nextProps.account.selectedTeam,
					nextProps.login.token
				)
			} else if ( nextProps.match.path === '/' ) {
				this.search(nextProps, false)
			}
		}
		if ( this.props.match.params.adId !== nextProps.match.params.adId 
			&& nextProps.match.params.adId != null ) {
				this.search(nextProps, true)
		}
		if ( this.props.gotStatic !== nextProps.gotStatic ) {
			this.search(nextProps, true)
		}
		if ( this.props.selectedSports !== nextProps.selectedSports ||
			this.props.selectedInnerRegion !== nextProps.selectedInnerRegion ||
			this.props.selectedOuterRegion !== nextProps.selectedOuterRegion ||
			this.props.selectedStartDate !== nextProps.selectedStartDate ||
			this.props.selectedEndDate !== nextProps.selectedEndDate ||
			this.props.selectedStartTime !== nextProps.selectedStartTime ||
			this.props.selectedEndTime !== nextProps.selectedEndTime ||
			this.props.selectedType !== nextProps.selectedType ||
			this.props.selectedDivision !== nextProps.selectedDivision ||
			this.props.selectedMyType !== nextProps.selectedMyType ||
			this.props.selectedMyDivision !== nextProps.selectedMyDivision ||
			this.props.selectedTurn !== nextProps.selectedTurn ||
			this.props.selectedExpenseModel !== nextProps.selectedExpenseModel ||
			this.props.selectedLocations !== nextProps.selectedLocations ||
			this.props.selectedDetail0 !== nextProps.selectedDetail0 ||
			this.props.selectedDetail1 !== nextProps.selectedDetail1 || 
			this.props.selectedEnvironment !== nextProps.selectedEnvironment ) {
				this.redirectIfNotThere('/')
				this.search(nextProps, false)
				window.scrollTo(0, 0)
		}
		if ( this.props.sortOrder !== nextProps.sortOrder ||
			this.props.filter !== nextProps.filter ) {
			this.props.redoSearch(
				nextProps.searchParams,
				nextProps.account.selectedTeam,
				nextProps.login.token
			)
		}
		if ( this.props.account.editAdState !== nextProps.account.editAdState 
			&& nextProps.account.editAdState === EDIT_AD_SUCCESS ) {
			  if ( this.props.account.editAdId !== null ) {
				// Waits for the new data to be written to the database.
				// Strictly a temporary solution.
					setTimeout(() => {
							this.props.redoSearch(
								nextProps.searchParams,
								nextProps.account.selectedTeam,
								nextProps.login.token
							)
					}, 1000)
				}
		}
		if ( this.props.account.confirmResponseState !== nextProps.account.confirmResponseState 
			&& nextProps.account.confirmResponseState === CONFIRM_RESPONSE_SUCCESS ) {
			  this.props.clearConfirmResponse()
			  this.props.redoSearch(
				nextProps.searchParams,
				nextProps.account.selectedTeam,
				nextProps.login.token
			)
		}
		if ( this.props.account.adNotifications !== nextProps.account.adNotifications 
			&& nextProps.searchResultType === SEARCH_OWN_ADS_RESULT ) {
			this.props.searchToPatch(
				nextProps.account.selectedTeam,
				{ownAds: true}, 
				nextProps.account.adNotifications, 
				nextProps.login.token,
				NOTIFICATION_AD
			)
		}
		if ( this.props.account.responseNotifications !== nextProps.account.responseNotifications 
			&& ( 
				nextProps.searchResultType === SEARCH_RESULT
				|| nextProps.searchResultType === SEARCH_OWN_RESPONSES_RESULT 
			) ) {
			this.props.searchToPatch(
				nextProps.account.selectedTeam,
				{ownResponses: true}, 
				nextProps.account.responseNotifications, 
				nextProps.login.token,
				nextProps.searchResultType === SEARCH_OWN_RESPONSES_RESULT 
					? NOTIFICATION_RESPONSE 
					: null
			)
		}
		if ( this.props.account.matchNotifications !== nextProps.account.matchNotifications ) {
			if ( nextProps.searchResultType === SEARCH_OWN_MATCHES_RESULT ) {
				this.props.searchToPatch(
					nextProps.account.selectedTeam,
					{ownMatches: true}, 
					nextProps.account.matchNotifications, 
					nextProps.login.token,
					NOTIFICATION_MATCH
				)
			} else {
				this.props.removeFromResults(
					nextProps.account.matchNotifications
				)
			}
		}
		if ( this.props.account.teams !== nextProps.account.teams 
			&& !nextProps.account.teams.length 
			&& nextProps.login.state === LOGGED_IN) {
			  this.props.beginEditTeam(null)
		}	  
	}

    redirectIfNotThere(path) {
		if ( this.props.history.location.pathname !== path ) {
			this.props.history.push(path)
		}
	}  

	search(props, checkPath=false) {
		if ( checkPath && props.match.path === '/ad/:adId' ) {
			this.props.searchAdById(
				props.match.params.adId,
				props.account.selectedTeam,
				props.login.token
			)
		} else if ( checkPath && props.match.path === OWN_ADS_PATH ) {
			this.props.searchOwnAds(
				props.sortOrder,
				props.account.selectedTeam,
				props.login.token
			)
		} else if ( checkPath && props.match.path === OWN_RESPONSES_PATH ) {
			this.props.searchOwnResponses(
				props.sortOrder,
				props.account.selectedTeam,
				props.login.token
			)
		} else if ( checkPath && props.match.path === OWN_MATCHES_PATH ) {
			this.props.searchOwnMatches(
				props.sortOrder,
				props.account.selectedTeam,
				props.login.token
			)
		} else {
			this.props.search(
				props.selectedSports,
				props.selectedOuterRegion,
				props.selectedInnerRegion,
				props.selectedStartDate,
				props.selectedEndDate,
				props.selectedStartTime,
				props.selectedEndTime,
				props.selectedType,
				props.selectedDivision,
				props.selectedMyType,
				props.selectedMyDivision,
				props.selectedTurn,
				props.selectedExpenseModel,
				props.selectedLocations,
				props.selectedDetail0,
				props.selectedDetail1,
				props.selectedEnvironment,
				props.sortOrder,
				props.account.selectedTeam,
				props.login.token
			)
		}
	}

	render() {

		const cardStyle = {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "center"
		}

		return (
			<div className="BrowseView" style={{ ...this.props.padding, ...this.props.margins }}>
				<TopBar history={this.props.history} desktop={this.props.desktop} left={ this.props.margins.marginLeft }height={this.props.topBarHeight} />
				{ this.props.searchResultType === SEARCH_RESULT &&
					<Typography style={{ margin: "10px"}}>Hakuehdoilla löytyi <b>{ this.props.ads.length }</b> ilmoitus{ this.props.ads.length !== 1 ? "ta." : "." }</Typography>
				}
				{ this.props.searchResultType === SEARCH_OWN_RESPONSES_RESULT &&
					<Typography style={{ margin: "10px"}}>Löytyi <b>{ this.props.ads.length }</b> oma{ this.props.ads.length !== 1 ? "a" : "" } vastaus{ this.props.ads.length !== 1 ? "ta." : "." }</Typography>
				}
				{ this.props.searchResultType === SEARCH_OWN_ADS_RESULT &&
					<Typography style={{ margin: "10px"}}>Löytyi <b>{ this.props.ads.length }</b> oma{ this.props.ads.length !== 1 ? "a" : "" } ilmoitus{ this.props.ads.length !== 1 ? "ta." : "." }</Typography>
				}
				{ this.props.searchResultType === SEARCH_OWN_MATCHES_RESULT &&
					<Typography style={{ margin: "10px"}}>Löytyi <b>{ this.props.ads.length }</b>&nbsp;
					{ this.props.filter === OWN_MATCHES_FILTER_ALL && `oma${ this.props.ads.length !== 1 ? "a " : " " }` } 
					{ this.props.filter === OWN_MATCHES_FILTER_PAST && `mennyt${ this.props.ads.length !== 1 ? "tä " : " " }` } 
					{ this.props.filter === OWN_MATCHES_FILTER_UPCOMING && `tuleva${ this.props.ads.length !== 1 ? "a " : " " }` } 
					ottelu{ this.props.ads.length !== 1 ? "a." : "." }</Typography>
				}
				{ this.props.searchResultType === SEARCH_AD_BY_ID_RESULT &&
					<Typography style={{ margin: "10px"}}></Typography>
				}
				<Grid style={cardStyle}>
					{ this.props.ads.map((ad, i) => {
						let card = {}
						card.id = ad.id
						card.teamName = ad.team.name
						card.sportId = ad.sportId
						const sport = this.props.sports.find(sport => sport.id === ad.sportId) || {}
						card.responseStatus = ad.responseStatus
						card.image = `/images/${sport.image}`;
						card.sport = sport.name;
						card.opponentDivisions = ad.opponentDivisions.map(
							division => divisionToString(division.id)
						)
						card.cost = expenseModelToString(ad.expenseModel)
						card.type0 = teamTypeToString(ad.myType)
						card.type1 = teamTypeToString(ad.yourType)
						card.series0 = divisionToString(ad.myDivision)
						card.series1 = divisionToString(ad.yourDivision)
						/*
						card.detail0 = (this.props.details.find(detail => detail.id === ad.detail0Id) || {name: '-'}).name
						card.detail1 = (this.props.details.find(detail => detail.id === ad.detail1Id) || {name: '-'}).name
						*/
						card.environment = (this.props.environments.find(environment => environment.id === ad.environmentId) || {name: '-'}).name
						/*
						card.loc0 = this.props.regions.find(region => region.id === ad.innerRegion.outerRegionId).name
						card.loc1 = ad.innerRegion.name
						*/
						card.location = ad.location.name
						const startsAt = moment(ad.date)
						card.isOwn = ad.own
						if ( ad.own ) card.responses = ad.responses.length
						card.date = startsAt.format('D.M.YYYY')
						card.time = `${startsAt.format('HH:mm')} - ${moment(startsAt).add(ad.duration, 'minutes').format('HH:mm')}`
						card.turn = turnToString(ad.turn)
						card.refereeRequested = ad.refereeRequested
						card.refereeResponseStatus = 'refereeResponseStatus' in ad ? ad.refereeResponseStatus : -2
						card.refereeResponses = 'refereeResponses' in ad ? ad.refereeResponses.length : 0
						card.referee = ad.referee
						card.refereeId = ad.refereeId
						if ( ad.responseStatus === 2 ) {
							card.opponent = ad.own 
								? (ad.opponent ? ad.opponent.name : '')
								: (ad.team ? ad.team.name : '')
							card.opponentId = ad.own 
								? (ad.opponent ? ad.opponent.id : '')
								: (ad.team ? ad.team.id : '')
							card.team = ad.own 
								? (ad.team ? ad.team.name : '')
								: (ad.opponent ? ad.opponent.name : '')
							card.teamId = ad.own 
								? (ad.team ? ad.team.id : '')
								: (ad.opponent ? ad.opponent.id : '')
							card.opponentContact = ad.own
								? (ad.opponent && ad.opponent.user ? ad.opponent.user.email : '')
 								: (ad.team && ad.team.user ? ad.team.user.email : '')
						}
						return (
							<Ad key={ ad.id } 
								history={ this.props.history }
								card={ card } 
								margin={ this.props.desktop ? 10 : 3 } 
								promptLogin={this.props.promptLogin} 
							/>
						)}
					)}
				</Grid>
				<NewAdButton promptLogin={this.props.promptLogin} 
					desktop={this.props.desktop} 
					history={this.props.history} 
					padding={this.props.padding}
					margins={this.props.margins} />
				<ConfirmResponseDialog />
			</div>
		)
	}
}

const mapStateToProps = state => {
	return { 
  	  ads: state.search.results,
	  searchResultType: state.search.resultType,
	  selectedSports: state.search.selectedSports,
	  selectedOuterRegion: state.search.selectedOuterRegion,
	  selectedInnerRegion: state.search.selectedInnerRegion,
	  selectedStartDate: state.search.selectedStartDate,
	  selectedEndDate: state.search.selectedEndDate,
	  selectedStartTime: state.search.selectedStartTime,
	  selectedEndTime: state.search.selectedEndTime,
	  selectedType: state.search.selectedType,
	  selectedDivision: state.search.selectedDivision,
	  selectedMyType: state.search.selectedMyType,
	  selectedMyDivision: state.search.selectedMyDivision,
	  selectedTurn: state.search.selectedTurn,
	  selectedExpenseModel: state.search.selectedExpenseModel,
	  selectedDetail0: state.search.selectedDetail0,
	  selectedDetail1: state.search.selectedDetail1,
	  selectedEnvironment: state.search.selectedEnvironment,
	  selectedLocations: state.search.selectedLocations,
	  sortOrder: state.search.sortOrder,
	  filter: state.search.filter,
	  regions: state.static.regions,
	  sports: state.static.sports,
	  details: state.static.details,
	  environments: state.static.environments,
	  gotStatic: state.static.got,
	  login: state.login,
	  account: state.account,
	  searchParams: state.search,
	};
  };
  
const mapDispatchToProps = dispatch => ({
	search: (sports, outerRegion, innerRegion, startDate, endDate, startTime, endTime, type, division, myType, myDivision, turn, expenseModel, locations, detail0, detail1, environment, sortOrder, teamContext, token) => {
		dispatch(search(sports, outerRegion, innerRegion, startDate, endDate, startTime, endTime, type, division, myType, myDivision, turn, expenseModel, locations, detail0, detail1, environment, sortOrder, teamContext, token))
	},
	clearEditAd: () => {
		dispatch(clearEditAd())
	},
	beginConfirmResponse: adId => {
		dispatch(beginConfirmResponse(adId))
	},
	clearConfirmResponse: () => {
		dispatch(clearConfirmResponse())
	},
	redoSearch: (search, teamContext, token) => {
		dispatch(redoSearch(search, teamContext, token))
	},
	searchOwnAds: (sortOrder, teamContext, token) => {
		dispatch(searchOwnAds(sortOrder, teamContext, token))
	},
	searchOwnResponses: (sortOrder, teamContext, token) => {
		dispatch(searchOwnResponses(sortOrder, teamContext, token))
	},
	searchOwnMatches: (sortOrder, teamContext, token) => {
		dispatch(searchOwnMatches(sortOrder, teamContext, token))
	},	
	searchToPatch: (teamContext, type, ids, token, clearNotificationsId) => {
		dispatch(searchToPatch(teamContext, type, ids, token, clearNotificationsId))
	},
	removeFromResults: ids => {
		dispatch(removeFromResults(ids))
	},
	searchAdById: (adId, teamContext, token) => {
		dispatch(searchAdById(adId, teamContext, token))
	},
	beginEditTeam: teamId => {
		dispatch(beginEditTeam(teamId))
	}		
})
  
export default withWidth()(
		connect(mapStateToProps, mapDispatchToProps)(BrowseView)
)
