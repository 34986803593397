import React, { Component } from 'react';
import { connect } from "react-redux";
import TopBar from '../components/TopBar'
import moment from 'moment'
import { 
    BACKEND_URL,
    ARTICLES_NEW_PATH,
    ARTICLES_MD_PATH,
    USER_TYPE_ADMIN
} from '../const'
import axios from 'axios'
import NewAdButton from '../components/NewAdButton'

import {
    withWidth,
    Typography,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    IconButton,
    Tooltip
} from '@material-ui/core';

import {
    Add
} from '@material-ui/icons';

class ArticlesView extends Component {

    constructor(props){
        super(props)
        this.state = {
            articles: [],
            mailchimpEmail: ''
        }
    }

    componentWillMount() {
        this.fetch(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.source !== nextProps.source ) {
            this.fetch(nextProps)
        }
    }

    fetch(props) {
        const headers = props.login.token 
            ? {Authorization: props.login.token} 
            : null
        axios.get(`${BACKEND_URL}/article`, {headers})
            .then(res => {
                window.scrollTo(0, 0)
                this.setState({articles: res.data})
            }).catch(err => {
                console.error(err)
            })
    }

    redirectIfNotThere(path) {
        if ( this.props.history.location.pathname !== path ) {
            this.props.history.push(path)
        }
    }    

    render(){
        return (
            <div className="BrowseView ArticlesView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: this.props.desktop ? 67 : 63,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom
                }}>
                <TopBar tools={ 
                    this.props.login.user && 
                    this.props.login.user.type === USER_TYPE_ADMIN &&
                        <Tooltip title="Luo uusi artikkeli" aria-label="Luo uusi artikkeli">
                            <IconButton onClick={e => {
                                this.redirectIfNotThere(ARTICLES_NEW_PATH)
                            }}>
                                <Add />
                            </IconButton> 
                        </Tooltip>
                    }
                    history={this.props.history}
                    title={"Artikkelit"}
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />


            <div className="mailchimp-wrapper" style={{ marginTop: 17, marginBottom: 7, textAlign: 'center' }}>
            <div id="mc_embed_signup">
              <form action="https://harkkamatsi.us10.list-manage.com/subscribe/post?u=048e43c2cd54f15a92c076ff4&amp;id=e10ebfabfe" 
                method="post" 
                id="mc-embedded-subscribe-form" 
                name="mc-embedded-subscribe-form" 
                className="validateName" 
                target="_blank" 
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  <label htmlFor="mce-EMAIL"><Typography style={{ marginRight: 2, display: 'inline-block' }}>Tilaa uutiskirje:</Typography></label>
                  <input type="email" 
                    value={this.state.mailchimpEmail} 
                    name="EMAIL" 
                    className="email" 
                    id="mce-EMAIL" 
                    placeholder="Sähköpostiosoite"
                    onChange={e => {
                      this.setState({mailchimpEmail: e.target.value})
                    }} 
                    required 
                  />
                  <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input type="text" name="b_048e43c2cd54f15a92c076ff4_e10ebfabfe" tabIndex="-1" defaultValue="" />
                  </div>
                  <div className="clear" style={{ display: 'inline-block' }}>
                    <input type="submit" value="Tilaa" name="subscribe" id="mc-embedded-subscribe" className="button" />
                  </div>
                </div>
              </form>
            </div>
          </div>



                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {
                    this.state.articles.map((article, i) => 
                        <Card key={i} style={{ width: 480, maxWidth: '100%', marginTop: 10, marginLeft: 5, marginRight: 5 }}>
                            <CardActionArea onClick={() => {
                                this.redirectIfNotThere(ARTICLES_MD_PATH.replace(':md', article.source))
                            }}>
                                <CardMedia
                                    style={{ backgroundColor: '#e8e8e8', height: 80}}
                                    image={article.image ? `${BACKEND_URL}/article/${article.source}/${article.image}` : null}
                                    title={article.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {article.title}
                                    </Typography>
                                    <Typography style={{ whiteSpace: 'pre' }} variant="body2" color="textSecondary" component="p">
                                        {article.description}
                                    </Typography>
                                    <Typography style={{ 
                                        marginTop: 12, 
                                        fontSize: '.8em', 
                                        color: '#888',
                                        display: 'flex',
                                        flex: 1,
                                        alignItems: 'flex-end'
                                    }}>
                                        { article.draft
                                            ? <b>Luonnos</b>
                                            : <span>Julkaistu: {article.publishedAt ? moment(article.publishedAt).format('DD.MM.YYYY HH:mm') : '-'}</span>
                                        }
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )
                }
                </div>
                <NewAdButton promptLogin={this.props.promptLogin} 
                    desktop={this.props.desktop} 
                    padding={this.props.padding}
					history={this.props.history} 
					margins={this.props.margins} />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login
})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(ArticlesView)
)