import moment from 'moment'

import { 
  TOGGLE_SPORT,
  SELECT_SPORTS,
  SELECT_OUTER_REGION,
  SELECT_INNER_REGION,
  SEARCH,
  SEARCH_TO_PATCH,
  TOGGLE_RESPONSE,
  TOGGLE_REFEREE_REQUEST,
  REMOVE_FROM_RESULTS,
  SET_START_DATE,
  SET_END_DATE,
  SET_START_TIME,
  SET_END_TIME,
  SET_TIME_RANGE,
  SELECT_TYPE,
  SELECT_DIVISION,
  SELECT_MY_TYPE,
  SELECT_MY_DIVISION,
  SELECT_TURN,
  SELECT_EXPENSE_MODEL,
  TOGGLE_CATEGORY_EXPANDED,
  SET_LOCATIONS,
  SET_SORT_ORDER,
  SET_FILTER,
  SELECT_DETAIL_0,
  SELECT_DETAIL_1,
  SELECT_ENVIRONMENT
} from "./types"

import {
  SORT_ORDER_DESCENDING,
  OWN_MATCHES_FILTER_ALL
} from "../../const"

const initialState = {
  selectedSports: [],
  selectedOuterRegion: -1,
  selectedInnerRegion: -1,
  selectedLocations: [],
  selectedStartDate: moment().toDate(),
  selectedEndDate: moment().add(4, 'month').toDate(),
  selectedStartTime: null,
  selectedEndTime: null,
  results: [],
  resultType: null,
  selectedType: -1,
  selectedDivision: -1,
  selectedMyType: -1,
  selectedMyDivision: -1,
  selectedTurn: -1,
  selectedExpenseModel: -1,
  selectedDetail0: -1,
  selectedDetail1: -1,
  selectedEnvironment: -1,
  categoryExpanded: [true, true, true, false, false],
  sortOrder: SORT_ORDER_DESCENDING,
  filter: OWN_MATCHES_FILTER_ALL
}

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SPORT: {
      const { id } = action.payload
      return {
        ...state,
        selectedSports: state.selectedSports.indexOf(id) < 0
          ? [...state.selectedSports, id]
          : state.selectedSports.filter(
            sportId => sportId !== id
          )
      }
    }
    case SELECT_SPORTS: {
      const { sports } = action.payload
      return {
        ...state,
        selectedSports: sports
      }
    }
    case SELECT_OUTER_REGION: {
      const { id } = action.payload
      return {
        ...state,
        selectedOuterRegion: id,
        selectedInnerRegion: -1
      }
    }
    case SELECT_INNER_REGION: {
      const { id } = action.payload
      return {
        ...state,
        selectedInnerRegion: id
      }
    }
    case SEARCH: {
      return {
        ...state,
        ...action.payload
      }
    }
    case SEARCH_TO_PATCH: {
      const copyOfResults = [...state.results]
      action.payload.forEach(ad => {
        const i = copyOfResults.findIndex(prevAd => prevAd.id === ad.id)
        if ( i >= 0 ) copyOfResults[i] = ad
      })
      return {
        ...state,
        results: copyOfResults
      }
    }
    case REMOVE_FROM_RESULTS: {
      const ids = action.payload
      return {
        ...state,
        results: state.results.filter(ad => 
          !ids.some(id => ad.id === id)
        )
      }
    }
    case TOGGLE_RESPONSE: {
      const { id, responseStatus } = action.payload
      const copyOfResults = [...state.results]
      const ad = copyOfResults.find(
        ad => parseInt(ad.id) === parseInt(id)
      )
      if ( ad ) ad.responseStatus = responseStatus
      return {
        ...state,
        results: copyOfResults
      }
    }
    case TOGGLE_REFEREE_REQUEST: {
      const { id, refereeRequested } = action.payload
      const copyOfResults = [...state.results]
      const ad = copyOfResults.find(
        ad => parseInt(ad.id) === parseInt(id)
      )
      if ( ad ) ad.refereeRequested = refereeRequested
      return {
        ...state,
        results: copyOfResults
      }
    }
    case SET_START_DATE: {
      return {
        ...state,
        selectedStartDate: action.payload
      }
    }
    case SET_END_DATE: {
      return {
        ...state,
        selectedEndDate: action.payload
      }
    }
    case SET_START_TIME: {
      return {
        ...state,
        selectedStartTime: action.payload
      }
    }
    case SET_END_TIME: {
      return {
        ...state,
        selectedEndTime: action.payload
      }
    }
    case SET_TIME_RANGE: {
      return {
        ...state,
        selectedStartTime: action.payload[0],
        selectedEndTime: action.payload[1]
      }
    }
    case SELECT_TYPE: {
      const { type } = action.payload
      return {
        ...state,
        selectedType: type
      }
    }
    case SELECT_DIVISION: {
      const { division } = action.payload
      return {
        ...state,
        selectedDivision: division
      }
    }
    case SELECT_MY_TYPE: {
      const { myType } = action.payload
      return {
        ...state,
        selectedMyType: myType
      }
    }
    case SELECT_MY_DIVISION: {
      const { myDivision } = action.payload
      return {
        ...state,
        selectedMyDivision: myDivision
      }
    }
    case SELECT_TURN: {
      const { turn } = action.payload
      return {
        ...state,
        selectedTurn: turn
      }
    }
    case SELECT_EXPENSE_MODEL: {
      const { expenseModel } = action.payload
      return {
        ...state,
        selectedExpenseModel: expenseModel
      }
    }
    case TOGGLE_CATEGORY_EXPANDED: {
      const { i } = action.payload
      const categoryExpanded = [...state.categoryExpanded]
      categoryExpanded[i] = !categoryExpanded[i]
      return {
        ...state,
        categoryExpanded
      }
    }
    case SET_LOCATIONS: {
      const { locations } = action.payload
      return {
        ...state,
        selectedLocations: locations
      }
    }
    case SELECT_DETAIL_0: {
      const { detail0 } = action.payload
      return {
        ...state,
        selectedDetail0: detail0
      }
    }
    case SELECT_DETAIL_1: {
      const { detail1 } = action.payload
      return {
        ...state,
        selectedDetail1: detail1
      }
    }
    case SELECT_ENVIRONMENT: {
      const { environment } = action.payload
      return {
        ...state,
        selectedEnvironment: environment
      }
    }
    case SET_SORT_ORDER: {
      const { sortOrder } = action.payload
      return {
        ...state,
        sortOrder
      }
    }
    case SET_FILTER: {
      const { filter } = action.payload
      return {
        ...state,
        filter
      }
    }
    default:
      return state;
  }
}