import React from 'react';
import { connect } from "react-redux";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import {
    Box,
    Fab,
    Typography,
    TextField,
    Button,
    InputAdornment
} from '@material-ui/core';

import {
    AlternateEmail,
    Lock,
    Edit
} from '@material-ui/icons';

import ResetPasswordDialog from './ResetPasswordDialog'
import RegisterDialog from './RegisterDialog'

import {
    NOT_LOGGED_IN_FAILURE,
    GOOGLE_CLIENT_ID,
    FACEBOOK_APP_ID,
    FRONTEND_URL
} from '../const'

import { 
    login,
    googleLogin,
    facebookLogin,
    clearLoginFailure,
    beginRegister,
    beginResetPassword
} from '../redux/login/actions'  

class LoginMenu extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: ''
        }
    }

    FAILURE_COLOR = "#f00";

    render() {

        const classes = {};

        return (
            <div style={{ padding: 20, paddingTop: 10, display: 'flex', flexFlow: 'column' }}>

                <div style={{ display: 'flex', flexFlow: 'column' }} className="some-btns">

                    <GoogleLogin
                        clientId={ GOOGLE_CLIENT_ID }
                        buttonText="Kirjaudu Google-tilillä"
                        responseType='code'
                        onSuccess={responseGoogle => {
                            this.props.doGoogleLogin(responseGoogle.code)
                        }}
                        onFailure={responseGoogle => {
                            console.error(responseGoogle)
                        }}
                        cookiePolicy={'single_host_origin'}
                    />

                    <div style={{ margin: 5 }}></div>

                    <FacebookLogin
                        appId={ FACEBOOK_APP_ID }
                        autoLoad={false}
                        redirectUri={ FRONTEND_URL }
                        fields="name,email,picture"
                        callback={responseFacebook => {
                            this.props.doFacebookLogin(responseFacebook.accessToken)
                        }}
                        render={renderProps => (
                            <button className="facebook-btn" onClick={renderProps.onClick}>
                                <img alt="Facebook" className="facebook-logo" src={'/images/f_logo_RGB-Blue_58.png'}></img>
                                <span className="facebook-text">Kirjaudu Facebook-tilillä</span>
                            </button>
                        )}
                        cssClass="my-facebook-button-class"
                        icon="fa-facebook"
                    />

                </div>

                <div style={{ margin: "10px"}}></div>

                <Box component="span" style={{ marginTop: 10 }}>
                    <Typography>Kirjaudu tunnuksilla</Typography>
                </Box>

                <TextField
                style={{ marginBottom: "10px", marginTop: "10px"}}
                label="Sähköpostiosoite"
                type="email"
                value={this.state.email}
                onKeyUp={e => {
                    if ( e.keyCode === 13 ) {
                        this.props.doLogin(
                            this.state.email,
                            this.state.password
                        )
                    } else if ( this.props.login.state === NOT_LOGGED_IN_FAILURE ) {
                        this.props.clearLoginFailure()
                    }
                }}
                onChange={e => {
                    this.setState({email: e.target.value})
                }}
                InputProps={{
                    style: this.props.login.state === NOT_LOGGED_IN_FAILURE 
                        ? { color: this.FAILURE_COLOR } : {},
                    startAdornment: (
                        <InputAdornment position="start">
                            <AlternateEmail />
                        </InputAdornment>
                    ),
                }}
                />
                <TextField
                style={{ marginBottom: "10px"}}
                id="input-password"
                type="password"
                label="Salasana"
                onKeyUp={e => {
                    if ( e.keyCode === 13 ) {
                        this.props.doLogin(
                            this.state.email,
                            this.state.password
                        )
                    } else if ( this.props.login.state === NOT_LOGGED_IN_FAILURE ) {
                        this.props.clearLoginFailure()
                    }
                }}
                onChange={e => {
                    this.setState({password: e.target.value})
                }}
                InputProps={{
                    style: this.props.login.state === NOT_LOGGED_IN_FAILURE 
                        ? { color: this.FAILURE_COLOR } : {},
                    startAdornment: (
                        <InputAdornment position="start">
                            <Lock />
                        </InputAdornment>
                    ),
                }}
                />

                <Fab
                    variant="extended"
                    size="large"
                    color="primary"
                    aria-label="Add"
                    className={classes.button}
                    onClick={e => {
                    this.props.doLogin(
                        this.state.email,
                        this.state.password
                    )
                    }}
                >
                    Kirjaudu sisään
                </Fab>

                <Button onClick={e => {
                    this.props.beginResetPassword()
                }} color="primary" style={{marginTop: "5px"}}>
                    Unohtuiko salasana?
                </Button>

                <div style={{ margin: "8px"}}></div>

                <Fab
                    variant="extended"
                    size="large"
                    elevation={0}
                    style={{ color: "#000", fontWeight: "bold", backgroundColor: "#fff"}}
                    className={classes.button}
                    onClick={e => {
                    this.props.beginRegister()
                    }}
                >
                <Edit style={{ marginRight: "5px" }}/>Rekisteröidy
                </Fab>
                <ResetPasswordDialog initEmail={this.state.email} />
                <RegisterDialog />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return { 
      login: state.login
    };
  };
  
const mapDispatchToProps = dispatch => ({
    beginRegister: () => {
        dispatch(beginRegister())
      },
    beginResetPassword: () => {
        dispatch(beginResetPassword())
    },
    doLogin: (email, password) => {
        dispatch(login(email, password))
      },
    doGoogleLogin: code => {
        dispatch(googleLogin(code))
      },
    doFacebookLogin: token => {
        dispatch(facebookLogin(token))
      },
    clearLoginFailure: () => {
        dispatch(clearLoginFailure())
    },        
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginMenu)