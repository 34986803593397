import React, { Component } from 'react';
import { connect } from "react-redux";
import TopBar from '../components/TopBar'
import marked from 'marked';
import NewAdButton from '../components/NewAdButton'

import {
    withWidth,
    Typography,
    Paper
} from '@material-ui/core';

class MarkdownView extends Component {

    constructor(props){
        super(props)
        this.state = {
            content: null
        }
    }

    componentWillMount() {
        this.fetch(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.source !== nextProps.source ) {
            this.fetch(nextProps)
        }
    }

    fetch(props) {
        fetch(props.source)
            .then(res => res.text())
            .then(content => {
                window.scrollTo(0, 0)
                this.setState({content})
            })
    }

    render(){
        return (
            <div className="BrowseView MarkdownView" 
                style={{ 
                    ...this.props.padding, 
                    ...this.props.margins, 
                    marginBottom: this.props.desktop ? 67 : 63,
                    paddingBottom: this.props.desktop
                        ? 0
                        : this.props.padding.paddingBottom
                }}>
                <TopBar history={this.props.history}
                    title={this.props.title}
                    desktop={this.props.desktop} 
                    left={this.props.margins.marginLeft} 
                    height={this.props.topBarHeight} />
                <Paper style={{ marginTop: 10, padding: 10, marginBottom: this.props.cookieConsent === null ? 200 : 10 }}>
                    { this.state.content !== null &&
                        <Typography style={{ margin: "10px"}} dangerouslySetInnerHTML={{__html: marked(this.state.content)}}></Typography>
                    }
                </Paper>
                <NewAdButton promptLogin={this.props.promptLogin} 
   					padding={this.props.padding}
					desktop={this.props.desktop} 
					history={this.props.history} 
					margins={this.props.margins} />
            </div>
        )
    }

}

const mapStateToProps = state => ({
    cookieConsent: state.app.cookieConsent
})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(MarkdownView)
)