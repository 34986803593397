import React from 'react';
import { connect } from "react-redux";

import {
    withMobileDialog,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    InputAdornment
} from '@material-ui/core';

import { AlternateEmail } from '@material-ui/icons';

import { 
    beginResetPassword,
    resetPassword,
    clearResetPassword
} from '../redux/login/actions'

import {  
    RESET_PASSWORD_INIT,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE_404,
    RESET_PASSWORD_FAILURE_500,
} from '../const'

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class ResetPasswordDialog extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            resetPasswordEmail: props.initEmail
        }
    }    

    componentWillReceiveProps(nextProps) {
        if ( this.props.login.resetPasswordState !== nextProps.login.resetPasswordState 
            && nextProps.login.resetPasswordState === RESET_PASSWORD_INIT ) {
                this.setState({resetPasswordEmail: this.props.initEmail})
            }  
        if ( this.props.login.resetPasswordState !== nextProps.login.resetPasswordState 
          && nextProps.login.resetPasswordState === RESET_PASSWORD_SUCCESS ) {
            this.props.clearResetPassword()
          }
    }
    
    render() {
        return (
            <MobileDialog
                open={this.props.login.resetPasswordState !== null}
                onClose={e => {this.props.clearResetPassword()}}
            >
            <DialogTitle>Unohtuiko salasana?</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Syötä sähköpostiosoitteesi. Lähetämme osoitteeseen linkin, jonka avulla voit asettaa uuden salasanan.
            </DialogContentText>
            <TextField
                autoFocus
                id="input-resetPassword"
                type="email"
                label="Sähköpostiosoite"
                value={ this.state.resetPasswordEmail }
                onKeyUp={e => {
                if ( e.keyCode === 13 ) {
                    this.props.resetPassword(this.state.resetPasswordEmail)
                }
                }}
                onChange={e => {
                if ( this.props.login.resetPasswordState === RESET_PASSWORD_FAILURE_404 ||
                    this.props.login.resetPasswordState === RESET_PASSWORD_FAILURE_500 ) {
                    this.props.beginResetPassword()
                    }
                this.setState({resetPasswordEmail: e.target.value})
                }}
                InputProps={{
                style: this.props.login.resetPasswordState === RESET_PASSWORD_FAILURE_404 ||
                    this.props.login.resetPasswordState === RESET_PASSWORD_FAILURE_500
                    ? { color: this.FAILURE_COLOR } 
                    : {},  
                startAdornment: (
                    <InputAdornment position="start">
                    <AlternateEmail />
                    </InputAdornment>
                ),
                }}
            />
            <DialogContentText style={{ color: 'red', fontSize: '.8em' }}>
                { this.props.login.resetPasswordState === RESET_PASSWORD_FAILURE_404 
                ? 'Sähköpostiosoitteella ei löytynyt käyttäjätiliä.'
                : (
                    this.props.login.resetPasswordState === RESET_PASSWORD_FAILURE_500
                    ? 'Palvelinvirhe. Yritä myöhemmin uudestaan.'
                    : ''
                )
                }
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button 
                disabled={ !this.state.resetPasswordEmail.length }
                onClick={e => {this.props.resetPassword(this.state.resetPasswordEmail)}} color="primary" variant="contained">
                Lähetä
            </Button>
            <Button onClick={e => {this.props.clearResetPassword()}} color="primary">
                Peruuta
            </Button>
            </DialogActions>
        </MobileDialog>
        )
    }

}

const mapStateToProps = state => {
    return { 
      login: state.login
    };
  };
  
const mapDispatchToProps = dispatch => ({
    beginResetPassword: () => {
      dispatch(beginResetPassword())
    },
    resetPassword: email => {
      dispatch(resetPassword(email))
    },
    clearResetPassword: () => {
      dispatch(clearResetPassword())
    }
  })
  
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordDialog)