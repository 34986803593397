import { GET } from "./types"

const initialState = {
    locations: [],
    regions: [],
    sports: [],
    details: [],
    environments: [],
    got: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET: {
      return {
        ...action.payload,
        got: true
      };
    }
    default:
      return state;
  }
}