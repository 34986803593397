import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import BrowseView from './views/BrowseView';
import MarkdownView from './views/MarkdownView';
import TeamView from './views/TeamView';
import ReportView from './views/ReportView';
import ArticlesView from './views/ArticlesView';
import ArticleView from './views/ArticleView';
import ArticleEditorView from './views/ArticleEditorView';
import PricingView from './views/PricingView';
import GuideView from './views/GuideView';

import LeftDrawer from './components/LeftDrawer';
import RightDrawer from './components/RightDrawer';
import BottomBar from './components/BottomBar';
import DoResetPasswordDialog from './components/DoResetPasswordDialog'
import EditTeamDialog from './components/EditTeamDialog';
import EditAdDialog from './components/EditAdDialog';
import TransferCreditsDialog from './components/TransferCreditsDialog';
//import MessengerCustomerChat from 'react-messenger-customer-chat';

import {
  createMuiTheme,
  withWidth,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Link
} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/core/styles';
import { 
  LOGGED_IN, 
  VERSION, 
  UNDEFINED_VERSION,
  COOKIE_CONSENT_PATH,
  PRIVACY_POLICY_PATH,
  TEAM_PATH,
  LOGIN_TOKEN_PATH,
  COLOR_CTRL_PATH,
  REPORT_PATH,
  ARTICLES_PATH,
  ARTICLES_MD_PATH,
  ARTICLES_NEW_PATH,
  ARTICLES_EDIT_PATH,
  PRICING_PATH,
/*  FACEBOOK_APP_ID,
  FACEBOOK_PAGE_ID,
  FACEBOOK_CHAT_GREETING,*/
  GUIDE_PATH
} from './const'
import { doRegister } from './redux/login/actions'
import { 
  clearState,
  acceptCookies 
} from './redux/app/actions'

const themeDefinition = {
  MuiButtonBase: {
    disableRipple: true
  },
  typography: {
    fontSize: 13,
    fontFamily: ['Arial'].join(','),
  },
  palette: {
    primary: {
			main: "#34812C",
			contrastText: "#ffffff"
		},
  },
}

const topBarHeight = 60

class App extends React.Component {

  theme = null;

  constructor(props) {
    super(props)
    console.log(`Persisted app version: ${this.props.app.VERSION}`)
    console.log(`Current app version: ${VERSION}`)
    if ( (this.props.app || { VERSION: UNDEFINED_VERSION }).VERSION !== VERSION ) {
      this.props.clearState()
      return
    }
    this.state = {
      openLoginAlert: false,
      left: false,
      right: props.match.path === '/' && props.login.state !== LOGGED_IN
    }

    if ( props.match.path === LOGIN_TOKEN_PATH 
      && props.match.params.token !== undefined ) {
        props.doRegister(props.match.params.token)
    }

    if ( props.match.path === COLOR_CTRL_PATH ) {
      this.theme = createMuiTheme({
        ...themeDefinition,
        palette: {
          primary: {
            main: `#${props.match.params.btnBg}`,
            contrastText: `#${props.match.params.btnCt}`
          }
        }
      })
    } else {
      this.theme = createMuiTheme(themeDefinition)
    }
  }

  redirectIfNotThere(path) {
    if ( this.props.history.location.pathname !== path ) {
      this.props.history.push(path)
    }
  }

  componentWillReceiveProps(nextProps) {
    if ( this.props.match.path !== nextProps.match.path ) {
      console.log(`${this.props.match.path} -> ${nextProps.match.path}`)
    }
    if ( this.props.login.doRegisterState !== nextProps.login.doRegisterState ) {
      this.props.history.push('/')
    }
  }

	render() {
    if ( (this.props.app || { VERSION: UNDEFINED_VERSION }).VERSION !== VERSION ) {
      return null
    }
    const toggleLeft = e => {
      this.setState({left: !this.state.left})
    } 
    const toggleRight = e => {
      this.setState({right: !this.state.right})
    }
    const desktop = this.props.width === 'md'
      || this.props.width === 'lg'
      || this.props.width === 'xl'
    const margins = {
      marginLeft: /*desktop ? 300 : */0,
      marginRight: /*desktop ? 290 : */0,
      marginTop: 0,
      marginBottom: desktop ? 0 : 70,
    } 
    const padding = {
      paddingTop: desktop ? 10 : topBarHeight,
      paddingRight: 10 + ( desktop ? 290 : 0 ),
      paddingBottom: desktop ? 60 : 55,
      paddingLeft: 10 + ( desktop ? 300 : 0 )
    }
    const promptLogin = text => {
      this.setState({
        loginAlertText: text,
        openLoginAlert: true
      })
      if ( !desktop && !this.state.right ) {
        toggleRight()
      }
    }
		return (
        <ThemeProvider theme={this.theme}>
          <LeftDrawer history={this.props.history}
            toggle={toggleLeft} 
            parentState={this.state}
            desktop={desktop} />
          <RightDrawer history={this.props.history}
            toggle={toggleRight} 
            parentStateRight={this.state.right} 
            path={this.props.match.path}
            desktop={desktop} />
          <div className="main">
            { ( ( this.props.match.path === ARTICLES_NEW_PATH || 
              this.props.match.path === ARTICLES_EDIT_PATH ) && 
              <ArticleEditorView 
                source={this.props.match.params.id}
                history={this.props.history} 
                desktop={desktop} 
                margins={margins} 
                padding={padding} 
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === ARTICLES_MD_PATH && 
              <ArticleView 
                source={this.props.match.params.md}
                history={this.props.history}
                promptLogin={promptLogin} 
                desktop={desktop} 
                margins={margins} 
                padding={padding} 
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === ARTICLES_PATH && 
              <ArticlesView 
                location={this.props.location}
                history={this.props.history} 
                promptLogin={promptLogin}
                desktop={desktop} 
                margins={margins} 
                padding={padding} 
                match={this.props.match}
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === PRICING_PATH && 
                <PricingView 
                  location={this.props.location}
                  history={this.props.history} 
                  promptLogin={promptLogin}
                  desktop={desktop} 
                  margins={margins} 
                  padding={padding} 
                  match={this.props.match}
                  topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === REPORT_PATH && 
              <ReportView 
                location={this.props.location}
                history={this.props.history} 
                desktop={desktop} 
                margins={margins} 
                padding={padding} 
                match={this.props.match}
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === PRIVACY_POLICY_PATH && 
              <MarkdownView 
                title="Tietosuojaseloste"
                source="md/privacy.md"
                promptLogin={promptLogin}
                history={this.props.history} 
                desktop={desktop} 
                margins={margins} 
                padding={padding} 
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === COOKIE_CONSENT_PATH && 
              <MarkdownView 
                title="Evästeet"
                source="md/cookies.md"
                promptLogin={promptLogin}
                history={this.props.history} 
                desktop={desktop} 
                margins={margins} 
                padding={padding} 
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === TEAM_PATH && 
              <TeamView 
                history={this.props.history} 
                desktop={desktop} 
                promptLogin={promptLogin}
                margins={margins} 
                padding={padding} 
                teamId={parseInt(this.props.match.params.teamId)}
                match={this.props.match}
                topBarHeight={topBarHeight} />
              ) || ( this.props.match.path === GUIDE_PATH && 
                <GuideView 
                  history={this.props.history} 
                  desktop={desktop} 
                  promptLogin={promptLogin}
                  margins={margins} 
                  padding={padding} 
                  match={this.props.match}
                  topBarHeight={topBarHeight} />
              ) || 
              <BrowseView match={this.props.match} 
                history={this.props.history} 
                desktop={desktop} 
                promptLogin={promptLogin} 
                margins={margins} 
                padding={padding} 
                topBarHeight={topBarHeight} />
            }
          </div>
          { /*this.props.match.path !== ARTICLES_NEW_PATH &&
            this.props.match.path !== ARTICLES_EDIT_PATH && 
            this.props.match.path !== REPORT_PATH &&
          <MessengerCustomerChat
            appId={FACEBOOK_APP_ID}
            pageId={FACEBOOK_PAGE_ID}
            language="fi_FI"
            loggedInGreeting={FACEBOOK_CHAT_GREETING}
            loggedOutGreeting="Hei! Kuinka voimme auttaa?"
            themeColor="#67b868"
          />
          */ }
          { !desktop && <BottomBar 
            toggleLeft={toggleLeft} 
            toggleRight={toggleRight} 
          /> }
            <DoResetPasswordDialog match={this.props.match} history={this.props.history} />
            <Dialog
              open={this.state.openLoginAlert}
              onClose={e => {this.setState({openLoginAlert: false})}}
            >
              <DialogTitle>Kirjaudu sisään</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  { this.state.loginAlertText }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={e => {this.setState({openLoginAlert: false})}} color="primary" autoFocus>
                  Sulje
                </Button>
              </DialogActions>
            </Dialog>
            <EditAdDialog />
            { this.props.login.user &&
              <TransferCreditsDialog />
            }
            <EditTeamDialog history={this.props.history} />
            <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                style={{ marginBottom: desktop ? 0 : 50 }}
                open={!this.props.app.cookieConsent}
                autoHideDuration={6000}
                message={<span>
                  Käytämme evästeitä sivuston ja palvelumme kehittämiseen ja toteuttamiseen, parantaaksemme käyttökokemusta sekä kohdentaaksemme markkinointia. Hyväksyt evästeiden käytön jatkamalla sivustomme parissa.&nbsp;
                  <Link 
                    style={{ color: 'white', textDecoration: 'underline' }}
                    component="button" 
                    variant="body2" 
                    onClick={e => {this.redirectIfNotThere(COOKIE_CONSENT_PATH)}}>
                      Listätietoa evästeistä.
                  </Link>                  
                </span>}
                action={
                  <React.Fragment>
                    <Button color="primary" variant="contained" size="small" onClick={this.props.acceptCookies}>
                      OK
                    </Button>
                  </React.Fragment>
                }
              />
          </ThemeProvider>
    )
  }
}


const mapStateToProps = state => {
	return {
    login: state.login,
    app: state.app
	};
};
  
const mapDispatchToProps = dispatch => ({
  doRegister: token => {
    dispatch(doRegister(token))
  },
  acceptCookies: () => {
    dispatch(acceptCookies())
  },
  clearState: () => {
    dispatch(clearState())
  }
})
  
export default withWidth()(withRouter(
	connect(mapStateToProps, mapDispatchToProps)(App)
))