import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { Route, Switch, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import axios from 'axios'

import { ErrorBoundary } from 'react-error-boundary'

import * as serviceWorker from './serviceWorker'
import './index.css'
import App from './App'

import { purgeStoredState, persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/es/integration/react'
import storage from 'redux-persist/es/storage'

import appReducer from './redux/reducers'
import { get } from './redux/static/actions'

import { 
  BACKEND_URL,
  OWN_ADS_PATH,
  OWN_RESPONSES_PATH,
  OWN_MATCHES_PATH,
  COOKIE_CONSENT_PATH,
  PRIVACY_POLICY_PATH,
  TEAM_PATH,
  LOGIN_TOKEN_PATH,
  COLOR_CTRL_PATH,
  RESET_PASSWORD_TOKEN_PATH,
  AD_PATH,
  REPORT_PATH,
  ARTICLES_PATH,
  ARTICLES_MD_PATH,
  ARTICLES_NEW_PATH,
  ARTICLES_EDIT_PATH,
  PRICING_PATH,
  GUIDE_PATH
} from './const'

const config = {
  debug: false,
  key: 'root',
  storage,
  blacklist: ['view']
};

const rootReducer = persistReducer(config, ( state, action ) => {
  if ( action.type === 'CLEAR_STATE' ) {
    purgeStoredState(config)
    window.location.reload()
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
})

const store = createStore(
  rootReducer, 
  applyMiddleware(thunk)
)

const history = createBrowserHistory()
const persistor = persistStore(store)

window.store = store

store.dispatch(get())

ReactDOM.render(
  <ErrorBoundary onError={(error, componentStack) => {
    axios.post(`${BACKEND_URL}/error`, {error: error ? error.toString() : error, componentStack})
      .then(res => {
        store.dispatch({type: 'CLEAR_STATE'})
      }).catch(err => {
        store.dispatch({type: 'CLEAR_STATE'})
      })
    return null
  }}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Switch>
            <Route 
              path={RESET_PASSWORD_TOKEN_PATH}
              render={props => <App {...props} />}
            />
            <Route 
              path={LOGIN_TOKEN_PATH}
              render={props => <App {...props} />}
            />        
            <Route 
              path={COLOR_CTRL_PATH}
              render={props => <App {...props} />}
            />        
            <Route
              path={AD_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={OWN_ADS_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={OWN_RESPONSES_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={OWN_MATCHES_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={TEAM_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={PRIVACY_POLICY_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={COOKIE_CONSENT_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={REPORT_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={ARTICLES_EDIT_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={ARTICLES_NEW_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={ARTICLES_MD_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={ARTICLES_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={GUIDE_PATH}
              render={props => <App {...props} />}
            />
            <Route
              path={PRICING_PATH}
              render={props => <App {...props} />}
            />
            <Route component={App} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
	document.getElementById('root')
);

serviceWorker.unregister()
