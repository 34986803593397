import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  turnToString,
  expenseModelToString,
  teamTypeToString,
  getNumberOfDivisions,
  divisionToString,
  getEnvironmentsForSports
} from '../utils';

import { 
  toggleSport,
  selectOuterRegion,
  selectInnerRegion,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
  setTimeRange,
  selectTurn,
  selectExpenseModel,
  selectType,
  selectDivision,
  selectMyType,
  selectMyDivision,
  selectDetail0,
  selectDetail1,
  selectEnvironment,
  setLocations,
  toggleCategoryExpanded
} from '../redux/search/actions';

import {
  Place,
  Close,
  ExpandMore,
  DateRange,
  People,
  MoreVert
} from '@material-ui/icons';

import {
  withWidth,
  Drawer,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
/*  Fab,*/
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  Checkbox,
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField
} from '@material-ui/core';

import {
  Autocomplete
} from '@material-ui/lab'

import DateFnsUtils from '@date-io/date-fns';
import fiLocale from "date-fns/locale/fi";

import {
  MuiPickersUtilsProvider,
  DatePicker,
/*  TimePicker*/
} from '@material-ui/pickers';

const drawerWidth = 300;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    boxSizing: 'border-box',
    padding: 0,
    paddingTop: 0,
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: 100,
  },
  selectEmpty: {}
});

const CustomExpansionPanel = withStyles({
  root: {
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(ExpansionPanel);

const logo = "logo.png";

class LeftDrawer extends React.Component {

  times = [
    {
      startsAt: -1,
      endsAt: 0,
      label: "Kaikki ajankohdat"
    },
    {
      startsAt: 0,
      endsAt: 7,
      label: "Aamu"
    },
    {
      startsAt: 7,
      endsAt: 10,
      label: "Aamupäivä"
    },
    {
      startsAt: 10,
      endsAt: 15,
      label: "Iltapäivä"
    },
    {
      startsAt: 15,
      endsAt: 23,
      label: "Ilta"
    }
  ]

  componentWillReceiveProps(nextProps) {
    if ( this.props.selectedSports !== nextProps.selectedSports ) {
      if ( this.getMaxNumberOfDivisions(this.props.selectedType, this.props.selectedSports) 
        !== this.getMaxNumberOfDivisions(this.props.selectedType, nextProps.selectedSports)) {
          this.props.selectDivision(-1)
      }
      if ( this.getMaxNumberOfDivisions(this.props.selectedMyType, this.props.selectedSports) 
        !== this.getMaxNumberOfDivisions(this.props.selectedMyType, nextProps.selectedSports)) {
          this.props.selectMyDivision(-1)
      }
    }
  }

  render() {
    const { classes } = this.props;
    const bannerHeight = 200;
    return (
      <div className={classes.root}>
        <Drawer position="fixed"
          id="LeftDrawer"
          className={classes.drawer}
          variant={ this.props.desktop ? "permanent" : "temporary" }
          open={this.props.parentState.left}
          classes={{paper: classes.drawerPaper}}
          onClose={this.props.toggle}
          anchor="left">
            { this.props.width === 'xs' &&
            <div>
              <IconButton
                color="inherit"
                onClick={this.props.toggle}
                edge="end"
                >
                  <Close />
              </IconButton>
            </div> }
            { this.props.width !== 'xs'&& this.props.width !== 'sm' &&
              <div onClick={() => {this.redirectIfNotThere('/')}}
                  style={{ 
                  cursor: 'pointer',
                  maxWidth: '100%', 
                  minHeight: bannerHeight, 
                  backgroundImage: `url("/images/${logo}")`,
                  backgroundSize: 'cover'
              }}></div>
            }
            { this.search(classes) }
        </Drawer>
      </div>
      )
    }

    redirectIfNotThere(path) {
      if ( this.props.history.location.pathname !== path ) {
        this.props.history.push(path)
      }
    }

    getNumberOfDivisions(type, selectedSports=null){
      const _selectedSports = selectedSports || this.props.selectedSports
      return (_selectedSports.length ? _selectedSports : this.props.sports.map(sport => sport.id))
        .map(sportId => getNumberOfDivisions(this.props.sports, sportId, type))
    }

    getMaxNumberOfDivisions(type, selectedSports=null){
      const n = Math.max(...this.getNumberOfDivisions(type, selectedSports))
      return Number.isInteger(n) ? n : 0
    }

    search(classes){
      
      const divisions = [...Array(this.getMaxNumberOfDivisions(this.props.selectedType)).keys(), 999].reverse()
      const myDivisions = [...Array(this.getMaxNumberOfDivisions(this.props.selectedMyType)).keys(), 999].reverse()

      return (
        <React.Fragment>
          <CustomExpansionPanel expanded={this.props.categoryExpanded[0]}>
            <ExpansionPanelSummary
              onClick={() => {this.props.toggleCategoryExpanded(0)}}
              expandIcon={<ExpandMore />}
            >
              <Typography className={classes.heading}>
                <img alt="" className={"MuiSvgIcon-root"} src={'/images/baseball.svg'}></img>Laji
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="sportsExpansionPanel">
              <List dense>
                {this.props.sports.map((value, i) => (
                  <ListItem key={i}
                    style={{ fontSize: '1rem' }}
                    onClick={e => {
                      this.redirectIfNotThere('/')
                      this.props.toggleSport(value.id)
                      window.scrollTo(0, 0)
                    }}
                    button>
                    <ListItemAvatar>
                      <Avatar src={`/images/${value.thumbnail}`} />
                    </ListItemAvatar>
                    <ListItemText primary={value.name} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={this.props.selectedSports.indexOf(value.id) >= 0}
                        onClick={e => {
                          this.props.toggleSport(value.id)
                          window.scrollTo(0, 0)
                        }}
                        color="primary"
                        edge="end"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </ExpansionPanelDetails>
          </CustomExpansionPanel>
          <CustomExpansionPanel expanded={this.props.categoryExpanded[1]}>
            <ExpansionPanelSummary
              onClick={() => {this.props.toggleCategoryExpanded(1)}}
              expandIcon={<ExpandMore />}
            >
              <Typography className={classes.heading}><Place />Sijainti</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container>
                <Grid xs={/*9*/12} item>
                <Autocomplete
                    multiple
                    noOptionsText="Ei sijainteja"
                    options={this.props.locations}
                    getOptionLabel={option => option.name}
                    filterSelectedOptions
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={ this.props.selectedLocations.length ? "" : "Valitse sijainti..." }
                      />
                    )}
                    value={
                      this.props.locations.filter(
                        location => this.props.selectedLocations.find(
                          selectedLocation => selectedLocation === location.id
                        ) != null
                      )
                    }
                    onChange={(e, newValue)=> {
                      this.props.setLocations(newValue.map(location => location.id))
                    }}
                  />
                </Grid>
                {/*}
                <Grid xs={3} style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }} item>
                  <Fab color="primary" style={{ top: 5, width: 48, height: 48, boxShadow: '0 0 1px 1px rgba(0,0,0,.25)', backgroundColor: "white", color: "#444" }} className={classes.fab}>
                    <Place />
                  </Fab>
                </Grid>
                */}
              </Grid>
            </ExpansionPanelDetails>
          </CustomExpansionPanel>
          <MuiPickersUtilsProvider utils={DateFnsUtils} 
            locale={fiLocale}
          >
          <CustomExpansionPanel expanded={this.props.categoryExpanded[2]}>
              <ExpansionPanelSummary
                onClick={() => {this.props.toggleCategoryExpanded(2)}}
                expandIcon={<ExpandMore />}
              >
                <Typography className={classes.heading}><DateRange />Ajankohta</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div>
                  <div style={{ display: 'flex', marginBottom: 6 }}>
                    <DatePicker
                      style={{ marginTop: 0 }}
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      label="Alkaen"
                      value={this.props.selectedStartDate}
                      onChange={date => {
                        this.props.setStartDate(moment(date))
                      }}
                    />
                    <div style={{
                      display: 'flex',
                      height: 35,
                      alignSelf: 'flex-end',
                      width: 70,
                      justifyContent: 'center'
                    }}>-</div>
                    <DatePicker
                      style={{ marginTop: 0 }}
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      label="Päättyen"
                      value={this.props.selectedEndDate}
                      onChange={date => {
                        this.props.setEndDate(date)
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex'}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Ajankohta
                        </InputLabel>
                        <Select
                          label="Ajankohta"
                          value={ 
                            (this.times.find(
                                time => time.startsAt ===
                                  moment.utc(this.props.selectedStartTime).hours()
                              ) || this.times[0]
                            ).startsAt
                          }
                          onChange={ e => {
                            const time = this.times.find(
                              time => time.startsAt === e.target.value
                            )
                            if ( time.startsAt >= 0 ) {
                              const startsAt = moment.utc({hours: time.startsAt, minutes: 0}).toDate()
                              const endsAt = moment.utc({hours: time.endsAt, minutes: 0}).toDate()
                              this.props.setTimeRange([startsAt, endsAt])
                            } else this.props.setTimeRange([null, null])
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          { this.times.map((value, i) =>
                              <MenuItem key={i} value={value.startsAt}>{value.label}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </CustomExpansionPanel>
            <CustomExpansionPanel expanded={this.props.categoryExpanded[3]}>
              <ExpansionPanelSummary
                onClick={() => {this.props.toggleCategoryExpanded(3)}}
                expandIcon={<ExpandMore />}
              >
                <Typography className={classes.heading}><People />Joukkueet</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ display: 'flex', flexFlow: 'column', width: '100%' }}>
                  <div style={{ display: 'flex', marginBottom: 15 }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Ilmoittajajoukkueen tyyppi
                        </InputLabel>
                        <Select
                          label="Ilmoittajajoukkueen tyyppi"
                          value={this.props.selectedType}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectType(e.target.value)
                            this.props.selectDivision(-1)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki tyypit</MenuItem>
                          { [...Array(3).keys()].map((value, i) =>
                              <MenuItem key={i} value={value}>{teamTypeToString(i)}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', marginBottom: 15  }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Ilmoittajajoukkueen sarjataso
                        </InputLabel>
                        <Select
                          label="Ilmoittajajoukkueen sarjataso"
                          value={this.props.selectedDivision}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectDivision(e.target.value)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki sarjatasot</MenuItem>
                          { divisions.map((value, i) =>
                              <MenuItem key={i} value={value}>{divisionToString(value)}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', marginBottom: 15 }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Haetun joukkueen tyyppi
                        </InputLabel>
                        <Select
                          label="Haetun joukkueen tyyppi"
                          value={this.props.selectedMyType}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectMyType(e.target.value)
                            this.props.selectMyDivision(-1)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki tyypit</MenuItem>
                          { [...Array(3).keys()].map((value, i) =>
                              <MenuItem key={i} value={value}>{teamTypeToString(i)}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Haetun joukkueen sarjataso
                        </InputLabel>
                        <Select
                          label="Haetun joukkueen sarjataso"
                          value={this.props.selectedMyDivision}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectMyDivision(e.target.value)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki sarjatasot</MenuItem>
                          { myDivisions.map((value, i) =>
                              <MenuItem key={i} value={value}>{divisionToString(value)}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </CustomExpansionPanel>       
            <CustomExpansionPanel expanded={this.props.categoryExpanded[4]}>
              <ExpansionPanelSummary
                onClick={() => {this.props.toggleCategoryExpanded(4)}}
                expandIcon={<ExpandMore />}
              >
                <Typography className={classes.heading}><MoreVert />Muut hakukriteerit</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ width: '100%'}}>
                  <div style={{ display: 'flex' }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Vuoro
                        </InputLabel>
                        <Select
                          label="Vuoro"
                          value={this.props.selectedTurn}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectTurn(e.target.value)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki vuorot</MenuItem>
                          { [...Array(3).keys()].map((value, i) =>
                              <MenuItem key={i} value={value}>{turnToString(i)}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{margin: "16px"}}></div>
                  <div style={{ display: 'flex' }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Kustannukset
                        </InputLabel>
                        <Select
                          label="Kustannukset"
                          value={this.props.selectedExpenseModel}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectExpenseModel(e.target.value)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki kustannusmallit</MenuItem>
                          { [...Array(5).keys()].map((value, i) =>
                              <MenuItem key={i} value={value * 25}>{expenseModelToString(i * 25)}</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{margin: "16px"}}></div>
                  <div style={{ display: 'flex' }}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel shrink>
                          Kenttä
                        </InputLabel>
                        <Select
                          label="Kenttä"
                          value={this.props.selectedEnvironment}
                          onChange={ e => {
                            this.redirectIfNotThere('/')
                            this.props.selectEnvironment(e.target.value)
                            window.scrollTo(0, 0)
                          }}
                          input={<Input />}
                          displayEmpty
                          className={classes.selectEmpty}
                        >
                          <MenuItem key={-1} value={-1}>Kaikki kentät</MenuItem>
                          { getEnvironmentsForSports(this.props.selectedSports, this.props.environments).map(environment =>
                              <MenuItem key={environment.id} value={environment.id}>{ environment.name }</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </CustomExpansionPanel>
          </MuiPickersUtilsProvider>
        </React.Fragment>
        )
    }

}

LeftDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return { 
    sports: state.static.sports,
    locations: state.static.locations,
    divisions: state.static.divisions,
    details: state.static.details,
    environments: state.static.environments,
    selectedSports: state.search.selectedSports,
    selectedOuterRegion: state.search.selectedOuterRegion,
    selectedInnerRegion: state.search.selectedInnerRegion,
    selectedStartDate: state.search.selectedStartDate,
    selectedEndDate: state.search.selectedEndDate,
    selectedStartTime: state.search.selectedStartTime,
    selectedEndTime: state.search.selectedEndTime,
    selectedType: state.search.selectedType,
    selectedDivision: state.search.selectedDivision,
    selectedMyType: state.search.selectedMyType,
    selectedMyDivision: state.search.selectedMyDivision,
    selectedTurn: state.search.selectedTurn,
    selectedExpenseModel: state.search.selectedExpenseModel,
    selectedLocations: state.search.selectedLocations,
    selectedDetail0: state.search.selectedDetail0,
    selectedDetail1: state.search.selectedDetail1,
    selectedEnvironment: state.search.selectedEnvironment,
    sortOrder: state.search.sortOrder,
    categoryExpanded: state.search.categoryExpanded
  };
};

const mapDispatchToProps = dispatch => ({
  toggleSport: id => {
    dispatch(toggleSport(id))
  },
  selectOuterRegion: id => {
    dispatch(selectOuterRegion(id))
  },
  selectInnerRegion: id => {
    dispatch(selectInnerRegion(id))
  },
  setStartDate: date => {
    dispatch(setStartDate(date))
  },
  setEndDate: date => {
    dispatch(setEndDate(date))
  },
  setStartTime: time => {
    dispatch(setStartTime(time))
  },
  setEndTime: time => {
    dispatch(setEndTime(time))
  },
  setTimeRange: range => {
    dispatch(setTimeRange(range))
  },
  selectTurn: turn => {
    dispatch(selectTurn(turn))
  },
  selectExpenseModel: expenseModel => {
    dispatch(selectExpenseModel(expenseModel))
  },
  selectType: type => {
    dispatch(selectType(type))
  },
  selectDivision: division => {
    dispatch(selectDivision(division))
  },
  selectMyType: type => {
    dispatch(selectMyType(type))
  },
  selectMyDivision: division => {
    dispatch(selectMyDivision(division))
  },
  setLocations: locations => {
    dispatch(setLocations(locations))
  },
  selectDetail0: detail0 => {
    dispatch(selectDetail0(detail0))
  },
  selectDetail1: detail1 => {
    dispatch(selectDetail1(detail1))
  },
  selectEnvironment: environment => {
    dispatch(selectEnvironment(environment))
  },
  toggleCategoryExpanded: i => {
    dispatch(toggleCategoryExpanded(i))
  }
})

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeftDrawer))
);
