import React, { Component } from 'react';
import { connect } from "react-redux";
import HtmlView from './HtmlView';
import { 
    BACKEND_URL,
    USER_TYPE_ADMIN,
    ARTICLES_PATH,
    ARTICLES_EDIT_PATH
} from '../const'
import axios from 'axios'

import {
    withWidth,
    Tooltip,
    IconButton
} from '@material-ui/core';

import {
    Edit,
    Delete
} from '@material-ui/icons';

class ArticleView extends Component {

    constructor(props){
        super(props)
        this.state = {
            metadata: null
        }
    }

    componentWillMount() {
        this.fetch(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.source !== nextProps.source ) {
            this.fetch(nextProps)
        }
    }

    redirectIfNotThere(path) {
        if ( this.props.history.location.pathname !== path ) {
            this.props.history.push(path)
        }
    }    

    fetch(props) {
        const headers = props.login.token 
            ? {Authorization: props.login.token} 
            : null
        axios.get(`${BACKEND_URL}/article/${props.source}/_metadata`, {headers})
            .then(res => {
                window.scrollTo(0, 0)
                this.setState({metadata: res.data})
            }).catch(err => {
                console.error(err)
            })
    }

    delete(props) {
        const headers = props.login.token 
            ? {Authorization: props.login.token} 
            : null
        axios.delete(`${BACKEND_URL}/article/${props.source}`, {headers})
            .then(res => {
                this.redirectIfNotThere(ARTICLES_PATH)
            }).catch(err => {
                console.error(err)
            })
    }

    render(){
        if ( this.state.metadata ) {
            return <HtmlView 
                topBarTools={ 
                    this.props.login.user && 
                    this.props.login.user.type === USER_TYPE_ADMIN &&
                        <React.Fragment>
                            <Tooltip title="Muokkaa artikkelia" aria-label="Muokkaa artikkelia">
                                <IconButton onClick={e => {
                                    this.redirectIfNotThere(
                                        ARTICLES_EDIT_PATH.replace(':id', this.state.metadata.source)
                                    )
                                }}>
                                    <Edit />
                                </IconButton> 
                            </Tooltip>
                            <Tooltip title="Poista artikkeli" aria-label="Poista artikkeli">
                                <IconButton onClick={e => {
                                    if ( window.confirm('Haluatko varmasti poistaa artikkelin?') ) {
                                        this.delete(this.props)
                                    }
                                }}>
                                    <Delete />
                                </IconButton> 
                            </Tooltip>
                        </React.Fragment>
                    }
                title={"Artikkeli"/*this.state.metadata.title*/}
                promptLogin={this.props.promptLogin}
                source={`${BACKEND_URL}/article/${this.state.metadata.source}`}
                history={this.props.history} 
                desktop={this.props.desktop} 
                margins={this.props.margins} 
                padding={this.props.padding} 
                topBarHeight={this.props.topBarHeight} />
        } else return null
    }

}

const mapStateToProps = state => ({
    login: state.login
})
const mapDispatchToProps = dispatch => ({})

export default withWidth()(
    connect(mapStateToProps, mapDispatchToProps)(ArticleView)
)