import React from 'react';
import { connect } from "react-redux";

import {
    BottomNavigation,
    BottomNavigationAction
} from '@material-ui/core';

import {
    Search,
    Person
} from '@material-ui/icons';

class BottomBar extends React.Component {

    render(){
        return (
            <BottomNavigation
                style={{ 
                    position: 'fixed', 
                    bottom: 0, 
                    width: '100%',
                    borderTop: '1px solid #eee'
                }}
                showLabels
            >
                <BottomNavigationAction label="Haku" 
                    style={{ maxWidth: '100%' }}
                    onClick={this.props.toggleLeft}
                    icon={<Search />} 
                />
                <BottomNavigationAction label="Tili" 
                    style={{ maxWidth: '100%' }}
                    icon={<Person />} 
                    onClick={this.props.toggleRight}
                />
            </BottomNavigation>
        )
    }

}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => ({
})
  
export default connect(mapStateToProps, mapDispatchToProps)(BottomBar);