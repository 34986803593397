import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';

import {
    withMobileDialog,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar
} from '@material-ui/core';

import { People } from '@material-ui/icons';

import {
    clearConfirmResponse,
    confirmResponse,
} from '../redux/account/actions'

import {
	CONFIRM_RESPONSE_INIT,
} from '../const'

import {
	teamTypeToString,
	divisionToString
} from '../utils';

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class ConfirmResponseDialog extends React.Component {

    constructor(props){
        super(props)
        this.state = {
			confirmResponseSelection: null
		}
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.account.confirmResponseState === null 
			&& nextProps.account.confirmResponseState === CONFIRM_RESPONSE_INIT ) {
			this.setState({confirmResponseSelection: null})
		}
		if ( this.props.account.responses !== nextProps.account.responses ) {
			const selected = nextProps.account.responses.find(response => response.status > 0)
			this.setState({confirmResponseSelection: selected ? selected.id : null})
		}
    }

    render() {
        return (
            <MobileDialog
                open={this.props.account.confirmResponseState !== null}
                onClose={e => {this.props.clearConfirmResponse()}}
            >
            <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                <List component="nav">
                    {this.props.account.responses
                    .filter(response => response.team != null)
                    .map(response => 
                        <ListItem color="primary" 
                            button key={ response.id }
                            selected={ this.state.confirmResponseSelection === response.id }
                            onClick={() => {
                                this.setState({
                                    confirmResponseSelection: this.state.confirmResponseSelection === response.id
                                        ? null 
                                        : response.id
                                })
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <People />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={ response.team.name } 
                            
                            secondary={
                                <span>
                                    {!response.refereeResponse && `${teamTypeToString(response.team.type)}, ${divisionToString(response.team.division)}`}
                                    {!response.refereeResponse && <br />}
                                    { moment(response.createdAt).format('DD.MM.YYYY HH:mm') }
                                </span>
                            }
    
                            />
                        </ListItem>
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Button 
                    disabled={ this.state.confirmResponseSelection === null }
                    onClick={e => {
                    this.props.confirmResponse(
                        this.props.login.token, 
                        this.props.account.confirmResponseAdId,
                        this.state.confirmResponseSelection,
                        this.props.account.selectedTeam
                    )
                }} color="primary" variant="contained">
                    Valitse
                </Button>
                <Button onClick={e => {this.props.clearConfirmResponse()}} color="primary">
                    Peruuta
                </Button>
            </DialogActions>
            </MobileDialog>
        )
    }

}

const mapStateToProps = state => {
    return { 
        regions: state.static.regions,
        divisions: state.static.divisions,
        account: state.account,
        login: state.login
    };
  };
  
const mapDispatchToProps = dispatch => ({
    confirmResponse: (token, adId, responseId, teamContext) => {
		dispatch(confirmResponse(token, adId, responseId, teamContext))
    },
    clearConfirmResponse: () => {
		dispatch(clearConfirmResponse())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmResponseDialog)