import { 
  LOGIN, 
  LOGOUT,
  LOGIN_FAILURE,
  CLEAR_LOGIN_FAILURE,
  BEGIN_RESET_PASSWORD,
  RESET_PASSWORD,
  CLEAR_RESET_PASSWORD,
  DO_RESET_PASSWORD,
  BEGIN_REGISTER,
  REGISTER,
  CLEAR_REGISTER,
  DO_REGISTER,
  SET_USER_CREDITS
} from "./types"

import { 
  NOT_LOGGED_IN,
  LOGGED_IN,
  NOT_LOGGED_IN_FAILURE,
  RESET_PASSWORD_INIT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE_404,
  RESET_PASSWORD_FAILURE_500,
  REGISTER_SUCCESS,
  REGISTER_FAILURE_409,
  REGISTER_FAILURE_500,
  DO_REGISTER_SUCCESS,
  DO_REGISTER_FAILURE,
  REGISTER_INIT
} from "../../const"

const initialState = {
  user: null,
  state: NOT_LOGGED_IN,
  token: null,
  resetPasswordState: null,
  doResetPasswordState: null,
  registerState: null,
  doRegisterState: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGIN: {
      const { token, user } = action.payload
      return {
        ...state,
        user: user,
        token: token,
        state: LOGGED_IN
      };
    }
    case SET_USER_CREDITS: {
      const { credits } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          credits
        }
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        user: null,
        token: null,
        state: NOT_LOGGED_IN_FAILURE
      };
    }
    case CLEAR_LOGIN_FAILURE: {
      return {
        ...state,
        user: state.user ? {...state.user } : null,
        token: state.token,
        state: NOT_LOGGED_IN
      }
    }
    case LOGOUT: {
      return {
        ...state,
        user: null,
        token: null,
        state: NOT_LOGGED_IN
      };
    }
    case BEGIN_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordState: RESET_PASSWORD_INIT
      }
    }
    case RESET_PASSWORD: {
      const { status } = action.payload
      return {
        ...state,
        resetPasswordState: status === 200 
          ? RESET_PASSWORD_SUCCESS
          : (
            status === 404
              ? RESET_PASSWORD_FAILURE_404
              : RESET_PASSWORD_FAILURE_500
          )
      }
    }
    case CLEAR_RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordState: null
      }
    }
    case DO_RESET_PASSWORD: {
      const { status } = action.payload
      return {
        ...state,
        doResetPasswordState: status === 200 
          ? RESET_PASSWORD_SUCCESS
          : (
            status === 404
              ? RESET_PASSWORD_FAILURE_404
              : RESET_PASSWORD_FAILURE_500
          )
      }
    }
    case BEGIN_REGISTER: {
      return {
        ...state,
        registerState: REGISTER_INIT
      }
    }
    case REGISTER: {
      const { status } = action.payload
      return {
        ...state,
        registerState: status === 200 
          ? REGISTER_SUCCESS
          : (
            status === 409
              ? REGISTER_FAILURE_409
              : REGISTER_FAILURE_500
          )
      }
    }
    case CLEAR_REGISTER: {
      return {
        ...state,
        registerState: null
      }
    }
    case DO_REGISTER: {
      const { status } = action.payload
      return {
        ...state,
        doRegisterState: status === 200 
          ? DO_REGISTER_SUCCESS
          : DO_REGISTER_FAILURE
      }
    }
    default:
      return state;
  }
}
