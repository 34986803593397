import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import {
  NOT_LOGGED_IN,
  LOGGED_IN,
  SEARCH_RESULT,
  OWN_ADS_PATH,
  OWN_RESPONSES_PATH,
  OWN_MATCHES_PATH,
  REPORT_PATH,
  USER_TYPE_ADMIN,
  PRIVACY_POLICY_PATH,
  ARTICLES_PATH,
  ARTICLES_MD_PATH,
  ARTICLES_NEW_PATH,
  ARTICLES_EDIT_PATH,
  PRICING_PATH,
  GUIDE_PATH,
  PROFILE_TYPE_REFEREE
} from '../const'

import { 
  logout,
  getUserCredits
} from '../redux/login/actions'

import { 
  beginEditTeam,
  selectTeam,
  getNotifications,
  getTeamAttribute,
  beginTransferCredits
} from '../redux/account/actions'

import { 
  searchOwnResponses,
  searchOwnAds,
  searchOwnMatches,
  redoSearch
} from '../redux/search/actions'

import {
  withWidth,
  Divider,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Radio,
  Link
} from '@material-ui/core';

import {
  ChevronLeft,
  Add,
  Launch,
  Close,
  PowerSettingsNew,
  AccountCircle,
  AccountBalance,
  InsertChart,
  Subject
} from '@material-ui/icons';

import LoginMenu from './LoginMenu'

const drawerWidth = 290;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    boxSizing: 'border-box',
    width: drawerWidth
  },
  drawerPaperPad: {
    boxSizing: 'border-box',
    padding: 0,
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: 100,
    backgroundColor: theme.palette.background.default,
  },
  button: {
    fontWeight: "normal"
  },
  avatar: {
    margin: 25,
    width: 140,
    height: 140,
    alignSelf: 'center'
  },
});

class RightDrawer extends React.Component {

  constructor(props){
    super(props)
    setInterval(() => {
      if ( this.props.login.state === LOGGED_IN ) {
        if ( this.props.account.selectedTeam != null ) {
          this.props.getNotifications(
            this.props.login.token,
            this.props.account.selectedTeam
          )
        }
        this.props.getUserCredits(
          this.props.login.token
        )
        this.props.account.teams.forEach(team => {
          this.props.getTeamAttribute(
            this.props.login.token,
            team.id,
            'credits'
          )
        })
      }
    }, 2500)
  }

  FAILURE_COLOR = "#f00";

  componentWillReceiveProps(nextProps) {
    if ( this.props.account.selectedTeam !== nextProps.account.selectedTeam
      && this.props.account.selectedTeam === null
      && nextProps.login.state === LOGGED_IN) {
      this.props.redoSearch(
        nextProps.search,
        nextProps.account.selectedTeam,
        nextProps.login.token
      )
    }
    if ( this.props.login.state !== nextProps.login.state 
      && nextProps.login.state === NOT_LOGGED_IN ) {
        this.props.redoSearch(
          {...nextProps.search,
            resultType: SEARCH_RESULT},
          null,
          nextProps.login.token
        )
      }
  }

  getSelectedTeamOrEmptyObject() {
    return this.props.account.teams.find(
      team => team.id === this.props.account.selectedTeam
    ) || {}
  }

  redirectIfNotThere(path) {
    if ( this.props.history.location.pathname !== path ) {
      this.props.history.push(path)
    }
  }
    
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Drawer position="fixed"
          id="RightDrawer"
          className={ classes.drawer }
          variant={ this.props.desktop ? "permanent" : "temporary" }
          open={this.props.parentStateRight}
          classes={{ paper: this.props.login.state === LOGGED_IN ? classes.drawerPaper : classes.drawerPaperPad }}
          onClose={this.props.toggle}
          anchor="right">
            { this.props.width === 'xs' &&
            <div>
              <IconButton
                color="inherit"
                onClick={this.props.toggle}
                edge="end"
                >
                  <Close />
              </IconButton>
            </div> }
            { this.login(classes) }
        </Drawer>
      </div>
      )
    }

  login(classes){

    return (
      <React.Fragment>
        { !(this.props.login.state === LOGGED_IN) && <LoginMenu /> }
        { this.props.login.state === LOGGED_IN &&
          <React.Fragment>
            <List component="nav">
              <ListItem color="primary" style={{ paddingRight: 0 }} button onClick={e => {
                }}>
                <ListItemIcon style={{ paddingLeft: 8, boxSizing: 'border-box'}}>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={ this.props.login.user.email } />
              </ListItem>
            </List>
            <Divider />
            <List component="nav">
              <ListItem color="primary" button onClick={e => {
                this.props.beginEditTeam(null)
              }}>
                <ListItemIcon style={{ paddingLeft: 8, boxSizing: 'border-box'}}>
                  <Add />
                </ListItemIcon>
                <ListItemText primary="Lisää profiili" />
              </ListItem>
              { this.props.account.teams.map(team => {
                return (
                  <ListItem key={team.id} style={{ paddingRight: 0 }} color="primary" button onClick={e => {
//                    this.redirectIfNotThere('/')
                    this.props.selectTeam(
                      team.id,
                      this.props.login.token
                    )
                    this.props.redoSearch(
                      this.props.search,
                      team.id,
                      this.props.login.token
                    )
                  }}>
                    <ListItemIcon>
                      <Radio color="primary" 
                        checked={ this.props.account.selectedTeam === team.id } 
                      />
                    </ListItemIcon>
                <ListItemText primary={ team.name } secondary={ 
                  <span style={{ display: 'flex'}}>
                    <span style={{ flex: 1 }}>

                      {team.profileType === PROFILE_TYPE_REFEREE ? 'Tuomari, ' : ''}{this.props.sports.find(sport => sport.id === team.sportId).name }
                    </span>
                    { team.credits > 0 &&
                      <Tooltip title="Krediitit" aria-label="Krediitit">
                        <span style={{ flex: 0, whiteSpace: 'nowrap', display: 'flex' }}>
                          <AccountBalance style={{ width: 13, height: 13, alignSelf: 'center', marginRight: 2 }} />
                          <span style={{ lineHeight: '13px', alignSelf: 'center' }}>{ team.credits }</span>
                        </span>
                      </Tooltip>                      
                    }
                    </span> } />
                    <ListItemIcon>
                      <Tooltip title="Avaa joukkuesivu" aria-label="Avaa joukkuesivu">
                        <IconButton onClick={e => {
                            this.redirectIfNotThere(`/team/${team.id}`)                          
                            this.props.toggle()
                            e.stopPropagation()
                          }}>
                          <Launch />
                        </IconButton>
                      </Tooltip>
                    </ListItemIcon>
                  </ListItem>
                )
              })}
            </List>
            <Divider />
            <List component="nav">
              <ListItem
                disabled={this.props.account.selectedTeam == null || this.getSelectedTeamOrEmptyObject().profileType === PROFILE_TYPE_REFEREE }
                selected={ this.props.path === OWN_ADS_PATH } 
                button 
                onClick={e => {
                  this.redirectIfNotThere(OWN_ADS_PATH)
                  this.props.toggle()
              }}>
                <ListItemIcon>
                  <ChevronLeft />
                </ListItemIcon>
                <ListItemText primary="Omat ilmoitukset" />
                <Badge style={{ right: '15px' }} color="secondary" badgeContent={this.props.account.adNotifications.length} className={classes.margin}>
                  <span />
                </Badge>
              </ListItem>
              <ListItem 
                disabled={this.props.account.selectedTeam == null}
                selected={ this.props.path === OWN_RESPONSES_PATH }
                onClick={e => {
                  this.redirectIfNotThere(OWN_RESPONSES_PATH)
                  this.props.toggle()
                }} button>
                <ListItemIcon>
                  <ChevronLeft />
                </ListItemIcon>
                <ListItemText primary="Omat vastaukset" />
                <Badge style={{ right: '15px' }} color="secondary" badgeContent={this.props.account.responseNotifications.length} className={classes.margin}>
                  <span />
                </Badge>
              </ListItem>
              <ListItem 
                disabled={this.props.account.selectedTeam == null}
                selected={ this.props.path === OWN_MATCHES_PATH }
                onClick={e => {
                  this.redirectIfNotThere(OWN_MATCHES_PATH)
                  this.props.toggle()
                }} button>
                <ListItemIcon>
                  <ChevronLeft />
                </ListItemIcon>
                <ListItemText primary="Omat ottelut" />
                <Badge style={{ right: '15px' }} color="secondary" badgeContent={this.props.account.matchNotifications.length} className={classes.margin}>
                  <span />
                </Badge>
              </ListItem>
              { ( ( this.props.account.teams && this.props.account.teams.reduce((sum, team) => sum += team.credits, 0) > 0 )
              || ( this.props.login.user && this.props.login.user.credits > 0 ) ) &&
              <React.Fragment>
              <div style={{ margin: "10px"}}></div>
              <Divider />
              <div style={{ margin: "10px"}}></div>
              <ListItem 
                    onClick={e => {
                      this.props.beginTransferCredits()
                    }} button>
                    <ListItemIcon>
                      <AccountBalance />
                    </ListItemIcon>
                    <ListItemText primary="Siirrä krediittejä" />
                  </ListItem>
              </React.Fragment>
              }
              {
                this.props.login.user.type === USER_TYPE_ADMIN &&
                <React.Fragment>
                  <div style={{ margin: "10px"}}></div>
                  <Divider />
                  <div style={{ margin: "10px"}}></div>
                  <ListItem 
                    selected={ 
                      this.props.path === ARTICLES_PATH ||
                      this.props.path === ARTICLES_MD_PATH ||
                      this.props.path === ARTICLES_NEW_PATH ||
                      this.props.path === ARTICLES_EDIT_PATH
                    }
                    onClick={e => {
                      this.redirectIfNotThere(ARTICLES_PATH)
                      this.props.toggle()
                    }} button>
                    <ListItemIcon>
                      <Subject />
                    </ListItemIcon>
                    <ListItemText primary="Artikkelit" />
                  </ListItem>
                  <ListItem 
                    selected={ this.props.path === REPORT_PATH }
                    onClick={e => {
                      this.redirectIfNotThere(REPORT_PATH.replace(':entity', 'user'))
                      this.props.toggle()
                    }} button>
                    <ListItemIcon>
                      <InsertChart />
                    </ListItemIcon>
                    <ListItemText primary="Käyttäjätiedot" />
                  </ListItem>
                </React.Fragment>
              }
              <div style={{ margin: "10px"}}></div>
              <Divider />
              <div style={{ margin: "10px"}}></div>

              <ListItem button onClick={e => {
                this.redirectIfNotThere('/')
                this.props.doLogout(this.props.login.token)
              }}>
                <ListItemIcon>
                  <PowerSettingsNew />
                </ListItemIcon>
                <ListItemText primary="Kirjaudu ulos" />
              </ListItem>
            </List>
          </React.Fragment>
        }
        <div style={{
          flex: 1,
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          paddingTop: 30,
          paddingBottom: 6
        }}>
       <div style={{ display: 'flex', flexFlow: 'column' }}>

        <div>
          <Link style={{ marginBottom: 3, color: '#888' }} 
            component="button" 
            variant="body2" 
            onClick={e => {
              this.redirectIfNotThere(PRICING_PATH)
              this.props.toggle()
            }}>
              Hinnat
          </Link>
          <span style={{ marginBottom: 3, color: '#888' }}> | </span>
          <Link style={{ marginBottom: 3, color: '#888' }} 
            component="button" 
            variant="body2" 
            onClick={e => {
              this.redirectIfNotThere(GUIDE_PATH)
              this.props.toggle()
            }}>
              Ohjeet
          </Link>
          <span style={{ marginBottom: 3, color: '#888' }}> | </span>
          <Link style={{ marginBottom: 3, color: '#888' }} 
            component="button" 
            variant="body2" 
            onClick={e => {
              this.redirectIfNotThere(ARTICLES_PATH)
              this.props.toggle()
            }}>
              Artikkelit
          </Link>
        </div>

          <Link style={{ marginBottom: 3, color: '#888' }} 
            component="button" 
            variant="body2" 
            onClick={e => {
              this.redirectIfNotThere(PRIVACY_POLICY_PATH)
              this.props.toggle()
            }}>
              Tietosuojaseloste
          </Link>

            <a href="mailto:info@harkkamatsi.fi" style={{ textAlign: 'center' }}>
                        <Link style={{ fontSize: '.7em', padding: 3, color: 'green', opacity: .75 }} 
            component="button"
            variant="body2" >
              info@harkkamatsi.fi
          </Link>
          </a>


          </div>
        </div>
      </React.Fragment>
      )
  }

}

RightDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    search: state.search,
    login: state.login,
    account: state.account,
    sports: state.static.sports
  };
};

const mapDispatchToProps = dispatch => ({
  doLogout: token => {
    dispatch(logout(token))
  },
  searchOwnResponses: (sortOrder, teamContext, token) => {
    dispatch(searchOwnResponses(sortOrder, teamContext, token))
  },
  searchOwnAds: (sortOrder, teamContext, token) => {
    dispatch(searchOwnAds(sortOrder, teamContext, token))
  },
  searchOwnMatches: (search, teamContext, token) => {
    dispatch(searchOwnMatches(search, teamContext, token))
  },
  redoSearch: (search, teamContext, token) => {
    dispatch(redoSearch(search, teamContext, token))
  },
  beginEditTeam: teamId => {
    dispatch(beginEditTeam(teamId))
  },
  selectTeam: (teamId, token) => {
    dispatch(selectTeam(teamId, token))
  },
  getNotifications: (token, teamId) => {
    dispatch(getNotifications(token, teamId))
  },
  getTeamAttribute: (token, teamId, attribute) => {
    dispatch(getTeamAttribute(token, teamId, attribute))
  },
  beginTransferCredits: () => {
    dispatch(beginTransferCredits())
  },
  getUserCredits: token => {
    dispatch(getUserCredits(token))
  }
})

export default withWidth()(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RightDrawer))
);