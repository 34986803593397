import React from 'react';
import { connect } from "react-redux";
import moment from 'moment';

import {
    withMobileDialog,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    TextField,
    Chip,
    Input
} from '@material-ui/core';

import {
    Autocomplete
} from '@material-ui/lab';

import { Delete } from '@material-ui/icons';

import DateFnsUtils from '@date-io/date-fns';
import fiLocale from "date-fns/locale/fi";

import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';

import {
	beginEditAd,
	deleteAd,
	editAd,
	clearEditAd,
} from '../redux/account/actions'

import {
	EDIT_AD_INIT,
	EDIT_AD_FAILURE
} from '../const'

import {
	divisionToString,
	expenseModelToString,
    turnToString,
    teamTypeToString,
    durationToString,
    getNumberOfDivisions,
    getEnvironmentsForSports
} from '../utils';

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(Dialog);
class EditAdDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
			editAdInnerRegions: []
        }
    }

    getSelectedTeam(props) {
		return props.account.teams.find(team => team.id === props.account.selectedTeam) || {}
	}

	getTeamById(props, id) {
		return props.account.teams.find(team => team.id === id) || {}
	}

	emptyAd(props) {
        const dateTime = moment().add(1, 'week')
		return {
			editAdTeamId: props.account.selectedTeam,
            editAdDivision: this.getSelectedTeam(props).division,
            editAdDivisions: [this.getSelectedTeam(props).division],
            editAdType: this.getSelectedTeam(props).type,
			editAdOuterRegion: props.regions[0].id,
			editAdInnerRegions: props.regions[0].innerRegions,
			editAdInnerRegion: -1,
			editAdDateTime: dateTime.toDate(),
			editAdDuration: 60,
			editAdExpenseModel: 0,
            editAdTurn: 0,
            editAdLocation: this.props.locations.find(location => location.name === 'Helsinki').id,
            editAdDetail0: -1,
            editAdDetail1: -1,
            editAdEnvironment: -1,
            editAdExpires: moment(dateTime).subtract(1, 'days').set({hours: 23, minutes: 59, seconds: 0}).toDate()
		}
	}

    componentWillReceiveProps(nextProps) {
		if ( this.props.account.editAdState === null 
			&& nextProps.account.editAdState === EDIT_AD_INIT ) {
			  if ( nextProps.account.editAdId === null ) {
				this.setState({...this.emptyAd(nextProps)})
			  } else {
				const ad = this.props.ads.find(ad => ad.id === nextProps.account.editAdId)
				if ( ad ) {
					this.setState({
						editAdTeamId: ad.teamId,
                        editAdDivision: ad.yourDivision,
                        editAdDivisions: ad.opponentDivisions.map(division => division.id),
                        editAdType: ad.yourType,
						editAdOuterRegion: ad.innerRegion.outerRegionId,
						editAdInnerRegions: nextProps.regions.find(
                                region => region.id === ad.innerRegion.outerRegionId
                            ).innerRegions,
						editAdInnerRegion: ad.innerRegionId,
						editAdDateTime: new Date(ad.date),
						editAdDuration: ad.duration,
						editAdExpenseModel: ad.expenseModel,
                        editAdTurn: ad.turn,
                        editAdLocation: ad.location.id,
                        editAdDetail0: ad.detail0Id,
                        editAdDetail1: ad.detail1Id,
                        editAdEnvironment: ad.environmentId,
                        editAdExpires: ad.expires
					})	
				} else {
					//this.setState({...this.emptyAd(nextProps)})
				}
			  }
		}
    }

    render() {
        const team = this.getSelectedTeam(this.props)
        const numberOfDivisions = getNumberOfDivisions(
            this.props.sports, 
            team.sportId,
            this.state.editAdType
        )
        const checkTeamDivisions = () => {
            const n = getNumberOfDivisions(
                this.props.sports, 
                team.sportId,
                this.state.editAdType
            )
            if ( n <= this.state.editAdDivision ) {
                this.setState({editAdDivision: 999})
            }
            this.setState({
                editAdDivisions: this.state.editAdDivisions.filter(division => 
                    n > division || division === 999
                )})
        }

        return (
        <MobileDialog
            open={this.props.account.editAdState !== null}
            onClose={e => {this.props.clearEditAd()}}
        >
        <DialogTitle>{
            this.props.account.editAdId === null 
            ? 'Jätä uusi ilmoitus'
            : 'Muokkaa ilmoitusta'
        }</DialogTitle>
        <DialogContent>
            {
            this.props.account.editAdId === null &&
                <DialogContentText>
                Syötä ilmoituksen tiedot.
                </DialogContentText>
            }
            <FormControl style={{ display: 'none', marginBottom: 16 }}>
                <InputLabel shrink>
                    Joukkue
                </InputLabel>
                <Select
                    disabled
                    value={this.state.editAdTeamId  || null}
                    onChange={e => {this.setState({editAdTeamId: e.target.value})}}
                    displayEmpty
                >
                    { this.props.account.teams.map(team => (
                        <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl style={{ display: 'none', marginBottom: 16 }}>
                <InputLabel shrink>
                    Laji
                </InputLabel>
                <Select
                    disabled
                    value={this.getTeamById(this.props, this.state.editAdTeamId).sportId}
                    onChange={e => {this.setState({editAdSport: e.target.value})}}
                    displayEmpty
                >
                    { this.props.sports.map(sport => (
                        <MenuItem key={sport.id} value={sport.id}>{sport.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div style={{ display: 'flex', marginBottom: 16 }}>
            <FormControl style={{ paddingRight: 5 }}>
                <InputLabel shrink>
                    Vastustajan tyyppi
                </InputLabel>
                <Select
                    value={this.state.editAdType}
                    onChange={e => {this.setState({
                        editAdType: e.target.value
                    }, checkTeamDivisions)}}
                    displayEmpty
                >
                    { [...Array(3).keys()].map(type =>
                        <MenuItem key={type} value={type}>{ teamTypeToString(type) }</MenuItem>
                    )}
                </Select>
            </FormControl>
            <FormControl style={{ marginLeft: 5 }}>
                <InputLabel shrink>
                    Vastustajan sarjataso
                </InputLabel>
                {/*
                <Select
                    value={this.state.editAdDivision}
                    onChange={e => {this.setState({editAdDivision: e.target.value})}}
                    displayEmpty
                >
                    { [...Array(numberOfDivisions).keys(), 999].reverse().map(division =>
                        <MenuItem key={division} value={division}>{ divisionToString(division) }</MenuItem>
                    )}
                </Select>
                */}
                <Select
                    multiple
                    value={this.state.editAdDivisions}
                    onChange={e => {this.setState({editAdDivisions: e.target.value})}}
                    displayEmpty
                    input={<Input style={{ maxHeight: 31, overflow: 'hidden' }}/>}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip style={{ marginRight: 2 }} key={value} label={divisionToString(value)} />
                        ))}
                      </div>
                    )}
                >
                    { [...Array(numberOfDivisions).keys(), 999].reverse().map(division =>
                        <MenuItem key={division} value={division}>{ divisionToString(division) }</MenuItem>
                    )}
                </Select>
            </FormControl>
            </div>
            <Autocomplete
                options={this.props.locations}
                getOptionLabel={option => option.name}
                clearOnEscape
                noOptionsText="Ei sijainteja"
                renderInput={(params) => <TextField {...params} label="Sijainti" />}
                value={this.props.locations.find(location => location.id === this.state.editAdLocation)}
                onChange={(e, newValue) => {
                    if ( newValue ) this.setState({editAdLocation: newValue.id})
                }}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                <div style={{ display: 'flex', marginBottom: 8 }}>
                    <DatePicker
                        autoOk
                        disablePast
                        minDateMessage="Päivämäärä ei saa olla menneisyydessä."
                        style={{ paddingRight: 5 }}
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        label="Ottelun ajankohta"
                        value={this.state.editAdDateTime}
                        onChange={date => {
                            this.setState({editAdDateTime: date})
                        }}
                    />
                    <TimePicker
                        autoOk
			            variant="inline"
                        disablePast
                        minDateMessage=" "
                        style={{ marginLeft: 5 }}
                        ampm={false}
                        margin="normal"
                        id="time-picker"
                        label=" "
                        value={this.state.editAdDateTime}
                        onChange={date => {
                            this.setState({editAdDateTime: date})
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            <FormControl style={{ marginBottom: 16 }}>
                <InputLabel>
                    Kesto
                </InputLabel>
                <Select
                    value={this.state.editAdDuration}
                    onChange={e => {
                        this.setState({editAdDuration: e.target.value})
                    }}
                    displayEmpty
                >
                    {[30,45,60,90,120].map(duration =>
                        <MenuItem key={duration}value={duration}>{ durationToString(duration) }</MenuItem>
                    )}
                </Select>
            </FormControl>
                <FormControl style={{ marginBottom: 16 }}>
                    <InputLabel>
                        Vuoro
                    </InputLabel>
                    <Select
                        value={this.state.editAdTurn}
                        onChange={e => {
                            this.setState({editAdTurn: e.target.value})
                        }}
                        displayEmpty
                    >
                        {[...Array(3).keys()].map(turn =>
                            <MenuItem key={turn} value={turn}>{ turnToString(turn) }</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl style={{ marginBottom: 16 }}>
                    <InputLabel>
                        Kustannukset
                    </InputLabel>
                    <Select
                        value={this.state.editAdExpenseModel}
                        onChange={e => {
                            this.setState({editAdExpenseModel: e.target.value})
                        }}
                        displayEmpty
                    >
                        {[0,25,50,75,100].map(expenseModel =>
                            <MenuItem key={expenseModel} value={expenseModel}>{ expenseModelToString(expenseModel) }</MenuItem>
                        )}
                    </Select>
                </FormControl>
            <div style={{ display: 'flex' }}>
                {/*
                <FormControl style={{ marginBottom: 16 }}>
                    <InputLabel>
                        Kenttätyyppi
                    </InputLabel>
                    <Select
                        value={this.state.editAdDetail0 || -1}
                        onChange={e => {
                            this.setState({editAdDetail0: e.target.value})
                        }}
                        displayEmpty
                    >
                        <MenuItem key={''} value={-1}>-</MenuItem>
                        {this.props.details.filter(detail => detail.class === 0).map(detail =>
                            <MenuItem key={detail.id} value={detail.id}>{ detail.name }</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <FormControl style={{ marginLeft: 5 }}>
                    <InputLabel>
                        Alusta
                    </InputLabel>
                    <Select
                        value={this.state.editAdDetail1 || -1}
                        onChange={e => {
                            this.setState({editAdDetail1: e.target.value})
                        }}
                        displayEmpty
                    >
                        <MenuItem key={''} value={-1}>-</MenuItem>
                        {this.props.details.filter(detail => detail.class === 1).map(detail =>
                            <MenuItem key={detail.id} value={detail.id}>{ detail.name }</MenuItem>
                        )}
                    </Select>
                </FormControl>
                        */ }
                <FormControl>
                    <InputLabel>
                        Kenttä
                    </InputLabel>
                    <Select
                        value={this.state.editAdEnvironment || -1}
                        onChange={e => {
                            this.setState({editAdEnvironment: e.target.value})
                        }}
                        displayEmpty
                    >
                        <MenuItem key={''} value={-1}>-</MenuItem>
                        {getEnvironmentsForSports([team.sportId], this.props.environments).map(environment =>
                            <MenuItem key={environment.id} value={environment.id}>{environment.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale}>
                <div style={{ display: 'flex', marginBottom: 8 }}>
                    <DatePicker
                        autoOk
                        maxDate={moment(this.state.editAdDateTime).subtract(1, 'day').toDate()}
                        maxDateMessage="Täytyy olla viimeistään ottelua edeltävä päivä."
                        style={{ paddingRight: 5 }}
                        disableToolbar
                        variant="inline"
                        format="dd.MM.yyyy"
                        margin="normal"
                        label="Ilmoitus vanhenee"
                        value={this.state.editAdExpires}
                        onChange={date => {
                            this.setState({editAdExpires: date})
                        }}
                    />
                    <TimePicker
                        autoOk
                        maxDate={moment(this.state.editAdDateTime).subtract(1, 'day').toDate()}
                        maxDateMessage=" "
			            variant="inline"
                        style={{ marginLeft: 5 }}
                        ampm={false}
                        label=" "
                        margin="normal"
                        id="time-picker"
                        value={this.state.editAdExpires}
                        onChange={date => {
                            this.setState({editAdExpires: date})
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            { this.props.account.editAdState === EDIT_AD_FAILURE &&
                <DialogContentText style={{ 
                    marginBottom: 0, 
                    marginTop: 16, 
                    color: 'red', 
                    fontSize: '.8em' 
                }}>
                    Palvelinvirhe. Yritä myöhemmin uudestaan.
                </DialogContentText>
            }
        </DialogContent>
        <DialogActions>
            <div style={{ flex: 1, paddingLeft: 14, height: 48 }}>
            { this.props.account.editAdId !== null &&
                <Tooltip title="Poista ilmoitus" aria-label="Poista ilmoitus">
                    <IconButton onClick={() => {
                        if ( window.confirm('Oletko varma, että haluat poistaa ilmoituksen? Kaikki ilmoitukseen liittyvät tiedot hävitetään.') ) {
                            this.props.deleteAd(
                            this.props.login.token,
                            this.props.account.editAdId
                            )
                        }
                    }}>
                        <Delete />
                    </IconButton> 
                </Tooltip>
            }
            </div>
            <Button 
                disabled={ !this.state.editAdDivisions || !this.state.editAdDivisions.length }
                onClick={e => {
                this.props.editAd(
                    this.props.login.token, 
                    this.state.editAdTeamId, 
                    this.state.editAdDivision, 
                    this.state.editAdDivisions, 
                    this.state.editAdOuterRegion, 
                    this.state.editAdInnerRegion, 
                    moment(this.state.editAdDateTime).toISOString(), 
                    this.state.editAdDuration, 
                    this.state.editAdTurn, 
                    this.state.editAdExpenseModel,
                    this.state.editAdType, 
                    this.state.editAdLocation,
                    this.state.editAdDetail0,
                    this.state.editAdDetail1,
                    this.state.editAdEnvironment,
                    this.state.editAdExpires,
                    this.props.account.editAdId
                )
            }} color="primary" variant="contained">
                Tallenna
            </Button>
            <Button onClick={e => {this.props.clearEditAd()}} color="primary">
                Peruuta
            </Button>
        </DialogActions>
        </MobileDialog>)
    }

}

const mapStateToProps = state => {
    return { 
        ads: state.search.results,
        regions: state.static.regions,
        locations: state.static.locations,
        sports: state.static.sports,
        details: state.static.details,
        environments: state.static.environments,
        login: state.login,
        account: state.account
    }
  };
  
const mapDispatchToProps = dispatch => ({
	beginEditAd: adId => {
		dispatch(beginEditAd(adId))
	},
	clearEditAd: () => {
		dispatch(clearEditAd())
	},
	editAd: (token, teamId, division, divisions, outerRegionId, innerRegionId, dateTime, duration, turn, expenseModel, type, location, detail0, detail1, environment, expires, adId=null) => {
		dispatch(editAd(token, teamId, division, divisions, outerRegionId, innerRegionId, dateTime, duration, turn, expenseModel, type, location, detail0, detail1, environment, expires, adId))
    },
	deleteAd: (token, adId) => {
		dispatch(deleteAd(token, adId))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(EditAdDialog)
