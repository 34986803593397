import axios from 'axios'

import { GET_TEAM } from './types'

import { BACKEND_URL } from '../../const'

export const getTeam = (id, token) => {
    const headers = token 
        ? {Authorization: token} 
        : null

    return dispatch => {
        axios.get(`${BACKEND_URL}/team/${id}`, {headers})
            .then(res => {
                dispatch({
                    type: GET_TEAM,
                    payload: {
                        data: res.data,
                        status: 200
                    }
                })
            }).catch(err => {
                dispatch({
                    type: GET_TEAM,
                    payload: {
                        data: null,
                        status: err.response   
                            ? err.response.status
                            : 500
                    }
                })
                console.error(err)
            })
    }
}
