export const TOGGLE_SPORT = "TOGGLE_SPORT"
export const SELECT_SPORTS = "SELECT_SPORTS"
export const SELECT_OUTER_REGION = "SELECT_OUTER_REGION"
export const SELECT_INNER_REGION = "SELECT_INNER_REGION"
export const SEARCH = "SEARCH"
export const SEARCH_TO_PATCH = "SEARCH_TO_PATCH"
export const TOGGLE_RESPONSE = "TOGGLE_RESPONSE"
export const TOGGLE_REFEREE_REQUEST = "TOGGLE_REFEREE_REQUEST"
export const REMOVE_FROM_RESULTS = "REMOVE_FROM_RESULTS"
export const SET_START_DATE = "SET_START_DATE"
export const SET_END_DATE = "SET_END_DATE"
export const SET_START_TIME = "SET_START_TIME"
export const SET_END_TIME = "SET_END_TIME"
export const SET_TIME_RANGE = "SET_TIME_RANGE"
export const SELECT_TYPE = "SELECT_TYPE"
export const SELECT_DIVISION = "SELECT_DIVISION"
export const SELECT_MY_TYPE = "SELECT_MY_TYPE"
export const SELECT_MY_DIVISION = "SELECT_MY_DIVISION"
export const SELECT_TURN = "SELECT_TURN"
export const SELECT_EXPENSE_MODEL = "SELECT_EXPENSE_MODEL"
export const TOGGLE_CATEGORY_EXPANDED = "TOGGLE_CATEGORY_EXPANDED"
export const SET_LOCATIONS = "SET_LOCATIONS"
export const SET_SORT_ORDER = "SET_SORT_ORDER"
export const SET_FILTER = "SET_FILTER"
export const SELECT_DETAIL_0 = "SELECT_DETAIL_0"
export const SELECT_DETAIL_1 = "SELECT_DETAIL_1"
export const SELECT_ENVIRONMENT = "SELECT_ENVIRONMENT"