import { GET_TEAM } from './types'

const { 
  UNDEFINED_VIEW
} = require('../../const')

const initialState = {
    data: {},
    type: UNDEFINED_VIEW,
    status: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TEAM: {
      const { data } = action.payload
      return {
        ...state,
        data
      }
    }
    default:
      return state;
  }
}