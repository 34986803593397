import { 
    CLEAR_STATE,
    ACCEPT_COOKIES
} from './types'

export const clearState = () => ({
    type: CLEAR_STATE
})

export const acceptCookies = () => ({
    type: ACCEPT_COOKIES
})