import { combineReducers } from "redux";
import staticData from './static/reducers';
import search from './search/reducers';
import login from './login/reducers';
import account from './account/reducers';
import app from './app/reducers';
import view from './view/reducers';

export default combineReducers({ 
    static: staticData,
    search,
    login,
    account,
    app,
    view
});
