import axios from 'axios';
import { BACKEND_URL } from '../../const'

import { getTeams } from '../account/actions';

import { 
    LOGIN, 
    LOGIN_FAILURE,
    CLEAR_LOGIN_FAILURE,
    LOGOUT,
    BEGIN_RESET_PASSWORD,
    RESET_PASSWORD,
    CLEAR_RESET_PASSWORD,
    DO_RESET_PASSWORD,
    BEGIN_REGISTER,
    REGISTER,
    CLEAR_REGISTER,
    DO_REGISTER,
    SET_USER_CREDITS
} from "./types";

const loginSuccess = login => ({
    type: LOGIN,
    payload: login
})

const loginFailure = err => ({
    type: LOGIN_FAILURE
})

export const login = (email, password) => {
    return dispatch => {
        axios.post(`${BACKEND_URL}/auth`, {
                email,
                password
            }).then(res => {
                const token = res.data.token
                dispatch(loginSuccess(res.data))
                dispatch(getTeams(token))
            }).catch(err => {
                console.error(err)
                dispatch(loginFailure(err))
            })
    }
}

export const googleLogin = code => {
    return dispatch => {
        axios.post(`${BACKEND_URL}/auth/google`, {
                code
            }).then(res => {
                const token = res.data.token
                dispatch(loginSuccess(res.data))
                dispatch(getTeams(token))
            }).catch(err => {
                console.error(err)
                dispatch(loginFailure(err))
            })
    }
}

export const facebookLogin = token => {
    return dispatch => {
        axios.post(`${BACKEND_URL}/auth/facebook`, {
                token
            }).then(res => {
                const token = res.data.token
                dispatch(loginSuccess(res.data))
                dispatch(getTeams(token))
            }).catch(err => {
                console.error(err)
                dispatch(loginFailure(err))
            })
    }
}

export const getUserCredits = token => {
    return dispatch => {
        const headers = token 
            ? {Authorization: token} 
            : null
        axios.get(`${BACKEND_URL}/credits`, {headers})
            .then(res => {
                dispatch({
                    type: SET_USER_CREDITS,
                    payload: res.data
                })
            }).catch(err => {
                console.error(err)
            })
    }
}

export const clearLoginFailure = () => ({
    type: CLEAR_LOGIN_FAILURE
})

export const logout = token => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        axios.delete(`${BACKEND_URL}/auth`, {headers})
            .finally(() => {
                dispatch({
                    type: LOGOUT
                })
            })
    }
}

export const beginResetPassword = () => ({
    type: BEGIN_RESET_PASSWORD
})

export const resetPassword = email => {
    return dispatch => {
        return axios.post(`${BACKEND_URL}/reset-password`, {
            email
        }).then(res => {
            dispatch({
                type: RESET_PASSWORD,
                payload: {status: 200}
            })
        }).catch(err => {
            dispatch({
                type: RESET_PASSWORD,
                payload: {status: 
                    err.response
                        ? err.response.status
                        : 500 
                }
            })
        })
    }
}

export const clearResetPassword = () => ({
    type: CLEAR_RESET_PASSWORD
})

export const doResetPassword = (password, token) => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        return axios.post(`${BACKEND_URL}/reset-password/auth`, {password}, {headers})
            .then(res => {
                dispatch({
                    type: DO_RESET_PASSWORD,
                    payload: { status: 200 }
                })
            }).catch(err => {
                dispatch({
                    type: DO_RESET_PASSWORD,
                    payload: {status: 
                        err.response
                            ? err.response.status
                            : 500 
                    }
               })
            })
    }    
}

export const beginRegister = () => ({
    type: BEGIN_REGISTER
})

export const register = (email, password) => {
    return dispatch => {
        return axios.post(`${BACKEND_URL}/register`, {email, password})
            .then(res => {
                dispatch({
                    type: REGISTER,
                    payload: { status: 200 }
                })
            }).catch(err => {
                dispatch({
                    type: REGISTER,
                    payload: {status: 
                        err.response
                            ? err.response.status
                            : 500 
                    }
                })
            })
    }    
}

export const clearRegister = () => ({
    type: CLEAR_REGISTER
})

export const doRegister = token => {
    const headers = token 
        ? {Authorization: token} 
        : null
    return dispatch => {
        return axios.patch(`${BACKEND_URL}/register`, null, {headers})
            .then(res => {
                dispatch({
                    type: DO_REGISTER,
                    payload: { status: 200 }
                })
                dispatch(loginSuccess(res.data))
                dispatch(getTeams(res.data.token))
            }).catch(err => {
                dispatch({
                    type: DO_REGISTER,
                    payload: {status: 
                        err.response
                            ? err.response.status
                            : 500 
                    }
                })
            })
    }    
}