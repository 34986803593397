import React from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import {
    SEARCH_RESULT,
    SEARCH_OWN_RESPONSES_RESULT,
    SEARCH_OWN_ADS_RESULT,
    SEARCH_OWN_MATCHES_RESULT,
    SEARCH_AD_BY_ID_RESULT,
    SORT_ORDER_ASCENDING,
    SORT_ORDER_DESCENDING,
    OWN_MATCHES_FILTER_ALL,
    OWN_MATCHES_FILTER_PAST,
    OWN_MATCHES_FILTER_UPCOMING
} from '../const'

import {
    setSortOrder,
    setFilter
} from '../redux/search/actions'

import {
    IconButton,
    Tooltip,
    Menu,
    MenuItem
} from '@material-ui/core';

import { 
    Sort,
    FilterList,
    Check
} from '@material-ui/icons';

const styles = theme => ({
    root: {
      width: '100%',
    },
    grow: {
      flexGrow: 1,
    },
  });  

const logo = "logo.png";

class TopBar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        filterMenuAnchorEl: null,
        sortMenuAnchorEl: null
    }
  }

  redirectIfNotThere(path) {
    if ( this.props.history.location.pathname !== path ) {
      this.props.history.push(path)
    }
  }

  render() {
    return (
        <div style={{
            position: this.props.desktop ? 'static' : 'fixed',
            top: 0,
            left: this.props.left,
            height: this.props.height,
            width: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexFlow: 'row',
            zIndex: 1,
            boxShadow: 'rgba(0, 0, 0, 0.05) 2px 2px 2px 2px'
        }}>
            { !this.props.desktop &&
                <div style={{ 
                    cursor: 'pointer',
                    alignSelf: 'flex-end',
                    height: '100%',
                    width: 100,
                    backgroundImage: `url("/images/${logo}")`,
                    backgroundSize: 'contain',
                    borderRadius: '0% 0% 0 20%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    padding: '10px 20px 10px 0',
                    position: 'relative',
                    top: 10,
                    left: this.props.desktop ? 10 : 0
                }}
                onClick={() => {this.redirectIfNotThere('/')}}
                ></div>
            }
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flex: this.props.desktop ? 1 : 0,
                fontFamily: 'Arial',
                fontSize: 20,
                paddingLeft: this.props.desktop ? 15 : 0,
                color: '#222',
                whiteSpace: 'nowrap',
                position: this.props.desktop ? 'relative' : 'absolute',
                width: this.props.desktop ? 'auto' : '100%',
                height: this.props.desktop ? 'auto' : '100%',
                justifyContent: this.props.desktop ? 'flex-start' : 'center',
                pointerEvents: 'none',
            }}>
                { 
                    ( this.props.title && this.props.title ) ||
                    ( this.props.searchResultType === SEARCH_RESULT && 'Hakutulokset' ) ||
                    ( this.props.searchResultType === SEARCH_OWN_RESPONSES_RESULT && 'Omat vastaukset' ) ||
                    ( this.props.searchResultType === SEARCH_OWN_ADS_RESULT && 'Omat ilmoitukset' ) ||
                    ( this.props.searchResultType === SEARCH_OWN_MATCHES_RESULT && 'Omat ottelut' ) ||
                    ( this.props.searchResultType === SEARCH_AD_BY_ID_RESULT && 'Ilmoitus' )
                }
            </div>
            <div style={{ 
                display: 'flex', 
                alignItems: 'center',
                paddingLeft: 10, 
                paddingRight: 10,
                justifyContent: this.props.desktop ? 'flex-start' : 'flex-end',
                flex: this.props.desktop ? 0 : 1, 
            }}>
                { this.props.tools && this.props.tools }
                { !this.props.tools && !this.props.title && this.props.searchResultType === SEARCH_OWN_MATCHES_RESULT &&
                    <React.Fragment>
                        <Tooltip title="Suodata" aria-label="Suodata">
                            <IconButton onClick={e => {
                                this.setState({filterMenuAnchorEl: e.currentTarget})
                            }}>
                                <FilterList />
                            </IconButton> 
                        </Tooltip>
                        <Menu
                            anchorEl={this.state.filterMenuAnchorEl}
                            open={Boolean(this.state.filterMenuAnchorEl)}
                            onClose={() => {this.setState({filterMenuAnchorEl: null})}}
                        >
                            <MenuItem onClick={() => {
                                this.props.setFilter(OWN_MATCHES_FILTER_ALL)
                                this.setState({filterMenuAnchorEl: null})
                            }}>
                                <Check style={{ marginRight: 5, visibility: this.props.filter === OWN_MATCHES_FILTER_ALL ? 'visible' : 'hidden' }} />Kaikki ottelut
                            </MenuItem>
                            <MenuItem onClick={() => {
                                this.props.setFilter(OWN_MATCHES_FILTER_PAST)
                                this.setState({filterMenuAnchorEl: null})
                            }}>
                                <Check style={{ marginRight: 5, visibility: this.props.filter === OWN_MATCHES_FILTER_PAST ? 'visible' : 'hidden' }} />Menneet ottelut
                            </MenuItem>
                            <MenuItem onClick={() => {
                                this.props.setFilter(OWN_MATCHES_FILTER_UPCOMING)
                                this.setState({filterMenuAnchorEl: null})
                            }}>
                                <Check style={{ marginRight: 5, visibility: this.props.filter === OWN_MATCHES_FILTER_UPCOMING ? 'visible' : 'hidden' }} />Tulevat ottelut
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                }
                { !this.props.title && <React.Fragment>
                    <Tooltip title="Järjestä" aria-label="Järjestä">
                        <IconButton onClick={e => {
                            this.setState({sortMenuAnchorEl: e.currentTarget})
                        }}>
                            <Sort />
                        </IconButton> 
                    </Tooltip>
                    <Menu
                        anchorEl={this.state.sortMenuAnchorEl}
                        open={Boolean(this.state.sortMenuAnchorEl)}
                        onClose={() => {this.setState({sortMenuAnchorEl: null})}}
                    >
                        <MenuItem onClick={() => {
                            this.props.setSortOrder(SORT_ORDER_DESCENDING)
                            this.setState({sortMenuAnchorEl: null})
                        }}>
                            <Check style={{ marginRight: 5, visibility: this.props.sortOrder === SORT_ORDER_DESCENDING ? 'visible' : 'hidden' }} />Uusimmasta vanhimpaan
                        </MenuItem>
                        <MenuItem onClick={() => {
                            this.props.setSortOrder(SORT_ORDER_ASCENDING)
                            this.setState({sortMenuAnchorEl: null})
                        }}>
                            <Check style={{ marginRight: 5, visibility: this.props.sortOrder === SORT_ORDER_ASCENDING ? 'visible' : 'hidden' }} />Vanhimmasta uusimpaan
                        </MenuItem>
                    </Menu>
                </React.Fragment> }
            </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filter: state.search.filter,
    sortOrder: state.search.sortOrder,
    searchResultType: state.search.resultType
  }
}
const mapDispatchToProps = dispatch => ({
    setSortOrder: sortOrder => {
        dispatch(setSortOrder(sortOrder))
    },
    setFilter: filter => {
        dispatch(setFilter(filter))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(TopBar)
);
