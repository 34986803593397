export const LOGIN = "LOGIN"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const CLEAR_LOGIN_FAILURE = "CLEAR_LOGIN_FAILURE"
export const LOGOUT = "LOGOUT"

export const BEGIN_RESET_PASSWORD = "BEGIN_RESET_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD"
export const DO_RESET_PASSWORD = "DO_RESET_PASSWORD"

export const BEGIN_REGISTER = "BEGIN_REGISTER"
export const REGISTER = "REGISTER"
export const CLEAR_REGISTER = "CLEAR_REGISTER"
export const DO_REGISTER = "DO_REGISTER"

export const SET_USER_CREDITS = "SET_USER_CREDITS"
