export const GET_TEAMS = "GET_TEAMS"
export const SELECT_TEAM = "SELECT_TEAM"
export const GET_TEAM_ATTRIBUTE = "GET_TEAM_ATTRIBUTE"

export const BEGIN_EDIT_TEAM = "BEGIN_EDIT_TEAM"
export const EDIT_TEAM = "EDIT_TEAM"
export const CLEAR_EDIT_TEAM = "CLEAR_EDIT_TEAM"

export const BEGIN_EDIT_AD = "BEGIN_EDIT_AD"
export const EDIT_AD = "EDIT_AD"
export const CLEAR_EDIT_AD = "CLEAR_EDIT_AD"

export const BEGIN_TRANSFER_CREDITS = "BEGIN_TRANSFER_CREDITS"
export const TRANSFER_CREDITS = "TRANSFER_CREDITS"
export const CLEAR_TRANSFER_CREDITS = "CLEAR_TRANSFER_CREDITS"

export const GET_RESPONSES = "GET_RESPONSES"
export const BEGIN_CONFIRM_RESPONSE = "BEGIN_CONFIRM_RESPONSE"
export const CONFIRM_RESPONSE = "CONFIRM_RESPONSE"
export const CLEAR_CONFIRM_RESPONSE = "CLEAR_CONFIRM_RESPONSE"

export const CONFIRM_MATCH = "CONFIRM_RESPONSE"

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"