import {
  LOGOUT
} from "../login/types"

import { 
  GET_TEAMS,
  SELECT_TEAM,
  GET_TEAM_ATTRIBUTE,
  BEGIN_EDIT_TEAM,
  EDIT_TEAM,
  CLEAR_EDIT_TEAM,
  BEGIN_EDIT_AD,
  EDIT_AD,
  CLEAR_EDIT_AD,
  BEGIN_CONFIRM_RESPONSE,
  CONFIRM_RESPONSE,
  CLEAR_CONFIRM_RESPONSE,
  GET_RESPONSES,
  GET_NOTIFICATIONS,
  BEGIN_TRANSFER_CREDITS,
  CLEAR_TRANSFER_CREDITS,
  TRANSFER_CREDITS
} from "./types"

import {
  EDIT_TEAM_INIT,
  EDIT_TEAM_SUCCESS,
  EDIT_TEAM_FAILURE_409,
  EDIT_TEAM_FAILURE_500,
  EDIT_AD_INIT,
  EDIT_AD_SUCCESS,
  EDIT_AD_FAILURE,
  CONFIRM_RESPONSE_INIT,
  CONFIRM_RESPONSE_SUCCESS,
  CONFIRM_RESPONSE_FAILURE,
  TRANSFER_CREDITS_INIT,
  TRANSFER_CREDITS_SUCCESS,
  TRANSFER_CREDITS_FAILURE_400,
  TRANSFER_CREDITS_FAILURE_500
} from "../../const"

const initialState = {
    teams: [],
    selectedTeam: null,
    editTeamState: null,
    editTeamId: null,
    editAdState: null,
    editAdId: null,
    transferCreditsState: null,
    responses: [],
    confirmResponseState: null,
    confirmResponseAdId: null,
    adNotifications: [],
    responseNotifications: [],
    matchNotifications: [],
    previousAdNotification: null,
    previousResponseNotification: null,
    previousMatchNotification: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOGOUT: {
      return initialState
    }
    case GET_TEAMS: {
      return {
        ...state,
        teams: action.payload
      };
    }
    case SELECT_TEAM: {
      return {
        ...state,
        selectedTeam: action.payload
      }
    }
    case CLEAR_EDIT_TEAM: {
      return {
        ...state,
        editTeamState: null
      }
    }
    case BEGIN_EDIT_TEAM: {
      return {
        ...state,
        editTeamId: action.payload,
        editTeamState: EDIT_TEAM_INIT
      }
    }
    case EDIT_TEAM: {
      const { status } = action.payload
      return {
        ...state,
        editTeamState: status === 200 
          ? EDIT_TEAM_SUCCESS
          : (
            status === 409
              ? EDIT_TEAM_FAILURE_409
              : EDIT_TEAM_FAILURE_500
          )
      }
    }
    case GET_TEAM_ATTRIBUTE: {
      let teams = [...state.teams]
      const teamIndex = teams.findIndex(
        team => team.id === action.payload.teamId
      )
      if ( teamIndex >= 0 ) {
        const team = {
          ...state.teams[teamIndex],
          ...action.payload.data
        }
        teams[teamIndex] = team
      }
      return {
        ...state,
        teams
      }
    }
    case CLEAR_EDIT_AD: {
      return {
        ...state,
        editAdState: null
      }
    }
    case BEGIN_EDIT_AD: {
      return {
        ...state,
        editAdId: action.payload,
        editAdState: EDIT_AD_INIT
      }
    }
    case CLEAR_TRANSFER_CREDITS: {
      return {
        ...state,
        transferCreditsState: null
      }
    }
    case TRANSFER_CREDITS: {
      const { status } = action.payload
      return {
        ...state,
        transferCreditsState: status === 200 
          ? TRANSFER_CREDITS_SUCCESS
          : (
            status === 400
              ? TRANSFER_CREDITS_FAILURE_400
              : TRANSFER_CREDITS_FAILURE_500
          )
      }
    }
    case BEGIN_TRANSFER_CREDITS: {
      return {
        ...state,
        transferCreditsState: TRANSFER_CREDITS_INIT
      }
    }
    case EDIT_AD: {
      const { status } = action.payload
      return {
        ...state,
        editAdState: status === 200 
          ? EDIT_AD_SUCCESS
          : EDIT_AD_FAILURE
      }
    }
    case BEGIN_CONFIRM_RESPONSE: {
      return {
        ...state,
        responses: [],
        confirmResponseAdId: action.payload,
        confirmResponseState: CONFIRM_RESPONSE_INIT
      }
    }
    case CONFIRM_RESPONSE: {
      const { status } = action.payload
      return {
        ...state,
        confirmResponseState: status === 200 
          ? CONFIRM_RESPONSE_SUCCESS
          : CONFIRM_RESPONSE_FAILURE
      }
    }
    case CLEAR_CONFIRM_RESPONSE: {
      return {
        ...state,
        confirmResponseState: null
      }
    }
    case GET_RESPONSES: {
      return {
        ...state,
        responses: action.payload
      };
    }
    case GET_NOTIFICATIONS: {
      const { notifications, previousCreatedAt } = action.payload
      let newState = {...state}
      if ( previousCreatedAt.ad !== state.previousAdNotification ) {
        newState.adNotifications = notifications.ad
        newState.previousAdNotification = previousCreatedAt.ad
      }
      if ( previousCreatedAt.response !== state.previousResponseNotification ) {
        newState.responseNotifications = notifications.response
        newState.previousResponseNotification = previousCreatedAt.response
      }
      if ( previousCreatedAt.match !== state.previousMatchNotification ) {
        newState.matchNotifications = notifications.match
        newState.previousMatchNotification = previousCreatedAt.match
      }
      return newState;
    }
    default:
      return state;
  }
}