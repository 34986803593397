import axios from 'axios';
import { BACKEND_URL } from '../../const'
import { GET } from "./types";

const getSuccess = data => ({
    type: GET,
    payload: data
})

export const get = () => {
    return dispatch => {
        axios.get(`${BACKEND_URL}/static_data`)
            .then(res => {
                dispatch(getSuccess(res.data))
            }).catch(err => {
                console.error(err)
            })
    }
}